<template>
    <div class="profile-authentication-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>
                                    <router-link to="/register"><i class='bx bx-arrow-back'></i></router-link>
                                    Tell us more about yourself.
                                </h3>
                                <div class="register-non-investor-container">
                                    <form class="register-non-investor-form">
                                        <div class="container p-0 mt-3 mb-3">
                                            <div class="row">
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="first_name" class="form-label">First Name</label>
                                                    <input type="text" name="first_name" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="last_name" class="form-label">Last Name</label>
                                                    <input type="text" name="last_name" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="dob" class="form-label">Date of Birth</label>
                                                    <input type="date" name="dob" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="phone_number" class="form-label">Phone Number</label>
                                                    <input type="phone" name="phone_number" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="company_name" class="form-label">Company Name</label>
                                                    <input type="text" name="company_name" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="address" class="form-label">Address</label>
                                                    <input type="text" name="address" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="city" class="form-label">City (Optional)</label>
                                                    <input type="text" name="city" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="state" class="form-label">State (Optional)</label>
                                                    <input type="text" name="state" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="phone_number" class="form-label">Asset Classes Being
                                                        Tokenized</label>
                                                    <select class="form-control">
                                                        <option value="" disabled selected></option>
                                                        <option value="">Retail</option>
                                                        <option value="">Multifamily</option>
                                                        <option value="">Office</option>
                                                        <option value="">Industrial</option>
                                                        <option value="">Hospitallity</option>
                                                        <option value="">Mixed Use</option>
                                                        <option value="">Land</option>
                                                        <option value="">Self Storage</option>
                                                        <option value="">Mobile Home Park</option>
                                                        <option value="">Senior Living</option>
                                                        <option value="">Special Purpose</option>
                                                        <option value="">Note/Loan</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="phone_number" class="form-label">Have you done a RegD
                                                        506(c) offering for your properties?</label>
                                                    <select class="form-control">
                                                        <option value="" disabled selected></option>
                                                        <option value="">Yes</option>
                                                        <option value="">Not yet, but we’re planning to</option>
                                                        <option value="">No, we are not planning to</option>
                                                        <option value="">What’s a RegD 506 (c) offering?</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="phone_number" class="form-label">How much equity are you
                                                        raising?</label>
                                                    <select class="form-control">
                                                        <option value="" selected></option>
                                                        <option value="">$5M - $10M</option>
                                                        <option value="">$10M - $50M</option>
                                                        <option value="">Over $50M</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="register-investor-next-buttons">
                                    <button class="btn btn-register-investor-confirmation-next"
                                        @click="next">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import router from '../../router'
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'Owner',
    components: {
        RegisterNav
    },
    methods: {
        next: function () {
            router.push({ name: "SubmissionPage" })
        }
    }
}
</script>