<template>
    <div class="container">
        <div class="flex flex-row">
            <router-link to="" class="property-link">
                Property Home
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
}
</script>