<template>
    <div>
        <Navbar class="navbar-style-two" />
        <MainBanner />
        <GetStarted />
        <BestSellers heading="Our BestSellers" />
        <BuyAndSell />
        <Funfacts class="pb-70 bg-f9f9f9" />
        <FeedbackSlider />
        <ManagePortfolio class="bg-main-color" />
        <Features heading="Our Features" />
        <MobileApp />
        <OurAdvisors />
        <StartTrading heading="Start Trading on Temo" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeTwo/MainBanner'
import GetStarted from '../HomeTwo/GetStarted'
import BestSellers from '../Common/BestSellers'
import BuyAndSell from '../Common/BuyAndSell'
import Funfacts from '../Common/Funfacts'
import FeedbackSlider from '../Common/FeedbackSlider'
import ManagePortfolio from '../Common/ManagePortfolio'
import Features from '../Common/Features'
import MobileApp from '../Common/MobileApp'
import OurAdvisors from '../Common/OurAdvisors'
import StartTrading from '../Common/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageTwo',
    components: {
        Navbar,
        MainBanner,
        GetStarted,
        BestSellers,
        BuyAndSell,
        Funfacts,
        FeedbackSlider,
        ManagePortfolio,
        Features,
        MobileApp,
        OurAdvisors,
        StartTrading,
        Footer,
    }
}
</script>