<template>
    <tr v-bind:class="rowColor">
        <td class="text-left text-uppercase">{{ orderID() }}</td>
        <td class="text-center">{{ showDate() }}</td>
        <td class="text-center">{{ buySell() }}</td>
        <td class="text-center">{{ Math.floor(Math.random() * (100 - 0) + 0) }}</td>
        <td class="text-center">{{ Math.floor(Math.random() * (100 - 0) + 0) }}</td>
        <td class="text-center">{{ Math.floor(Math.random() * (10000 - 0) + 0) }}</td>
        <td class="text-center">{{ (Math.random() * (100 - 0) + 0).toFixed(2) }}</td>
        <td class="text-center"></td>
        <td class="text-center">Pending</td>
        <td class="text-center">
            <button type="button" class="btn btn-sm btn-success mr-1">Accept</button>
            <button type="button" class="btn btn-sm btn-danger mr-1">Reject</button>
            <button type="button" class="btn btn-sm btn-warning">Counter</button>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName'],
    created() {
    },
    methods: {
        orderID: function () {
            var s1 = Math.random().toString(36).replace(/[^a-z^0-9]+/g, '').substr(0, 4);
            var s2 = Math.random().toString(36).replace(/[^a-z^0-9]+/g, '').substr(0, 4);
            var s3 = Math.random().toString(36).replace(/[^a-z^0-9]+/g, '').substr(0, 8);
            return [s1, s2, s3].join('-');
        },
        showDate: function () {
            var today = new Date();
            var before = new Date(2018, 11, 24, 10, 33, 30, 0);
            var d = new Date(today.getTime() + Math.random() * (before.getTime() - today.getTime())),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        },
        buySell: function () {
            const months = ["Buy", "Sell"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        }
    }
}
</script>