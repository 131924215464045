<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Friends Earn Money on KIBANX</h2>
                <p>
                    In addition, if any of the individuals you invite ultimately become a client of KIBANX before the
                    end of the invitation period (as described below), KIBANX will also credit them with, at a minimum,
                    the same Share Bonus amount, as determined by KIBANX Advisors. The Share Bonus will be credited to
                    your friend within approximately one week after your friend has become a client of KIBANX Advisors.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features',
    props: ['heading']
}
</script>