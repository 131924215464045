<template>
    <div class="banner-area">
        <div class="container">
            <div class="banner-content">
                <h1>Buy & Sell Cryptocurrency Easy, Fast and Secure!</h1>
                <div class="cryptocurrency-search-box">
                    <div class="row">
                        <div class="col-lg-5 col-md-5">
                            <div class="currency-selection">
                                <label>YOU PAY</label>
                                <input 
                                    type="number" 
                                    v-model="cryptoQuantity"
                                >
                                <div class="dropdown">
                                    <button 
                                        class="dropdown-toggle"
                                        @click="show = !show"
                                        :aria-pressed="show ? 'true' : 'false'"
                                        v-bind:class="{ show: button_show_state }"
                                        v-on:click="button_show_state = !button_show_state"
                                    >
                                        <img
                                            :src="card"
                                            alt="image"
                                        />
                                        {{name}}
                                    </button>
                                    <ul 
                                        class="dropdown-menu"
                                        :class="{ toggler: show }"
                                    >
                                        <div v-if="newData.length > 0">
                                            <li v-for="(data, index) in newData" :key="data.id">
                                                <div class="dropdown-item" v-on:click="toggleSelected(data, index), show = !show">
                                                    <img
                                                        :src="data.image"
                                                        alt="image"
                                                    />
                                                    {{data.name}}
                                                </div>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-5">
                            <div class="currency-selection">
                                <label>YOU RECEIVE</label>
                                <input type="text" :value="conversionValue * cryptoQuantity " />
                                <div class="dropdown">
                                    <button class="dropdown-toggler">
                                        <img
                                            :src="cardTwo"
                                            alt="image"
                                        />
                                        {{nameTwo}}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-5">
                            <div class="buy-btn">
                                <a target="_blank" href="https://www.coinbase.com/accounts">
                                    <button type="submit">
                                        <i class='bx bxs-hand-right'></i> 
                                        Buy Bitcoin
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-image">
                <img src="../../assets/img/banner/banner-img2.png" alt="image">
            </div>
        </div>
        <div class="shape1"><img src="../../assets/img/shape/shape1.png" alt="image"></div>
        <div class="shape2"><img src="../../assets/img/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/img/shape/shape3.png" alt="image"></div>
        <div class="shape4"><img src="../../assets/img/shape/shape4.png" alt="image"></div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'MainBanner',
	data: () => ({
		show: false,
		active: false,
		button_show_state: false,
		button_active_state: false,
		//
		newData: [],
		conversionValue: 0,
		cryptoQuantity: 1,
		coinSymbol: "BTC",
		name: "Bitcoin",
		nameTwo: "USD",
		card: require("../../assets/img/cryptocurrency/cryptocurrency2.png"),
		cardTwo: require("../../assets/img/cryptocurrency/cryptocurrency1.png"),
        clicked: false
	}),
	created() {
		axios
			.get(
				"https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
			)
			.then((res) => {
				this.newData = res.data;
			})
			.catch((e) => {
				console.log(e);
			});

        axios
			.get(
				"https://min-api.cryptocompare.com/data/price?fsym=btc&tsyms=USD"
			)
			.then((res) => {
				this.conversionValue = res.data.USD;
			})
			.catch((e) => {
				console.log(e);
			});
	},

    methods: {
        toggleSelected(cat, index){
            if(this.clicked === index){
                this.clicked = null
            }

            this.clicked = index 
            this.name = cat.name 
            this.card = cat.image
            this.coinSymbol = cat.symbol.toUpperCase()

            axios
                .get(
                    `https://min-api.cryptocompare.com/data/price?fsym=${this.coinSymbol}&tsyms=USD`
                )
                .then((res) => {
                    this.conversionValue = res.data.USD;
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }
}
</script>