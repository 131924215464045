<template>
    <div>
        <apexchart width="100%" type="donut" :options="donutPieChartOptions" :series="donutPieSeries">
        </apexchart>
    </div>
</template>

<script>
export default {
    name: 'Donut Pie Apex Chart',
    props: ['donutPieChartOptions', 'donutPieSeries'],
}
</script>