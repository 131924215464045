<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <WatchlistBreadCrumbs />
        <WatchlistDetails />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import WatchlistBreadCrumbs from '../Watchlist/Summary/Details/WatchlistBreadCrumbs'
import WatchlistDetails from "../Watchlist/Summary/Details/Main"
import Footer from '../Layouts/Footer'

export default {
    name: 'WatchlistDetail',
    components: {
        Navbar,
        Breadcrumb,
        WatchlistBreadCrumbs,
        WatchlistDetails,
        Footer,
    },
    data() {
        return {
            crumbs: ['Watchlist', 'Detail Screen']
        }
    }
}
</script>