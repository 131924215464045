<template>
    <div class="container my-3 p-0">
        <div class="row form-group align-items-center">
            <div class="col-lg-3 col-md-5 col-5 text-center">
                <label for="tables" class="form-label m-0">Select Table *</label>
            </div>
            <div class="col-lg-7 col-md-7 col-7">
                <select name="tables" id="tables" class="form-select">
                    <option value="">Select</option>
                    <option value="m_account_types">m_account_types</option>
                    <option value="m_actions">m_actions</option>
                    <option value="m_approval_actions">m_approval_actions</option>
                    <option value="m_approval_levels">m_approval_levels</option>
                    <option value="m_approval_types">m_approval_types</option>
                    <option value="m_attachment_types">m_attachment_types</option>
                    <option value="m_bank_branches">m_bank_branches</option>
                    <option value="m_banks">m_banks</option>
                    <option value="m_batch_uploads">m_batch_uploads</option>
                    <option value="m_buildings">m_buildings</option>
                    <option value="m_chart_of_accounts">m_chart_of_accounts</option>
                    <option value="m_cities">m_cities</option>
                    <option value="m_company_types">m_company_types</option>
                    <option value="m_construction_items">m_construction_items</option>
                    <option value="m_construction_locations">m_construction_locations</option>
                    <option value="m_contract_types">m_contract_types</option>
                    <option value="m_documents">m_documents</option>
                    <option value="m_facilities_types">m_facilities_types</option>
                    <option value="m_floor_adjustments">m_floor_adjustments</option>
                    <option value="m_grade_items_actions">m_grade_items_actions</option>
                    <option value="m_grade_options">m_grade_options</option>
                    <option value="m_job_statuses">m_job_statuses</option>
                    <option value="m_layouts">m_layouts</option>
                    <option value="m_libraries">m_libraries</option>
                    <option value="m_line_item_types">m_line_item_types</option>
                    <option value="m_location_area_item_actions">m_location_area_item_actions</option>
                    <option value="m_mcx1_ordr_firms">m_mcx1_ordr_firms</option>
                    <option value="m_mcx1_source_request">m_mcx1_source_request</option>
                    <option value="m_modules">m_modules</option>
                    <option value="m_occupants">m_occupants</option>
                    <option value="m_parking_spaces">m_parking_spaces</option>
                    <option value="m_portfolios">m_portfolios</option>
                    <option value="m_postal_codes">m_postal_codes</option>
                    <option value="m_prefectures">m_prefectures</option>
                    <option value="m_properties">m_properties</option>
                    <option value="m_property_locations">m_property_locations</option>
                    <option value="m_property_mappings">m_property_mappings</option>
                    <option value="m_quantity_measurements">m_quantity_measurements</option>
                    <option value="m_reason_of_requests">m_reason_of_requests</option>
                    <option value="m_regional_office_bank_accounts">m_regional_office_bank_accounts</option>
                    <option value="m_regional_offices">m_regional_offices</option>
                    <option value="m_renovation_types">m_renovation_types</option>
                    <option value="m_rent_rolls">m_rent_rolls</option>
                    <option value="m_report_controls">m_report_controls</option>
                    <option value="m_request_estimate_status">m_request_estimate_status</option>
                    <option value="m_roles">m_roles</option>
                    <option value="m_roles_actions">m_roles_actions</option>
                    <option value="m_roles_module_accesses">m_roles_module_accesses</option>
                    <option value="m_tax_types">m_tax_types</option>
                    <option value="m_translate_languages">m_translate_languages</option>
                    <option value="m_turnovers">m_turnovers</option>
                    <option value="m_units">m_units</option>
                    <option value="m_vendor_bank_accounts">m_vendor_bank_accounts</option>
                    <option value="m_vendor_compliance">m_vendor_compliance</option>
                    <option value="m_vendor_contacts">m_vendor_contacts</option>
                    <option value="m_vendors">m_vendors</option>
                    <option value="m_workflow_action_fields">m_workflow_action_fields</option>
                    <option value="m_workflow_actions">m_workflow_actions</option>
                    <option value="m_workflow_field_types">m_workflow_field_types</option>
                    <option value="m_workflow_fields">m_workflow_fields</option>
                    <option value="m_workflow_groups">m_workflow_groups</option>
                    <option value="m_workflows">m_workflows</option>
                    <option value="mcx_translate_language">mcx_translate_language</option>
                    <option value="migrations">migrations</option>
                    <option value="t_accounting_summaries">t_accounting_summaries</option>
                    <option value="t_approvals">t_approvals</option>
                    <option value="t_attachments">t_attachments</option>
                    <option value="t_campaign_units">t_campaign_units</option>
                    <option value="t_campaigns">t_campaigns</option>
                    <option value="t_cancel_activity_logs">t_cancel_activity_logs</option>
                    <option value="t_facilities">t_facilities</option>
                    <option value="t_floor_adjustments">t_floor_adjustments</option>
                    <option value="t_imports_files">t_imports_files</option>
                    <option value="t_invoice_line_items">t_invoice_line_items</option>
                    <option value="t_invoices">t_invoices</option>
                    <option value="t_leases">t_leases</option>
                    <option value="t_line_items">t_line_items</option>
                    <option value="t_logout_timers">t_logout_timers</option>
                    <option value="t_mcx1_approval_logs">t_mcx1_approval_logs</option>
                    <option value="t_mcx1_job_approvals">t_mcx1_job_approvals</option>
                    <option value="t_mcx1_jobs_lineitems">t_mcx1_jobs_lineitems</option>
                    <option value="t_mcx1_repairs_estimates">t_mcx1_repairs_estimates</option>
                    <option value="t_mcx1_repairs_payments">t_mcx1_repairs_payments</option>
                    <option value="t_mcx1_workorders">t_mcx1_workorders</option>
                    <option value="t_moveouts">t_moveouts</option>
                    <option value="t_renovation_approvals">t_renovation_approvals</option>
                    <option value="t_renovation_contracts">t_renovation_contracts</option>
                    <option value="t_renovation_estimates">t_renovation_estimates</option>
                    <option value="t_renovation_workflow_maps">t_renovation_workflow_maps</option>
                    <option value="t_renovations">t_renovations</option>
                    <option value="t_rent_roll_trackings">t_rent_roll_trackings</option>
                    <option value="t_rent_rolls">t_rent_rolls</option>
                    <option value="t_rent_table_logs">t_rent_table_logs</option>
                    <option value="t_rent_table_views">t_rent_table_views</option>
                    <option value="t_rent_tables">t_rent_tables</option>
                    <option value="t_rents">t_rents</option>
                    <option value="t_request_estimates">t_request_estimates</option>
                    <option value="t_save_searches">t_save_searches</option>
                    <option value="t_tenant_infos">t_tenant_infos</option>
                    <option value="t_work_order_estimates">t_work_order_estimates</option>
                    <option value="t_work_order_invoice_line_items">t_work_order_invoice_line_items</option>
                    <option value="t_work_order_invoices">t_work_order_invoices</option>
                    <option value="t_work_order_line_items">t_work_order_line_items</option>
                    <option value="t_work_order_payment_approvals">t_work_order_payment_approvals</option>
                    <option value="t_work_order_workflow_maps">t_work_order_workflow_maps</option>
                    <option value="t_work_orders">t_work_orders</option>
                    <option value="third_parties">third_parties</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tables',
}
</script>