<template>
    <div class="portfolio-area pt-100">
        <div class="container">
            <div class="single-portfolio-item">
                <div class="row align-items-center m-0">
                    <div class="col-xl-5 col-lg-6 col-md-12 p-0">
                        <div class="content-slides">
                            <div class="content">
                                <h3>Manage Your Portfolio</h3>
                                <p>Build a bespoke real estate portfolio, one investment at a time, from a selection of
                                    institutional-quality commercial properties.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-7 col-lg-6 col-md-12 p-0">
                        <div class="image text-center">
                            <img src="../../assets/img/portfolio/portfolio-img1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ManagePortfolio',
})
</script>