<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <MyPropertiesBreadcrumb selected_option="Properties" />
        <Filter />
        <Map />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import MyPropertiesBreadcrumb from '../MyProperties/Breadcrumb'
import Filter from '../MyProperties/Filter-map'
import Map from '../MyProperties/Map'
import Footer from '../Layouts/Footer'

export default {
    name: 'MyPropertyMap',
    components: {
        Navbar,
        Breadcrumb,
        MyPropertiesBreadcrumb,
        Filter,
        Map,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', 'Summary']
        }
    }
}
</script>