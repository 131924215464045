<template>
    <div class="container mt-10 mb-20">
        <div class="row">
            <div class="col-md-12 col-12">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center p-2">Property</th>
                                <th class="text-center p-2">Transaction Type</th>
                                <th class="text-center p-2">Date</th>
                                <th class="text-center p-2">Amount</th>
                                <th class="text-center p-2">Tokens</th>
                                <th class="text-center p-2">Amount/Token</th>
                                <th class="text-center p-2">Notes</th>
                                <th class="text-center p-2">Attachments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableRow from "../Distributions/TableRow";
export default {
    name: "MyPropertyDistribution",
    components: {
        TableRow,
    }
}
</script>