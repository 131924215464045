<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <MyPropertiesBreadcrumb selected_option="Distributions" />
        <Filter />
        <Distribution />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import MyPropertiesBreadcrumb from '../MyProperties/Breadcrumb'
import Filter from '../MyProperties/Distributions/Filter'
import Distribution from "../MyProperties/Distributions/Distributions"
import Footer from '../Layouts/Footer'
export default {
    name: 'MyPropertyDistribution',
    components: {
        Navbar,
        Breadcrumb,
        MyPropertiesBreadcrumb,
        Filter,
        Distribution,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', 'Summary']
        }
    }
}
</script>