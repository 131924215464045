<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <PropertyBreadCrumbs selected_option="Property Home" />
        <Main />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import PropertyBreadCrumbs from '../Exchange/Property/Breadcrumb'
import Main from "../Exchange/Property/Main"
import Footer from '../Layouts/Footer'
export default {
    name: 'ExchangeProperty',
    components: {
        Navbar,
        Breadcrumb,
        PropertyBreadCrumbs,
        Main,
        Footer,
    },
    data() {
        return {
            crumbs: ['Exchange', '45 Wilshire Blvd']
        }
    }
}
</script>