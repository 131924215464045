<template>
    <div class="row property-risk-analysis-graph">
        <div class="col-md-4 col-12 mb-5">
            <h6>Vacancy</h6>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-4 col-12 mb-5">
            <h6>Rent / PSF</h6>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-4 col-12 mb-5">
            <h6>Available SQ FT</h6>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-4 col-12 mb-5">
            <h6>Market Rent Growth (Y over Y)</h6>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-4 col-12 mb-5">
            <h6>Under Construction Sq Ft</h6>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-4 col-12 mb-5">
            <h6>Properties Sold</h6>
            <br>
            <LineChart2 />
        </div>
    </div>
</template>
<script>
import LineChart2 from "./Chart2";
export default ({
    name: "PropertyRiskAnalysis",
    components: {
        LineChart2,
    }
})
</script>