<template>
    <div class="account-create-process-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-9 col-md-12">
                    <div class="account-create-process-content">
                        <div class="section-title">
                            <h2>How It Works</h2>
                            <p>
                                Through the KIBANX Friends & Family Program you can invite friends and family to sign up
                                for an account on the "KIBANX Platform" (e.g., www.kibanx.com and its affiliated mobile
                                apps) by sending them an invitation. KIBANX will send an invite email on your behalf to
                                each friend you invite via the Invitation Program email sending interface on
                                KIBANX.com/invitation-program/landing: (1) in your name; and/or (2) with your name. You
                                will also be provided with a unique link that you may share with your friends.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-12">
                    <div class="account-create-process-image text-center">
                        <img src="../../assets/img/convert-currency.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowItWorks'
}
</script>