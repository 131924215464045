<template>
    <div>
        <ValueTrade />
        <Navbar />
        <MainBanner />
        <Funfacts />
        <BuyAndSell />
        <GetStarted />
        <TrustedCryptocurrency />
        <Feedback />
        <ManagePortfolio class="bg-f9f9f9" />
        <Features heading="Our Features" />
        <MobileApp />
        <StartTrading />
        <Footer />
    </div>
</template>

<script>
import ValueTrade from '../Layouts/ValueTrade'
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeOne/MainBanner'
import Funfacts from '../Common/Funfacts'
import BuyAndSell from '../HomeOne/BuyAndSell'
import GetStarted from '../HomeOne/GetStarted'
import TrustedCryptocurrency from '../HomeOne/TrustedCryptocurrency'
import Feedback from '../HomeOne/Feedback'
import ManagePortfolio from '../Common/ManagePortfolio'
import Features from '../Common/Features'
import MobileApp from '../Common/MobileApp'
import StartTrading from '../HomeOne/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageOne',
    components: {
        ValueTrade,
        Navbar,
        MainBanner,
        Funfacts,
        BuyAndSell,
        GetStarted,
        TrustedCryptocurrency,
        Feedback,
        ManagePortfolio,
        Features,
        MobileApp,
        StartTrading,
        Footer,
    }
}
</script>