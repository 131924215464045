<template>
    <form>
        <div class="row mt-3 admin-add-property-step-2">
            <span class="admin-add-property-name mb-3">Property Name: 100 Main Street</span>
            <div class="col-md-5 col-12">
                <div class="row form-group">
                    <div class="col-5">
                        <label for="Name" class="form-label">
                            Sale Condition
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="name" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="Type" class="form-label">
                            Ownership
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="type" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Square Feet
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="subtype" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Air Rights (sq ft)
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Address" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Parking Spaces
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="country" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Year Renovated
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Stories
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Lot Size
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Occupancy Date
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Cap Rate
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Pro-Forma Cap Rate
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Price / Sq Ft
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Lease Type
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
            </div>
            <div class="offset-md-1 col-md-5 col-12">
                <div class="row form-group">
                    <div class="col-5">
                        <label for="Name" class="form-label">
                            Tenant
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="name" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Class
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Net Rentable Area (sq ft)
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Units / Keys
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Year Built
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Buildings
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Zoning
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            APN
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="Type" class="form-label">
                            Net Operating Income
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="type" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Pro-Forma NOI
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="subtype" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Price / Unit
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Address" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Price / Sq Ft (Land Value)
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="country" class="form-control">
                    </div>
                </div>
                <div class="row form-group">
                    <div class="col-5">
                        <label for="subtype" class="form-label">
                            Number of tenants
                        </label>
                    </div>
                    <div class="col-7">
                        <input type="text" name="Equity" class="form-control">
                    </div>
                </div>
                <div class="text-right">
                    <button class="btn btn-secondary mr-3" type="button" @click="this.back()">Back</button>
                    <button class="btn btn-primary" type="button" @click="this.continue()">Continue</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: "step-2",
    emits: ['emitStep'],
    methods: {
        continue: function () {
            this.$emit('emitStep', '3');
        },
        back: function () {
            this.$emit('emitStep', '1');
        }
    }
}
</script>