<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="text-left text-link py-2 px-0">
            <router-link to="/my-holdings-detail" class="nav-link">
                {{ tokenName }}
            </router-link>
        </td>
        <td class="text-center py-2 px-0">45 Wilshire</td>
        <td class="text-center py-2 px-0">20,000 USD</td>
        <td class="text-center py-2 px-0">2,300 USD</td>
        <td class="text-center py-2 px-0">10</td>
        <td class="text-center py-2 px-0">2,000</td>
        <td class="text-center py-2 px-0">2,000,000</td>
        <td class="text-center py-2 px-0">1.00%</td>
        <td class="text-center py-2 px-0">1,200 USD</td>
        <td class="text-center py-2 px-0">1,200 USD</td>
        <td class="text-center py-2">10.7%</td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName'],
    created() {
    }
}
</script>