<template>
    <div class="fedback-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Trusted by Thousands of Users</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs.</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user1.jpg" alt="image">
                                <div class="title">
                                    <h3>David Malan</h3>
                                    <span>Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs.</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user2.jpg" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>Designer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-feedback-box">
                        <div class="rating">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                            <img src="../../assets/img/star.png" alt="image">
                        </div>
                        <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs.</p>
                        <div class="client-info">
                            <div class="d-flex align-items-center">
                                <img src="../../assets/img/user/user3.jpg" alt="image">
                                <div class="title">
                                    <h3>Ben Stokes</h3>
                                    <span>Manager</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feedback'
}
</script>