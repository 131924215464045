<template>
    <div class="container">
        <div class="market-health-area distribution-detail-area pt-100 pb-5">
            <ul class="market-health-list distribution-detail-list">
                <li>
                    <h3>Initial Token Offering</h3>
                    <div class="title">
                        <span class="name">10,000,000</span>
                    </div>
                </li>
                <li>
                    <h3>Total Distributions</h3>
                    <div class="title">
                        <span class="name">2,450,000</span>
                    </div>
                </li>
                <li>
                    <h3>Net Position</h3>
                    <div class="title">
                        <span class="name">7,550,000</span>
                    </div>
                </li>
                <li>
                    <h3>Days Since Last Distributions</h3>
                    <div class="title">
                        <span class="name">25 Days</span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="col-md-12 col-12 mb-5 text-right">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#examplemodal">Add
                    Distribution</button>
                <Modal />
            </div>
            <div class="col-md-12 col-12 mb-5">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-center p-2">Transaction Type</th>
                                <th class="text-center p-2">Date</th>
                                <th class="text-center p-2">Amount</th>
                                <th class="text-center p-2">Tokens</th>
                                <th class="text-center p-2">Amount/Token</th>
                                <th class="text-center p-2">Notes</th>
                                <th class="text-center p-2">Attachments</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                            <TableRow />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TableRow from "../Distribution/TableRow";
import Modal from "../Distribution/DistributionsModal";
export default {
    name: "PropertyDistribution",
    components: {
        TableRow,
        Modal,
    }
}
</script>