<template>
    <div class="container my-3 report-setting-security">
        <div class="row form-group text-left">
            <div class="col-md-12 report-setting-security-label">
                <label for="access" class="main-label">Who can access the generated report?</label>
            </div>
            <div class="offset-md-1 col-md-11 report-setting-security-input">
                <div class="form-check my-3">
                    <input class="form-check-input" type="radio" name="access" id="admin_access" checked>
                    <label class="form-check-label" for="admin_access">
                        Only the admin (which is you) can access this report.
                    </label>
                </div>
                <div class="form-check my-3">
                    <input class="form-check-input" type="radio" name="access" id="all_access">
                    <label class="form-check-label" for="all_access">
                        The admin (which is you) and other registered users can access this report after authentication.
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Security',
}
</script>