<template>
    <div class="row mt-3 admin-add-property-step-1">
        <div class="col-lg-8 col-md-10 col-12">
            <form>
                <div class="row form-group">
                    <label for="Name" class="col-4 form-label">
                        Property Name
                    </label>
                    <input type="text" name="name" class="col-8 form-control">
                </div>
                <div class="row form-group">
                    <label for="Type" class="col-4 form-label">
                        Property Type
                    </label>
                    <select name="type" class="col-8 form-control">
                        <option value=""></option>
                        <option value="">Commercial</option>
                        <option value="">Retail</option>
                        <option value="">Multifamily</option>
                        <option value="">Office</option>
                    </select>
                </div>
                <div class="row form-group">
                    <label for="subtype" class="col-4 form-label">
                        Property Sub-Type
                    </label>
                    <select name="subtype" class="col-8 form-control">
                        <option value=""></option>
                        <option value="">Retail</option>
                        <option value="">Multifamily</option>
                        <option value="">Office</option>
                        <option value="">Industrial</option>
                        <option value="">Hospitallity</option>
                        <option value="">Mixed Use</option>
                        <option value="">Land</option>
                        <option value="">Self Storage</option>
                        <option value="">Mobile Home Park</option>
                        <option value="">Senior Living</option>
                        <option value="">Special Purpose</option>
                        <option value="">Note/Loan</option>
                    </select>
                </div>
                <div class="row form-group">
                    <label for="subtype" class="col-4 form-label">
                        Address
                    </label>
                    <input type="text" name="Address" class="col-8 form-control">
                </div>
                <div class="row form-group">
                    <label for="subtype" class="col-4 form-label">
                        Country
                    </label>
                    <select class="col-8 form-control" name="country">
                        <option value=""></option>
                        <option value="">United States</option>
                        <option value="">Canada</option>
                        <option value="">United Kingdom</option>
                    </select>
                </div>
                <div class="row form-group">
                    <label for="subtype" class="col-4 form-label">
                        Equity Being Asked For
                    </label>
                    <input type="text" name="Equity" class="col-8 form-control">
                </div>
                <div class="text-right">
                    <button class="btn btn-secondary mr-3" type="button" @click="this.cancel()">Cancel</button>
                    <button class="btn btn-primary" type="button" @click="this.continue()">Continue</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    name: "step-1",
    emits: ['emitStep', 'emitCancel'],
    methods: {
        continue: function () {
            this.$emit('emitStep', '2');
        },
        cancel: function () {
            this.$emit('emitCancel', 'main');
        }
    }
}
</script>