<template>
    <div :class="['landing-page-navbar navbar-area', { 'is-sticky': isSticky }]">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <router-link class="navbar-brand" to="/">
                    <img src="../../assets/img/kibanxLogo.png" alt="logo">
                </router-link>
                <div class="others-option">
                    <div class="d-flex align-items-center">
                        <div class="option-item">
                            <router-link to="/login" class="login-btn">
                                <i class='bx bx-log-in'></i>
                                Login
                            </router-link>
                        </div>
                        <div class="option-item">
                            <router-link to="/" class="default-btn">
                                <i class='bx bxs-user'></i>
                                Register
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'LandingNav',
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
}
</script>