<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-12">
                <select name="properties" id="properties" class="form-select mr-2">
                    <option value="">Report Type</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-3 col-12">
                <select name="transactions" id="transactions" class="form-select mr-2">
                    <option value="">Data Group</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
}
</script>