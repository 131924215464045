<template>
    <accordion>
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Who is eligible to sell their KIBANX Tokens?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Sell orders can be placed by any eligible token holder on the <strong>KIBANX</strong> Exchange.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    When can I sell my KIBANX Tokens?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Token holders are generally eligible to begin trading on the secondary market after the acquisition
                    of their securitized token offering.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    At what price can I sell?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Sellers will have the ability to offer their <strong>KIBANX</strong> Tokens at a specified discount
                    or premium to
                    their “Marked Value.” Discounts/premiums for eligible assets are based on prior quarters’ supply and
                    demand levels and are determined separately from the Marked Value. See the definition of “Marked
                    Value” below for more detail. As we continue to refine the <strong>KIBANX</strong> Exchange, we
                    expect to update (and
                    could restrict) the range of prices that positions can be offered at.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    How are Marked Values determined?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    The Marked Value represents an updated assessment of an investment’s value and is determined
                    quarterly by the issuers on the <strong>KIBANX</strong> platform and then adjusted throughout each
                    quarter to reflect
                    distributions made to owners. Marked Values determined by <strong>KIBANX</strong> for investments in
                    the <strong>KIBANX</strong>
                    Exchange are also audited semi-annually by a third-party auditor. Marked Value is stated net of
                    partnership-level expenses and prior distributions. Please note that updated Marked Values are
                    released within 45 days of each quarter end (read more about marked values and net asset values
                    here).
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Can I cancel my sell order after I submit a sell request?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What if a buyer is found only for a portion of my listed tokens?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    How and when will I receive my sale proceeds?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Upon closing of your secondary sale, <strong>KIBANX</strong> will send your proceeds (net of fees
                    and charges) to the
                    bank account we have on file. We will seek to facilitate closing and settlement as reasonably early
                    as possible, but we generally expect to distribute sale proceeds at least XX days after the buyer
                    window for your sale started.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    When is the Effective Date of the sale?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What happens to my distribution after I place a sell order?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Distributions generated prior to the effective date will accrue to the benefit of the seller. For
                    example, if the effective date of your transaction is July 14th and a distribution is generated
                    prior to July 14th, the seller will receive the benefit of that distribution.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What if a buyer isn’t found for the interest that I listed?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Who is eligible to buy secondary interests?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Buyers must be “accredited investors” that are either U.S. taxpayers or otherwise able to own
                    interests in a U.S. pass-through partnership.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    When can I buy secondary interests?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Buyers can access the <strong>KIBANX</strong> Exchange at any time.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What are the targeted returns for investments on the KIBANX Exchange / Secondary Market?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Investment memorandums and quarterly updates available in the Documents tab of each investment page
                    reflect the partnership’s targeted returns at the original closing (based on underwriting
                    information) and property and business plan performance at each valuation date. Due to differences
                    in purchase prices and investment timing, the potential returns of a secondary purchase will differ
                    from the issuers’ targeted returns.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Can I cancel my buy order after I submit a buy request?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    When would I need to fund by?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    How will I know when my purchase is finalized?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    You will receive an email notification that you are closed into the investment and will be able to
                    view your investment on your Portfolio page. You will receive reporting and any future distributions
                    for that investment as of the effective date of purchase.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    How are sell orders matched?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Sell orders are matched with eligible buyers on a first-come, first-served basis.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What is the fee structure? Are there transaction costs?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Sellers are charged 1.5% and buyers are charged 3.25% on the gross transaction price, which is paid
                    to <strong>KIBANX</strong> at the time of transfer. Funding amounts and target returns are always
                    stated net of fees
                    unless otherwise indicated.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Is my identity known to other buyers or sellers?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    No. Participants remain anonymous to one another unless disclosure is required to comply with law or
                    to effectuate the transaction.
                </p>
            </template>
        </accordion-item>
    </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'AccordionContant3',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>