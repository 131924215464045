<template>
    <nav>
        <ol class="breadcrumb main-breadcrumb">
            <li v-for="(crumb, ci) in crumbs" :key="ci" class="breadcrumb-item align-items-center">
                <button class="btn btn-link" :class="{ disabled: isLast(ci), 'text-pointer': isMore(crumb) }"
                    @click="selected(crumb);" @mouseenter="is_hide = false" @mouseleave="is_hide = true">
                    {{ crumb }}
                    <span class="btn-more-sign" v-if="isMore(crumb)">
                        <i class='bx bx-chevron-down'></i>
                    </span>
                </button>
                <div class="main-breadcrumb-list" v-if="isMore(crumb)" :class="{ 'hide': is_hide }"
                    @mouseenter="is_hide = false" @mouseleave="is_hide = true">
                    <span class="text-pointer" :class="{ 'active': sub_crumbs_selected == sub_crumb }"
                        v-for="(sub_crumb, indx) in sub_crumbs" :key="indx" @click="selected_sub(sub_crumb);">
                        {{ sub_crumb }}
                    </span>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>
import router from '../../router';

export default {
    name: 'Breadcrumb',
    props: {
        crumbs: {
            type: Array,
            required: true
        },
        sub_crumbs: {
            type: Array
        },
        sub_crumbs_selected: {
            type: String
        },
        is_sub_crumbs_dropdown: {
            type: String
        }
    },
    data() {
        return {
            is_hide: true,
        }
    },
    methods: {
        isLast(index) {
            return index === this.crumbs.length - 1;
        },
        isMore(name) {
            return (name == 'Watchlist' || name == 'My Holdings') && this.is_sub_crumbs_dropdown;
        },
        selected(crumb) {
            if (this.isMore(crumb)) {
                return false;
            }
            router.push({ name: crumb })
        },
        selected_sub(crumb) {
            if (!this.isMore(crumb)) {
                return false;
            }
            router.push({ name: crumb })
        },
    }
}
</script>

<style scoped>
.breadcrumb {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.37rem;
}
</style>