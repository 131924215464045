<template>
    <div class="container p-0">
        <div class="cryptocurrency-data-table remove-left-data-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-left w-25">Report Name</th>
                        <th class="">Description</th>
                        <th class="text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowVue filename="Property Summary" rowColor="bg-gray-200" />
                    <TableRowVue filename="Asset Memo" rowColor="bg-white" />
                    <TableRowVue filename="Asset Summary" rowColor="bg-gray-200" />
                    <TableRowVue filename="Token Distribution Summary" rowColor="bg-white" />
                    <TableRowVue filename="Compliance Summary" rowColor="bg-gray-200" />
                    <TableRowVue filename="Investment Fact Sheet" rowColor="bg-white" />
                    <TableRowVue filename="Cash Flow Summary" rowColor="bg-gray-200" />
                    <TableRowVue filename="Watchlist Summary" rowColor="bg-white" />
                    <TableRowVue filename="Risk Profile Report" rowColor="bg-gray-200" />
                    <TableRowVue filename="Property Market Report" rowColor="bg-white" />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import TableRowVue from './TableRow';

export default {
    name: 'Table',
    components: {
        TableRowVue
    }
}
</script>