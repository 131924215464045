<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>The Global Leader of Social Trading</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="features-box">
                        <div class="icon">
                            <img src="../../assets/img/icon/icon18.png" alt="image">
                        </div>
                        <h3>Start Instantly</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="features-box">
                        <div class="icon bg-ffefc7">
                            <img src="../../assets/img/icon/icon19.png" alt="image">
                        </div>
                        <h3>Easy to Use</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="features-box">
                        <div class="icon bg-ffc9c2">
                            <img src="../../assets/img/icon/icon20.png" alt="image">
                        </div>
                        <h3>Secure and Regulated</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipicing elit, sed do eiusmod tempor incididunt ut labore et doee magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GlobalLeader'
}
</script>