<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="text-left text-link py-2 px-0">
            <router-link to="/watchlist-detail" class="nav-link" style="width: 125px; padding: 10px;">
                {{ tokenName }}
            </router-link>
        </td>
        <td class="text-center py-2 px-0">2Q-2022</td>
        <td class="text-center py-2 px-0">1,000,000</td>
        <td class="text-center py-2 px-0">200,000</td>
        <td class="text-center py-2 px-0">1,200,000</td>
        <td class="text-center py-2 px-0">1,400,000</td>
        <td class="text-center py-2 px-0">46,813</td>
        <td class="text-center py-2">400,000</td>
        <td class="text-center py-2">24.6%</td>
        <td class="text-center py-2 px-0">1.4x</td>
        <td class="text-center py-2 px-0">6.5</td>
        <td class="text-center py-2 px-0">
            <div style="width: 130px;">
                <button type="button" class="btn btn-watchlist-action">Buy</button>
                <button type="button" class="btn btn-watchlist-danger ml-1">Sell</button>
            </div>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName']
}
</script>