<template>
    <div class="row mb-3">
        <div class="col-10 my-property-admin-my-listing">
            <div class="my-property-admin-my-listing-tabs">
                <div class="d-inline-block mr-10 mb-3">
                    <a class="text-base" href="#" :class="{ 'text-blue-700': step == '1' }" @click="changeStep('1')">
                        1. Basic
                    </a>
                </div>
                <div class="d-inline-block mr-10 mb-3">
                    <a class="text-base" href="#" :class="{ 'text-blue-700': step == '2' }" @click="changeStep('2')">
                        2. Details
                    </a>
                </div>
                <div class="d-inline-block mr-10 mb-3">
                    <a class="text-base" href="#" :class="{ 'text-blue-700': step == '3' }" @click="changeStep('3')">
                        3. Description
                    </a>
                </div>
                <div class="d-inline-block mr-10 mb-3">
                    <a class="text-base" href="#" :class="{ 'text-blue-700': step == '4' }" @click="changeStep('4')">
                        4. Photos / Videos
                    </a>
                </div>
                <div class="d-inline-block mr-10 mb-3">
                    <a class="text-base" href="#" :class="{ 'text-blue-700': step == '5' }" @click="changeStep('5')">
                        5. OM / Flyer Config
                    </a>
                </div>
            </div>
        </div>
        <div class="container pt-3 pb-20" v-if="step == '1'">
            <AddProperty1 @emitStep="changeStep" @emitCancel="backToMain" />
        </div>
        <div class="container pt-3 pb-20" v-if="step == '2'">
            <AddProperty2 @emitStep="changeStep" />
        </div>
        <div class="container pt-3 pb-20" v-if="step == '3'">
            <AddProperty3 @emitStep="changeStep" />
        </div>
        <div class="container pt-3 pb-20" v-if="step == '4'">
            <AddProperty4 @emitStep="changeStep" />
        </div>
        <div class="container pt-3 pb-20" v-if="step == '5'">
            <AddProperty5 @emitStep="changeStep" />
        </div>
        <FinishPropertyModal @emitClose="backToMain()" />
    </div>
</template>

<script>
import AddProperty1 from './AddProperty1';
import AddProperty2 from './AddProperty2';
import AddProperty3 from './AddProperty3';
import AddProperty4 from './AddProperty4';
import AddProperty5 from './AddProperty5';
import FinishPropertyModal from './FinishPropertyModal';

export default {
    name: "AddPropertyParent",
    components: {
        AddProperty1,
        AddProperty2,
        AddProperty3,
        AddProperty4,
        AddProperty5,
        FinishPropertyModal
    },
    data() {
        return {
            step: '1',
        }
    },
    emits: ['emitFunctions'],
    methods: {
        changeStep: function (val) {
            this.step = val;
        },
        backToMain: function () {
            this.$emit('emitFunction', 'main');
        }
    },
}
</script>