<template>
    <div class="profile-authentication-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>
                                    <router-link to="/register"><i class='bx bx-arrow-back'></i></router-link>
                                    Are you an accredited investor?
                                </h3>
                                <p class="register-investor-mainline">As a regulated investment platform, we are
                                    required to learn more about you before creating your account.</p>
                                <p class="register-investor-mainline-2">Generally, you are an accredited investor if any
                                    of the following are true:</p>
                                <ul class="register-investor-ul">
                                    <li class="register-investor-li">Annual income greater than $200k (for the last 2
                                        years)</li>
                                    <li class="register-investor-li">Joint household income greater than $300k (for the
                                        last 2 years)</li>
                                    <li class="register-investor-li">Net worth greater than $1M (excluding your primary
                                        residence)</li>
                                </ul>
                                <a href="https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor"
                                    class="register-investor-info-line" target='_blank'><b><i
                                            class='bx bx-info-circle'></i> <u>Read the
                                            full SEC definition</u></b></a>
                                <div class="register-investor-decide-buttons">
                                    <button class="btn btn-register-investor-confirmation" @click="myFilter(true)"
                                        :class="{ active: isYes }">Yes</button>
                                    <button class="btn btn-register-investor-confirmation" @click="myFilter(false)"
                                        :class="{ active: !isYes }">No</button>
                                </div>
                                <div class="register-investor-next-buttons">
                                    <button class="btn btn-register-investor-confirmation-next"
                                        @click="next">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import router from '../../router';
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'Investor',
    data() {
        return {
            isYes: true
        }
    },
    components: {
        RegisterNav
    },
    methods: {
        myFilter: function (bol) {
            this.isYes = bol;
        },
        next: function () {
            (this.isYes) ? router.push({ name: 'InvestorYesPage' }) : router.push({ name: 'InvestorNoPage' });
        }
    }
}
</script>