<template>
    <accordion>
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How does the token process work?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Investors can purchase securitized tokens via the <strong>KIBANX</strong> platform.
                    <strong>KIBANX</strong> has already onboarded property issuers to ensure that the data reflected on
                    their token is accurate and secure.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Am I able to sell my investments prior to the end of the hold period?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Through the <strong>KIBANX</strong> Token Exchange, you may have an option to offer your position
                    for sale on our platform at a target sale price based on the net asset value (“NAV”) of the
                    investment at that time. However, liquidity and pricing are not guaranteed. Prior to investing, you
                    should assume that you may be required to hold your investment for the duration of Token's full hold
                    period.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How are security tokens at KIBANX structured?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Investors will purchase an ownership interest in a Delaware limited partnership managed by
                    <strong>KIBANX</strong>. Typically, the token issuer enters into an LLC joint venture that
                    indirectly owns the underlying asset.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What reports will I receive?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Investors can expect to receive the following:
                </p>
                <p class="ml-8">
                    Property Updates: We provide quarterly updates for each property within 45 days of quarter-end (your
                    first quarterly report may follow the first complete calendar quarter). An online summary and full
                    downloadable report can be accessed through our Portfolio page.
                </p>
                <p class="ml-8">
                    Investor NAVs: We aim to publish Investor NAVs roughly 60 days after quarter-end. ‍
                </p>
                <p class="ml-8">
                    Tax Reporting: For U.S. taxpayers, we aim to provide annual K-1 statements by the first week of
                    April for principal deals. For U.S. taxpayers, we aim to provide annual K-1 statements in a timely
                    manner for curated deals but cannot guarantee that they will be available prior to April 15th.
                    Investors can find all current and historical statements on the Documents tab on the asset page.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What is the length of my commitment?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Each investment is different but can last often last up to 3-8 year hold periods.
                </p>
                <p>
                    You may be able to offer your interests for sale on the <strong>KIBANX</strong> Exchange at a sale
                    price based on the net asset value of the investment and a secondary market discount rate. However,
                    liquidity, pricing and individual asset eligibility to be listed on the Secondary Market are not
                    guaranteed.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What fees will I be paying to KIBANX?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Clients are typically subject to
                </p>
                <ol>
                    <li>FEE 1</li>
                    <li>FEE 2</li>
                    <li>FEE 3</li>
                </ol>
                <p>
                    Specific fee levels can vary by product and deal. Please refer to a deal’s Private Placement
                    Memorandum for a detailed breakdown of fees.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    When and how will I receive distributions?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    <strong>KIBANX</strong> tokens attempt to make regular (roughly quarterly) distributions from
                    available cash flow once a project is stabilized. These distributions may vary depending on each
                    period’s cash flow and capital requirements. Size and timing of distributions depend on the business
                    plan and performance of each transaction. Distributions are automatically deposited into your bank
                    account on file. You will be notified of upcoming distributions and are able to track your
                    rough our online platform.
                </p>
            </template>
        </accordion-item>
    </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'AccordionContant2',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>