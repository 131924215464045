<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="p-1.5">{{ tokenName }}</td>
        <td class="text-center p-1.5">{{ token2 }}</td>
        <td class="text-center p-1.5">{{ token3 }}</td>
        <td class="text-center p-1.5">{{ token4 }}</td>
        <td class="text-center p-1.5"></td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName', 'token2', 'token3', 'token4'],
    created() {
    }
}
</script>