<template>
    <div class="system-admin-filters mb-3">
        <div class="row">
            <div class="col-lg-3 col-6 md-mb-2">
                <div class="input-group">
                    <input type="text" id="searchbox" class="form-control" placeholder="John *ith, Eva Blau">
                    <span class="input-group-text" id="inputGroupappend"><i class='bx bx-search'></i></span>
                </div>
            </div>
            <div class="col-lg-2 col-6 sort-by-filter md-mb-2">
                <input type="date" name="creation" id="creation" class="form-control">
            </div>
            <div class="col-lg-2 col-6 md-mb-2">
                <select name="tag" id="tag" class="form-select">
                    <option value="" disabled selected>All Tags</option>
                    <option value="">Invalid ID</option>
                    <option value="">Unsatisfactory photos</option>
                    <option value="">Duplicate</option>
                    <option value="">Additional documents</option>
                </select>
            </div>
            <div class="col-lg-2 col-6 md-mb-2">
                <select name="status" id="status" class="form-select">
                    <option value="" disabled selected>All</option>
                    <option value="">Pending</option>
                    <option value="">Approved</option>
                    <option value="">Declined</option>
                </select>
            </div>
            <div class="col-lg-3 col-12 d-flex align-items-center justify-content-end">
                <button class="btn btn-main mr-3">Clear Filters</button>
                <button class="btn btn-optional">CSV</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
    methods: {
    }
}
</script>