<template>
    <div class="container watchlist-block">
        <div class="flex flex-row justify-between">
            <div class="watchlist-main-menu d-flex align-items-center">
                <a class="text-blue-700 text-base font-bold hover:text-black mr-8" href="#"
                    :class="{ 'active': currentTab == 'Dashboard' }" @click="changeTab('Dashboard')">Dashboard</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mr-8" href="#"
                    :class="{ 'active': currentTab == 'Issuer' }" @click="changeTab('Issuer')">Issuer</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mr-8" href="#"
                    :class="{ 'active': currentTab == 'Investor' }" @click="changeTab('Investor')">Investor</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mr-8" href="#"
                    :class="{ 'active': currentTab == 'KYC' }" @click="changeTab('KYC')">KYC / KYI</a>
            </div>
        </div>
    </div>
    <KYCVue v-if="currentTab == 'KYC'" />
</template>

<script>
import KYCVue from './KYC/KYC.vue';

export default {
    name: 'Main',
    data() {
        return {
            currentTab: 'KYC',
        }
    },
    components: {
        KYCVue,
    },
    methods: {
        changeTab: function (val) {
            this.currentTab = val;
        }
    }
}
</script>