<template>
    <div class="container">
        <div class="flex flex-row sub-breadcrumbs">
            <router-link to="/exchange/property" class="property-link"
                :class="{ 'selected_option': selected_option == 'Property Home' }">
                Property Home
            </router-link>
            <router-link to="/exchange/distributions" class="property-link"
                :class="{ 'selected_option': selected_option == 'Distributions' }">
                Distributions
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: ['selected_option'],
}
</script>