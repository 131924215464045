<template>
    <div class="modal fade" id="propertymodal" tabindex="-1" aria-labelledby="propertymodallabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body pt-5 px-5">
                    <h3>Almost There!</h3>
                    <p>
                        Your property has been submitted. We will review the data that you have provided and contact you
                        soon.
                    </p>
                    <div class="text-center pt-20">
                        <button type="button" class="btn btn-main" data-bs-dismiss="modal"
                            @click="this.contnue()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DistributionsModal",
    emits: ["emitClose"],
    methods: {
        contnue: function () {
            this.$emit("emitClose", "main");
        },
    }
}
</script>