<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-12">
                <select name="properties" id="properties" class="form-select mr-2">
                    <option value="">All Properties</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-3 col-12">
                <select name="transactions" id="transactions" class="form-select mr-2">
                    <option value="">All Transaction Type</option>
                    <option value="">Contributions</option>
                    <option value="">Distributions</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-3 col-12">
                <select name="distributions" id="distributions" class="form-select mr-2">
                    <option value="">Dates</option>
                </select>
            </div>
            <div class="col-lg-6 col-md-3 col-12 d-flex align-items-center justify-content-end">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#examplemodal">Add
                    Distribution</button>
                <Modal />
            </div>
        </div>
    </div>
</template>

<script>
import Modal from "../Distributions/DistributionsModal";
export default {
    name: 'Filter',
    components: {
        Modal,
    }
}
</script>