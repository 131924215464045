<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-md-2 col-12 btn-search-block">
                <input type="text" id="search" class="form-control" placeholder="Search here">
                <button type="button" class="btn btn-search"><i class='bx bx-search'></i></button>
            </div>
            <div class="col-md-10 col-12 text-right align-self-center">
                <button class="btn btn-main">Add</button>
                <button class="btn btn-view" :class="{ 'active': current == 'list' }">
                    <i class='bx bx-list-ul'></i>
                </button>
                <button class="btn btn-view" :class="{ 'active': current == 'table' }">
                    <i class='bx bx-table'></i>
                </button>
                <button class="btn btn-view" :class="{ 'active': current == 'card' }">
                    <i class='bx bx-card'></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
    props: ['current']
}
</script>