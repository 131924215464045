<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="d-inline-block logo">
                            <img src="../../assets/img/kibanxLogo.png" alt="Image">
                        </router-link>
                        <div class="newsletter-form">
                            <p>SUBSCRIBE TO OUR NEWSLETTER</p>
                            <form data-toggle="validator">
                                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL"
                                    required autocomplete="off">
                                <button type="submit">
                                    Subscribe Now
                                    <i class='bx bx-paper-plane'></i>
                                </button>
                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                        <ul class="social-links">
                            <li>
                                <a href="https://www.facebook.com/" target="_blank" class="facebook">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank" class="twitter">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/" target="_blank" class="linkedin">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/" target="_blank" class="instagram">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Platform</h3>
                        <ul class="services-links">
                            <li>
                                <router-link to="/">My Properties</router-link>
                            </li>
                            <li>
                                <router-link to="/">My Tokens</router-link>
                            </li>
                            <li>
                                <router-link to="/">Exchange</router-link>
                            </li>
                            <li>
                                <router-link to="/watchlist">My Watchlist</router-link>
                            </li>
                            <li>
                                <router-link to="/">KIBANK Utility Tokens</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Resources</h3>
                        <ul class="quick-links">
                            <li>
                                <router-link to="/trade">Trade</router-link>
                            </li>
                            <li>
                                <router-link to="/guides">Guides</router-link>
                            </li>
                            <li>
                                <router-link to="/wallet">Wallets</router-link>
                            </li>
                            <li>
                                <router-link to="/faq">FAQ</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Contact</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contact Info</h3>
                        <ul class="footer-contact-info">
                            <li>Address: 99 Wall Street Suite 1727, NY, NY 10005</li>
                            <li>Email: <a href="mailto:hello@temo.com">hello@temo.com</a></li>
                            <li>Phone: <a href="tel:+44587154756">+44 587 154756</a></li>
                            <li>Fax: <a href="tel:+44587154756">+55 58715 4756</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container">
                <p>Copyright {{ currentYear }} <strong>KIBANX</strong>. All Rights Reserved by
                    <a href="https://envytheme.com/" target="_blank">EnvyTheme</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>