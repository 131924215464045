<template>
    <div class="container my-property-reporting-subtabs">
        <div class="row">
            <div class="col-12">
                <div class="row align-items-center">
                    <div class="col-lg-2 col-md-3 col-6">
                        <button type="button" class="btn btn-default" :class="{ 'text-blue-700': reportType == '1' }"
                            @click="changeReportType('1')">
                            Base Reports
                        </button>
                    </div>
                    <div class="col-lg-2 col-md-3 col-6">
                        <button type="button" class="btn btn-default" :class="{ 'text-blue-700': reportType == '2' }"
                            @click="changeReportType('2')">
                            Custom Reports
                        </button>
                    </div>
                    <div class="col-lg-8 col-md-6 col-6 text-right" v-if="reportType == '2'">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#createReportModal">
                            Add New Report
                        </button>
                        <Modal />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container p-0 pt-5" v-if="reportType == '1'">
        <BaseTable />
    </div>
    <div class="container p-0 pt-5" v-if="reportType == '2'">
        <Table />
    </div>
</template>

<script>
import BaseTable from './BaseReport/Table'
import Table from './CustomReport/Table'
import Modal from './ReportModal'

export default {
    name: 'Filter',
    components: {
        BaseTable,
        Table,
        Modal,
    },
    data() {
        return {
            reportType: '1',
        }
    },
    methods: {
        changeReportType: function (val) {
            this.reportType = val;
        },
    },
}
</script>