<template>
    <accordion>
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What are KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    KDOLLARS are US Dollar backed tokens. The bank services for KDOLLARS are provided by BANKCOMPANY
                    (“Bank”), Member FDIC, through our technology partner.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What are the benefits of KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Some of the main benefits of the <strong>KIBANX</strong> KDOLLARS program are:
                </p>
                <ol>
                    <li>
                        FDIC insurance for up to $750k. Cash in the KDOLLARS Account is FDIC insured through our partner
                        banks, with accounts eligible for up to $750k of FDIC insurance via the Multi-Bank Program.
                    </li>
                    <li>
                        No fees. There are neither fees to you associated with the KDOLLARS account nor additional
                        charges to you for withdrawals or transfers. Fees charged by <strong>KIBANX</strong> are tied to
                        our securitized token offerings. Any fees charged by the bank providers will be covered by
                        <strong>KIBANX</strong>.
                    </li>
                    <li>
                        Make regular withdrawals and deposits. Easily move your money in and out of your cash account at
                        no cost. Your KDOLLARS account is your own bank account so you can make deposits and withdrawals
                        regularly.
                    </li>
                    <li>
                        Limit your market risk. Since your cash deposits are in a savings account at a bank, they are
                        not invested in the stock market and you can rest easily that the cash will not be subject to
                        market volatility.
                    </li>
                </ol>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I get started with KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <ol>
                    <li>
                        Decide how much you want to invest on <strong>KIBANX</strong>.
                    </li>
                    <li>
                        As part of our onboarding process, we will open a ‘<strong>KIBANX</strong> Wallet’ for you where
                        you can begin adding a balance of KDOLLARS.
                    </li>
                    <li>
                        Purchase using your KDOLLARS account and deposit or withdraw from your KDOLLARS account
                        regularly.
                    </li>
                </ol>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Who is eligible for KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    At this time, KDOLLARS are available to US-based individual investors and joint tenants who meet our
                    accreditation criteria and invest in <strong>KIBANX</strong> real estate tokens. Additionally,
                    you’ll need a US-based external bank account to fund your KDOLLARS account.
                </p>
                <p>
                    KDOLLARS are currently not available for international investors, or U.S. individuals who invest
                    through limited liability companies, trusts or other arrangements.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What are the fees associated with my KDOLLARS account?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    <strong>KIBANX</strong> does not charge you any fees associated for your KDOLLARS account. Fees at
                    <strong>KIBANX</strong> are associated with our securitized token offerings.
                </p>
                <p>
                    There are no additional charges for any withdrawals or transfers.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Will I receive any additional tax forms?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipusm.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I transfer funds to KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    When you sign up for <strong>KIBANX</strong> access, you will link your external bank account to
                    deposit funds into your KDOLLARS account via ACH.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I withdraw funds out of KDOLLARS?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    You can initiate a withdrawal from your KDOLLARS account to your linked external account at any time
                    and for no fee by scheduling a transfer from your <strong>KIBANX</strong> Wallet.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I transfer funds to purchase KIBANX Tokens?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    When you invest in real estate tokens with <strong>KIBANX</strong>, we will automatically withdraw
                    from your <strong>KIBANX</strong> Wallet to fund your investment.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I close my KDOLLARS account?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Lorem Ipsum
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    If I don't want to open a KIBANX Wallet, can I still invest with
                    KIBANX?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    KDOLLARS facilitate efficient contributions and receiving distributions from <strong>KIBANX</strong>
                    tokens. Investors who qualify for a KDOLLARS account (i.e., U.S. based individuals investing through
                    their individual account) are asked to open a <strong>KIBANX</strong> Wallet before they invest. If
                    you would like to not open a KDOLLARS account, you can still use wire to fund your investments.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How are my KDOLLARS deposits protected?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Your KDOLLARS deposits are eligible for insurance by the Federal Deposit Insurance Corporation
                    (FDIC) at our partner banks up to an amount of $750,000, or $250,000 per bank through the Multi-Bank
                    Program.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    What fees do you charge on KDOLLARS accounts?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    There are no fees associated with opening or closing your <strong>KIBANX</strong> Wallet. And you
                    pay no monthly service charge or transaction fees. Certain fees charged by the bank providers are
                    covered by <strong>KIBANX</strong>.
                </p>
                <p>
                    Though we don’t expect to ever charge fees for KDOLLARS, we reserve the right to change the KDOLLARS
                    fee structure - any changes will be communicated to you in advance. Your continued use of KDOLLARS
                    after any fee change(s) comes into effect will constitute your agreement to pay the fees.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Are my deposits liquid?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Your KDOLLARS are liquid and can be withdrawn as needed.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Where will my KDOLLARS deposits be held?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Your deposits into your KDOLLARS account will be held at COMPANYBANK and other banks in the
                    multibank program including a custodial account where they are held in an FBO account. See the
                    KDOLLARS terms for more detail.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Where can I access my account agreements?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    The services provided to you for your <strong>KIBANX</strong> Wallet are subject to the applicable
                    account agreements available in your documents folder and our terms of service.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How do I link a bank account to my KDOLLARS account?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    You can link your bank account to your KDOLLARS account via our integration with SERVICEPROVIDER.
                    When you are ready to purchase a <strong>KIBANX</strong> real estate token, you will be prompted to
                    submit your bank information as you go through the onboarding process.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Where can I withdraw my funds to?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    You can withdraw your funds to the bank account you linked with the SERVICEPROVIDER integration.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How can I contact the KIBANX team if I have questions?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>You can contact the <strong>KIBANX</strong> team in one of three ways:</p>
                <ol>
                    <li>By emailing your assigned <strong>KIBANX</strong> Token Specialist.</li>
                    <li>
                        By emailing us at <a href="mailto:customersuppportkibanx.com">customersuppportkibanx.com</a>.
                    </li>
                    <li>By calling us at <a href="tel:+16468667473">+1 (646) 866-7473</a>.</li>
                </ol>
                <p class="text-black-50 mt-1">
                    <span class="red">*</span>
                    Our hours of customer support are between 9 AM - 6 PM EST, Monday through Friday.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    Who verifies my identification?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Your identification documents will be verified by our team and our certified third-party vendor.
                </p>
            </template>
        </accordion-item>
    </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'AccordionContant4',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>