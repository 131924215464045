<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12 mb-5">
                <img src="@/assets/img/investment_detail/1.png" class="w-100" style="height: 300px;" />
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-5">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12098.40003046868!2d-74.0072812!3d40.7048062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70c11c6c3c128d73!2sDeal%20Tracking%20Solutions%2C%20Inc.!5e0!3m2!1sen!2s!4v1660665348367!5m2!1sen!2s"
                    class="investment-detail-location-map" height="300px" style="border:0;" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="col-lg-6 col-12 mb-5">
                <InvestmentChart />
            </div>
        </div>
    </div>
    <PropertyDetailBreadCrumbs />
</template>
<script>
import InvestmentChart from "./Chart";
import PropertyDetailBreadCrumbs from "./PropertyDetailBreadCrumbs";

export default ({
    name: "Main",
    components: {
        InvestmentChart,
        PropertyDetailBreadCrumbs,
    }
})
</script>