<template>
    <div class="container p-0">
        <div class="cryptocurrency-data-table remove-left-data-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="item in items" :key="item"
                            :class="(item.heading == 'Report Name') ? 'text-left' : 'text-center'">{{ item.heading }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import TableRowVue from './TableRow';

const items = ref([{ heading: "Report Name" }, { heading: "Description" }, { heading: "Table Name" }, { heading: "Updated By" }, { heading: "Action" }])
export default {
    name: 'Table',
    data() {
        return {
            items: items
        }
    },
    components: {
        TableRowVue
    }
}
</script>