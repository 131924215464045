<template>
    <div class="row property-commentary-block">
        <div class="col-md-6 col-12 mb-5">
            <div class="form-group">
                <label for="description" class="form-label fw-bold">Description</label>
                <textarea name="description" id="description" class="form-control"
                    rows="7">The office condominiums at 866 United Nations Plaza offers foreign governments, non-profits and businesses an exceptional opportunity to secure a permanent office in the most prestigious and conveniently located building steps from the United Nations Headquarters. The building was designed by the lead architect for the United Nations complex and offers unparalleled light and air, with panoramic views of the East River and the United Nations campus, from a continuous band of floor-to-ceiling windows that envelop the building. It has four sides of oversized windows providing expansive light, air and unobstructed, panoramic views of the East River and the United Nations campus, on-site underground parking garage with easy access to the FDR East River Drive.</textarea>
            </div>
        </div>
        <div class="col-md-6 col-12 mb-5">
            <div class="form-group">
                <label for="opportunity" class="form-label fw-bold">Value Add Opportunity</label>
                <textarea name="opportunity" id="opportunity" class="form-control"
                    rows="7">Unparalleled increase in problem assets in the banking system will lead to opportunities to purchase stressed and distressed assets and portfolios. The deal team has underwritten an exit in July 2020 once the site is approved for the as-of-right 341,000 gross SF (243,000 net rentable SF) residential development (the Base Case underwriting assumes this takes three years to complete, though it may be done sooner). The projected exit value of $75.00 per GSF is based on a triangulation of comparable sales and an implied land value analysis that assumes that a developer will require a 6.00% yield on cost for this type of project.</textarea>
            </div>
        </div>
        <div class="col-md-6 col-12 mb-5">
            <div class="form-group">
                <label for="assumptions" class="form-label fw-bold">Exit Assumptions</label>
                <textarea name="assumptions" id="assumptions" class="form-control"
                    rows="7">Underwriting has an exit in July 2025 once the site is approved for the as-of-right 341,000 gross SF (243,000 net rentable SF) residential development (the Base Case underwriting assumes this takes three years to complete, though it may be done sooner). The projected exit value of $75.00 per GSF is based on a triangulation of comparable sales and an implied land value analysis that assumes that a developer will require a 6.00% yield on cost for this type of project.</textarea>
            </div>
        </div>
        <div class="col-md-6 col-12 mb-5">
            <div class="form-group">
                <label for="terms" class="form-label fw-bold">Financing Terms</label>
                <textarea name="terms" id="terms" class="form-control" rows="7">TBD</textarea>
            </div>
        </div>
        <div class="col-md-12 col-12 mb-5">
            <div class="form-group">
                <label for="notes" class="form-label fw-bold">Internal Notes</label>
                <textarea name="notes" id="notes" class="form-control"
                    rows="7">Deal looks like a go. Should be closed by end of December.</textarea>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    name: "PropertyCommentary",
})
</script>