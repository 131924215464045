<template>
    <div class="page-title-area">
        <div class="container">
            <div class="page-title-content">
                <h1>{{ pageTitle }}</h1>
                <p>{{ pageTagLine || "Temo is the easiest place to buy and sell cryptocurrency." }}</p>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: ['pageTitle', 'pageTagLine']
}
</script>