<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Getting Started on KIBANX"
            pageTagLine="Quickly get set up to buy KIBANX tokens or register your property to tokenize on our exchange." />
        <Guides />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Guides from '../Guides/Register'
// import Guides from '../Guides/Guides'
import Footer from '../Layouts/Footer'

export default {
    name: 'GuidesPage',
    components: {
        Navbar,
        PageTitle,
        Guides,
        Footer,
    }
}
</script>