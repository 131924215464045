<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-12 mb-5">
                <img src="@/assets/img/investment_detail/1.png" class="w-100" style="height: 300px;" />
            </div>
            <div class="col-lg-3 col-md-6 col-12 mb-5">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12098.40003046868!2d-74.0072812!3d40.7048062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70c11c6c3c128d73!2sDeal%20Tracking%20Solutions%2C%20Inc.!5e0!3m2!1sen!2s!4v1660665348367!5m2!1sen!2s"
                    class="investment-detail-location-map" height="300px" style="border:0;" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div class="col-lg-6 col-12 mb-5">
                <InvestmentChart />
            </div>
        </div>
    </div>
    <div class="container investment-part-2">
        <div class="row investment-part-2-options">
            <div class="col-lg-8 col-md-7 col-12 mb-3">
                <div class="row">
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Details</a>
                    </div>
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Location</a>
                    </div>
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Commentary</a>
                    </div>
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Performance</a>
                    </div>
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Characteristics</a>
                    </div>
                    <div class="col">
                        <a class="text-blue-700 text-base font-bold hover:text-black" href="#">Risk
                            Analysis</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-5 col-12 mb-3">
                <h1 class="text-blue-700 text-base text-end">
                    + Create New View
                </h1>
            </div>
        </div>
    </div>
    <div class="container mb-20">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-12 mb-5">
                <div class="row">
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Property Type</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Retails</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Property type</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Storefront</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Subtype</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Owner/User</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Investment Type</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Single</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Tenancy</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1,524</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Square Footage</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">7.06%</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Cap Rate</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">$31,779</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">NOI</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1930</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Year Built</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Buildings</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Stories</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Units/ Keys</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">45</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Parking Spaces</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">45</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0"> Address</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">100 Main Street</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">City</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">New York</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">State</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">New York</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Zip Code</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">10011</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Neighborhood</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Financial District</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Country</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">USA</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">MSA</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">NYC</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Parcel Number</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">1231231</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Assessed Values</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">20,200,00</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Lot Size (sq ft)</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">2,731</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">NRA (sq ft)</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">2,213</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Zoning</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">R</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="mb-0">Broker Co-Op</p>
                    </div>
                    <div class="col-md-3 col-6">
                        <p class="text-bold mb-0">Yes</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12 overflow-x-auto">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <div class="form-check ml-2">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    </div>
                                </th>
                                <th class="text-center p-2">Buildings</th>
                                <th class="text-center p-2">Stories</th>
                                <th class="text-center p-2">Year Built</th>
                                <th class="text-center p-2">Units</th>
                                <th class="text-center p-2">NRA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <InvestmentTable tokenName="Building 1" rowColor="bg-gray-200" />
                            <InvestmentTable tokenName="Building 2" rowColor="bg-white" />
                            <InvestmentTable tokenName="Building 3" rowColor="bg-gray-200" />
                            <InvestmentTable tokenName="Building 4" rowColor="bg-white" />
                            <InvestmentTable tokenName="Building 5" rowColor="bg-gray-200" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import InvestmentChart from "./Chart"
import InvestmentTable from "./InvestmentTable";
export default ({
    name: "MainComponent",
    components: {
        InvestmentChart,
        InvestmentTable
    }
})
</script>