<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="p-1.5">
            <div class="form-check ml-2">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            </div>
        </td>
        <td class="text-center p-1.5">{{ tokenName }}</td>
        <td class="text-center p-1.5">10</td>
        <td class="text-center p-1.5">2010</td>
        <td class="text-center p-1.5">2021</td>
        <td class="text-center p-1.5">123456</td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName'],
    created() {
    }
}
</script>