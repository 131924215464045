<template>
    <div class="account-create-process-area bg-image ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-12">
                    <div class="account-create-process-content">
                        <div class="section-title">
                            <h2>Disclosures</h2>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-12 mdw-100">
                                <div class="single-process-box">
                                    <p><b>Performance Not Guaranteed:</b></p>
                                    <p>Performance is no guarantee of future results. Any historical returns, expected
                                        returns, or probability projections are not guaranteed and may not reflect
                                        actual future performance.</p>
                                </div>
                            </div>
                            <div class="col-12 mdw-100">
                                <div class="single-process-box">
                                    <p><b>Risk of Loss:</b></p>
                                    <p>All securities involve a high degree of risk and may result in partial or total
                                        loss of your investment.</p>
                                </div>
                            </div>
                            <div class="col-12 mdw-100">
                                <div class="single-process-box">
                                    <p><b>Liquidity Not Guaranteed:</b></p>
                                    <p>Investments offered by KIBANX are illiquid and there is never any guarantee that
                                        you will be able to exit your investments on the Secondary Market or at what
                                        price an exit (if any) will be achieved.</p>
                                </div>
                            </div>
                            <div class="col-12 mdw-100">
                                <div class="single-process-box">
                                    <p><b>Not a Public Exchange:</b></p>
                                    <p>The KIBANX Exchange is NOT a stock exchange or public securities exchange, there
                                        is no guarantee of liquidity and no guarantee that the KIBANX Secondary Market
                                        will continue to operate or remain available to investors.</p>
                                </div>
                            </div>
                            <div class="col-12 mdw-100">
                                <div class="single-process-box">
                                    <p><b>Private Securities:</b></p>
                                    <p>Private Securities are high-risk and illiquid investments. No securities
                                        commission or regulatory authority has recommended or approved any investment or
                                        the accuracy or completeness of any of the information or materials provided by
                                        or through us. Please refer to the investment opportunity and memorandum for
                                        additional information, disclosures, and a more detailed explanation of expenses
                                        and risks. Interests are being offered only to persons who qualify as accredited
                                        investors under the Securities Act, and a Qualified Purchaser as defined in
                                        Section 2 (a) (51) (A) under the Company Act. Any securities offering is private
                                        and you may be required to verify your status as an "Accredited Investor" to
                                        participate.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Disclosures'
}
</script>