<template>
    <div class="account-register-area ptb-100">
        <div class="container">
            <div class="account-register-content">
                <h2>Start Trading on KIBANX</h2>
                <p>Get started trading real estate security tokens on KIBANX now!</p>
                <router-link to="/register" class="default-btn">
                    <i class='bx bxs-user'></i>
                    Register Now
                </router-link>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape10"><img src="../../assets/img/shape/shape10.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading'
}
</script>