<template>
    <div>
        <Investor />
    </div>
</template>

<script>
import Investor from '../LoginRegister/Investor'

export default {
    name: 'InvestorPage',
    components: {
        Investor
    }
}
</script>