<template>
    <div class="cryptocurrency-area pb-70 ">
        <div class="container">
            <div class="row justify-content-center">
                <!-- Block 1 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">45 Wilshire Blvd</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/1.png" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 2 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">212 Canal St</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/2.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 3 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">306 Fifth Ave</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/3.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 4 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">785 Park Ave</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/4.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 5 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">633 Third Ave</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/5.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 6 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch">
                    <div class="single-cryptocurrency-item my-property-card cursor-pointer" @click="MyProperty">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">866 UN Plaza</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/6.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import router from '../../router';
export default {
    name: 'Card',
    methods: {
        MyProperty() {
            router.push({ name: "Property" })
        }
    }
}

</script>