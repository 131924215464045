<template>
    <tr v-bind:class="rowColor">
        <td class="text-left text-uppercase text-pointer text-main text-underline">{{ filename }}</td>
        <td class="text-left">{{ Desc() }}</td>
        <td class="text-center">{{ Type() }}</td>
        <td class="text-center">{{ Area() }}</td>
        <td class="text-center">{{ showDate() }}</td>
        <td class="text-center">{{ showModifier() }}</td>
        <td class="text-center">
            <span class="property-library-action text-pointer mr-1" title="Download File"><i
                    class='bx bxs-download'></i></span>
            <span class="property-library-action text-pointer" title="Upload File"><i
                    class='bx bx-cloud-upload'></i></span>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'filename'],
    created() {
    },
    methods: {
        showDate: function () {
            var today = new Date();
            var before = new Date(2018, 11, 24, 10, 33, 30, 0);
            var d = new Date(today.getTime() + Math.random() * (before.getTime() - today.getTime())),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        },
        Desc: function () {
            const months = ["Lorem ipsum dolor sit amet...", "Nulla at commodo ante...", "Nullam quis justo consequat...", "Pellentesque mi urna..."];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        },
        Type: function () {
            const months = ["PDF", "Image"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        },
        Area: function () {
            const months = ["Exchange", "Property"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        },
        showModifier: function () {
            const months = ["Admin", "User 1", "User 2", "User 3", "User 4", "User 5", "User 6"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        }
    }
}
</script>