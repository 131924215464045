<template>
    <div>
        <Navbar />
        <MainBanner />
        <Funfacts />
        <BuyAndSell />
        <GetStarted />
        <TrustedCryptocurrency />
        <Feedback />
        <ManagePortfolio class="bg-f9f9f9" />
        <Features heading="Our Features" />
        <MobileApp />
        <StartTrading />
        <Disclosures />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../Dashboard/MainBanner'
import Funfacts from '../Dashboard/Funfacts'
import BuyAndSell from '../Dashboard/BuyAndSell'
import GetStarted from '../Dashboard/GetStarted'
import TrustedCryptocurrency from '../Dashboard/TrustedCryptocurrency'
import ManagePortfolio from '../Dashboard/ManagePortfolio'
import Features from '../Dashboard/Features'
import StartTrading from '../Dashboard/StartTrading'
import Disclosures from '../Dashboard/Disclosures'
import Footer from '../Layouts/Footer'

export default {
    name: 'Dashboard',
    components: {
        Navbar,
        MainBanner,
        Funfacts,
        BuyAndSell,
        GetStarted,
        TrustedCryptocurrency,
        ManagePortfolio,
        Features,
        StartTrading,
        Disclosures,
        Footer,
    }
}
</script>