<template>
    <div>
        <Navbar class="navbar-style-three" />
        <MainBanner />
        <GlobalLeader />
        <PreSale />
        <TopCryptocurrencies />
        <Features heading="Our Features" />
        <ManagePortfolio />
        <BuyAndSell />
        <MobileApp />
        <OurAdvisors />
        <StartTradingTwo />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HomeThree/MainBanner'
import GlobalLeader from '../HomeThree/GlobalLeader'
import PreSale from '../HomeThree/PreSale'
import TopCryptocurrencies from '../HomeThree/TopCryptocurrencies'
import Features from '../Common/Features'
import ManagePortfolio from '../HomeThree/ManagePortfolio'
import BuyAndSell from '../Common/BuyAndSell'
import MobileApp from '../HomeThree/MobileApp'
import OurAdvisors from '../Common/OurAdvisors'
import StartTradingTwo from '../Common/StartTradingTwo'
import Footer from '../Layouts/Footer'

export default {
    name: 'HomePageThree',
    components: {
        Navbar,
        MainBanner,
        GlobalLeader,
        PreSale,
        TopCryptocurrencies,
        Features,
        ManagePortfolio,
        BuyAndSell,
        MobileApp,
        OurAdvisors,
        StartTradingTwo,
        Footer,
    }
}
</script>