<template>
    <div class="profile-authentication-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>
                                    <router-link to="/register/investor"><i class='bx bx-arrow-back'></i></router-link>
                                    Just need a little more information.
                                </h3>
                                <div class="register-tell-more-block">
                                    <form>
                                        <div class="container p-0 m-3">
                                            <div class="row align-items-center">
                                                <div class="col-12 p-0">
                                                    <span class="register-tell-more-resident-heading">I am a resident
                                                        of:</span>
                                                    <select class="form-control register-tell-more-resident-select"
                                                        name="resident">
                                                        <option value="">United States</option>
                                                        <option value="">Canada</option>
                                                        <option value="">United Kingdom</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row register-tell-more-checkboxes">
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <input class="register-tell-more-checkbox form-check-input"
                                                            type="checkbox" value="">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            I am willing to undergo 'Know Your Customer' verification as
                                                            required for participation to this offering.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <input class="register-tell-more-checkbox form-check-input"
                                                            type="checkbox" value="">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            I have read and agree to the KIBANX site's Terms of Use and
                                                            Privacy Policy.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-merge">
                                                    <div class="form-check">
                                                        <input class="register-tell-more-checkbox form-check-input"
                                                            type="checkbox" value="">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            I confirm that I am not a citizen, national or resident (tax
                                                            or otherwise) of Andorra, North Korea, Zimbabwe or any
                                                            jurisdiction which prohibits:
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-merge">
                                                    <div class="form-check">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            - the possession, dissemination or communication of the
                                                            Whitepaper and/or prohibits participation in the STO token
                                                            sale, or
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            - the offering, purchase, possession and/or exchange of STO
                                                            tokens or any such similar activity.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <input class="register-tell-more-checkbox form-check-input"
                                                            type="checkbox" value="">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            I confirm that I am a citizen, national or resident (tax or
                                                            otherwise) of the United States.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-check">
                                                        <input class="register-tell-more-checkbox form-check-input"
                                                            type="checkbox" value="">
                                                        <label class="register-tell-more-label form-check-label"
                                                            for="flexCheckDefault">
                                                            I affirm that I am a US Accredited Investor.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="register-investor-next-buttons register-tell-more-next-button">
                                    <button class="btn btn-register-investor-confirmation-next"
                                        @click="next">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import router from '../../router'
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'TellUsMore',
    components: {
        RegisterNav
    },
    methods: {
        next: function () {
            router.push({ name: "SubmissionPage" })
        }
    }
}
</script>