<template>
    <div class="features-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>{{ heading }}</h2>
                <p>The KAIBANX tokenization platform democratizes investors' ability to access institutional grade real
                    estate, which used to be out of reach to average accredited investors. Tokenize your assets or begin
                    buying property tokens on our Digital Exchange.</p>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img1.png" alt="image">
                        <h3>Security First</h3>
                        <p>The technology that powers our platform was developed with industry-leading security and
                            encryption at its core. Our security team is constantly working to make sure you and your
                            assets are protected from emerging threats.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img2.png" alt="image">
                        <h3>Smart Distributions</h3>
                        <p>KIBANX smart contracts and distributed ledger technology allows for transactions on our
                            blockchain to establish ownership rights and facilitate periodic distributions.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-6 mdw-100">
                    <div class="single-features-box">
                        <img src="../../assets/img/features/features-img3.png" alt="image">
                        <h3>24/7 Support</h3>
                        <p>KAIBANX is here to ensure our customers’ success and end-users’ business productivity. We
                            offer dependable, on-demand support options including 24/7 technical and remote support.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features',
    props: ['heading']
}
</script>