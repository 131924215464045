<template>
    <div class="value-trade-area">
        <div class="container">
            <div class="value-trade-slides">
                <carousel :autoplay="2000" :wrap-around="true" :breakpoints="breakpoints" :settings="settings">
                    <slide v-for="coin in coins" :key="coin.id">
                        <div class="single-value-trade-box">
                            <p>
                                {{ coin.name }}
                                /<span class="symbol">{{ coin.symbol }}</span>
                                <span class="price">{{ coin.current_price }}</span>
                                <span class="trending" :class="[
                                    coin.price_change_percentage_24h > 0
                                        ? 'text-default'
                                        : 'text-danger'
                                ]">
                                    <i class="one fas fa-caret-up"></i>
                                    <i class="two fas fa-caret-down"></i>
                                    {{ coin.price_change_percentage_24h }}
                                </span>
                            </p>
                        </div>
                    </slide>

                    <template #addons>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'ValueTrade',
    components: {
        Carousel,
        Slide
    },
    data: () => ({
        // carousel settings
        settings: {
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            576: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
            1550: {
                itemsToShow: 6,
                snapAlign: 'center',
            }
        },
        coins: [],
    }),
    async mounted() {
        const res = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false')
        const data = await res.json()
        this.coins = data;
    },
})
</script>