<template>
    <div class="container my-3 report-setting-appearance">
        <div class="row form-group">
            <div class="col-md-12">
                <div class="layout row text-left">
                    <label for="layout">Layout</label>
                    <div class="col-5 border-top pt-2">
                        <div class="form-check my-3">
                            <input class="form-check-input" type="radio" name="layout" id="block" checked>
                            <label class="form-check-label" for="block">
                                Block
                            </label>
                        </div>
                        <div class="form-check my-3">
                            <input class="form-check-input" type="radio" name="layout" id="stepped">
                            <label class="form-check-label" for="stepped">
                                Stepped
                            </label>
                        </div>
                        <div class="form-check my-3">
                            <input class="form-check-input" type="radio" name="layout" id="outline">
                            <label class="form-check-label" for="outline">
                                Outline
                            </label>
                        </div>
                        <div class="form-check my-3">
                            <input class="form-check-input" type="radio" name="layout" id="horizontal">
                            <label class="form-check-label" for="horizontal">
                                Horizontal
                            </label>
                        </div>
                        <div class="form-check my-3">
                            <input class="form-check-input" type="radio" name="layout" id="mobile">
                            <label class="form-check-label" for="mobile">
                                Mobile
                            </label>
                        </div>
                    </div>
                    <div class="col-7">
                        <img src="https://dev.mcxweb.com/img/layout_stepped.gif" width="233" height="210"
                            id="img_layout" name="img_layout" class="img-thumbnail">
                    </div>
                </div>
                <div class="styles mt-2">
                    <div class="col-8 text-left">
                        <label for="styles">Styles <i class='bx bxs-hot'></i></label>
                        <select name="styles" id="styles" class="form-select mt-2">
                            <option value="grey">Grey</option>
                            <option value="teal">Teal</option>
                            <option value="blue">Blue</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Appearance',
}
</script>