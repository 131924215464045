<template>
    <div class="container my-3">
        <div class="row form-group align-items-center">
            <div class="col-md-11 col-12">
                <div class="cryptocurrency-data-table trackorder-data-table table-responsive">
                    <table id="report-modal-column-labels-table" class="table table-hover text-left">
                        <tbody id="lables_body">
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_0">no_of_ac</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><input
                                            class="input-as-tf" style="width: 100%;" type="text" value="no_of_ac"
                                            id="inputlbl_0" name="inputlbl_0-no_of_ac"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_1">payment_approval_status</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><input
                                            class="input-as-tf" style="width: 100%;" type="text"
                                            value="payment_approval_status" id="inputlbl_1"
                                            name="inputlbl_1-payment_approval_status"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_2">last_payment_approval_log_id</label>
                                </td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><input
                                            class="input-as-tf" style="width: 100%;" type="text"
                                            value="last_payment_approval_log_id" id="inputlbl_2"
                                            name="inputlbl_2-last_payment_approval_log_id"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_3">paidToDate</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><input
                                            class="input-as-tf" style="width: 100%;" type="text" value="paidToDate"
                                            id="inputlbl_3" name="inputlbl_3-paidToDate"></div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_4">payment_date</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><input
                                            class="input-as-tf" style="width: 100%;" type="text" value="payment_date"
                                            id="inputlbl_4" name="inputlbl_4-payment_date"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Labels',
}
</script>