<template>
    <div class="cta-area pt-100">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cta-content">
                        <h2>Signing up is easy to do</h2>
                        <p>
                            Don’t waste time and get in touch with your circle of friends and
                            share the knowledge while earning money.
                        </p>
                        <router-link to="/register" class="default-btn">
                            <i class='bx bxs-user'></i>
                            Register Now
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="cta-image">
                        <img src="../../assets/img/man.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape15"><img src="../../assets/img/shape/shape15.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTrading',
    props: ['heading']
}
</script>