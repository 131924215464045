<template>
    <div id="chart">
        <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            series: [15, 18, 40, 17, 10],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: ["Under $25k", "$25k-$50k", "$50k-$100k", "$100k-$150k", "Over $150k"],
                theme: {
                    monochrome: {
                        enabled: true
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name]
                    }
                },
                legend: {
                    show: false
                }

            }
        };
    },
}
</script>