<template>
    <div class="container">
        <div class="sub-breadcrumbs">
            <router-link to="/my-property/property" class="property-link"
                :class="{ 'selected_option': selected_option == 'Property Home' }">
                Property Home
            </router-link>
            <router-link to="/my-property/price-history" class="property-link"
                :class="{ 'selected_option': selected_option == 'Price History' }">
                Price History
            </router-link>
            <router-link to="/my-property/trade-and-orders" class="property-link"
                :class="{ 'selected_option': selected_option == 'Trade & Orders' }">
                Trade & Orders
            </router-link>
            <router-link to="/my-property/reporting" class="property-link"
                :class="{ 'selected_option': selected_option == 'Reporting' }">
                Reporting
            </router-link>
            <router-link to="/my-property/distributions" class="property-link"
                :class="{ 'selected_option': selected_option == 'Distributions' }">
                Distributions
            </router-link>
            <router-link to="/my-property/gallery" class="property-link"
                :class="{ 'selected_option': selected_option == 'Gallery' }">
                Gallery
            </router-link>
            <router-link to="/my-property/library" class="property-link"
                :class="{ 'selected_option': selected_option == 'Library' }">
                Library
            </router-link>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Breadcrumb',
    props: ['selected_option'],
}
</script>