<template>
    <Filter @emitButtonClick="clicked" />
    <div class="cryptocurrency-data-table table-responsive admin-my-property-listing-table">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center p-2">Property</th>
                    <th class="text-center p-2">Price</th>
                    <th class="text-center p-2">Tokens</th>
                    <th class="text-center p-2">Status</th>
                    <th class="text-center p-2">Search Score</th>
                    <th class="text-center p-2">Page Views</th>
                    <th class="text-center p-2">Visitors</th>
                    <th class="text-center p-2">OM Downloads</th>
                    <th class="text-center p-2">Actions</th>
                </tr>
            </thead>
            <tbody>
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
                <TableRow />
            </tbody>
        </table>
    </div>
</template>

<script>
import Filter from '../Filter';
import TableRow from './TableRow';

export default {
    name: "MyPropertyAdminMyListings",
    components: {
        Filter,
        TableRow,
    },
    emits: ['emitFunction'],
    methods: {
        clicked: function () {
            this.$emit('emitFunction', 'add');
        }
    }
}
</script>