<template>
    <Filter :current='current' />
    <div class="container mb-20">
        <Card v-if="current == 'card'" />
    </div>
</template>

<script>
import { ref } from 'vue';
import Filter from './Filter';
import Card from './Card';

const items = ref([{ heading: "File Name" }, { heading: "Description" }, { heading: "Document type" }, { heading: "Business Area" }, { heading: "Modified" }, { heading: "Modified By" }, { heading: "Actions" }])
export default {
    name: 'Main',
    data() {
        return {
            items: items,
            current: 'card',
        }
    },
    components: {
        Filter,
        Card,
    }
}
</script>