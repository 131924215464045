<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-12">
                <select name="properties" id="properties" class="form-select mr-2">
                    <option value="">All Values</option>
                    <option value="">Retail</option>
                    <option value="">Multipfamily</option>
                    <option value="">Office</option>
                    <option value="">Industrial</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-12">
                <select name="transactions" id="transactions" class="form-select mr-2">
                    <option value="">Any Price</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-12">
                <select name="distributions" id="distributions" class="form-select mr-2">
                    <option value="">Any Cap Rate</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-12">
                <select name="admin" id="admin" class="form-select mr-2">
                    <option value="">All Filters</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-6">
                <button class="btn btn-primary btn-save-search">Save Search</button>
            </div>
            <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-end">
                <div class="form-check form-switch">
                    <label class="form-check-label" for="showPropertiesMap">Show Map</label>
                    <input class="form-check-input" type="checkbox" id="showPropertiesMap" @click="toggle">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let isActive = false;

import router from '../../router';

export default {
    name: 'Filter',
    data() {
        return {
            isActive
        };
    },
    methods: {
        toggle() {
            if (!this.isActive) {
                this.isActive = true;
                router.push({ name: "My Property Map" })

            } else {
                this.isActive = false;
                router.push({ name: "My Properties" })
            }
        },
    }
}
</script>