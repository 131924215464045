<template>
    <div class="account-create-process-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-8 col-lg-9 col-md-12">
                    <div class="account-create-process-content">
                        <div class="section-title">
                            <h2>Get Started in a Few Minutes</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon1.png" alt="image">
                                    </div>
                                    <h3>Create Account</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon2.png" alt="image">
                                    </div>
                                    <h3>Link Your Bank Account</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-6 mdw-100 col-md-6">
                                <div class="single-process-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon3.png" alt="image">
                                    </div>
                                    <h3>Start Buying & Selling</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-3 col-md-12">
                    <div class="account-create-process-image text-center">
                        <img src="../../assets/img/convert-currency.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GetStarted'
}
</script>