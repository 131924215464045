<template>
    <div id="chart">
        <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            series: [{
                data: [1390, 1200, 1100, 690, 580]
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    bar: {
                        barHeight: '100%',
                        distributed: true,
                        horizontal: true,
                        dataLabels: {
                            position: 'bottom'
                        },
                    }
                },
                colors: ['#0071CD', '#D9D7D9', '#D9D7D9', '#D9D7D9', '#D9D7D9'],
                dataLabels: {
                    enabled: true,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff', '#000', '#000', '#000', '#000']
                    },
                    formatter: function (val, opt) {
                        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                    },
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: ['Office & Administrative Support', 'Management', 'Sales & Related', 'Production', 'Food Prep & Service'],
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: false
                    }
                },
                tooltip: {
                    theme: 'dark',
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                }
            }
        };
    },
}
</script>