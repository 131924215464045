<template>
    <div class="my-properties-admin-filters mb-3">
        <div class="row">
            <div class="col-lg-2 col-6 md-mb-2">
                <select name="properties" id="properties" class="form-select">
                    <option value="">My Listings</option>
                </select>
            </div>
            <div class="col-lg-4 col-6 md-mb-2">
                <div class="input-group">
                    <input type="text" id="searchbox" class="form-control"
                        placeholder="Search by name, location or brokerage">
                    <span class="input-group-text" id="inputGroupappend"><i class='bx bx-search'></i></span>
                </div>
            </div>
            <div class="col-lg-4 col-6 sort-by-filter">
                <label for="search" class="mr-2">Sort by:</label>
                <select name="distributions" id="distributions" class="form-select sort-by">
                    <option value="">Default (Activity)</option>
                </select>
            </div>
            <div class="col-lg-2 col-6 d-flex align-items-center justify-content-end p-md-0">
                <button class="btn btn-primary" @click="this.clicked()">Add Property</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
    emits: ['emitButtonClick'],
    methods: {
        clicked: function () {
            this.$emit('emitButtonClick');
        }
    }
}
</script>