<template>
    <div class="container investment-part-2">
        <div class="row investment-part-2-options">
            <div class="col-lg-8 col-md-7 col-12 align-self-center mb-3">
                <div class="row">
                    <div class="col text-pointer" v-for="(tab, ci) in tabs" :key="ci">
                        <p class="text-blue-700 text-base hover:text-black"
                            :class="[{ 'active-tab': tab === currentTab }]" @click="swapComponent(tab)">
                            {{ (tab == 'PropertyRiskAnalysis')? 'Risk Analysis': tab.replace('Property', '') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-5 col-12 mb-3 text-right">
                <a class="btn btn-main" href="#">Export</a>
            </div>
        </div>
    </div>
    <div class="container pt-3 pb-20" id="property-sub-detail">
        <component :is="currentTab">
        </component>
    </div>
</template>
<script>
import PropertyDetail from "./PropertyDetail";
import PropertyLocation from "./PropertyLocation";
import PropertyCommentary from "./PropertyCommentary";
import PropertyPerformance from "./PropertyPerformance";
import PropertyRiskAnalysis from "./PropertyRiskAnalysis";

export default ({
    el: '#property-sub-detail',
    data() {
        return {
            currentTab: 'PropertyDetail',
            tabs: ['PropertyDetail', 'PropertyLocation', 'PropertyCommentary', 'PropertyPerformance', 'PropertyRiskAnalysis']
        }
    },
    components: {
        PropertyDetail,
        PropertyLocation,
        PropertyCommentary,
        PropertyPerformance,
        PropertyRiskAnalysis,
    },
    props: ['componentsArray'],
    methods: {
        swapComponent: function (component) {
            this.currentTab = component;
        }
    }
})
</script>