<template>
    <div class="row">
        <div class="col-12 overflow-x-auto">
            <div class="cryptocurrency-data-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="w-5 p-1.5">Reporting Period</th>
                            <th class="text-center p-2 w-5">
                                <select name="underwriting" id="underwriting" class="text-center">
                                    <option value="">Underwriting</option>
                                    <option value="">Q1-2022</option>
                                    <option value="" selected>Q2-2022</option>
                                    <option value="">Q3-2022</option>
                                    <option value="">Q4-2022</option>
                                    <option value="">Q1-2023</option>
                                    <option value="">Q2-2023</option>
                                    <option value="">Q3-2023</option>
                                    <option value="">Q4-2023</option>
                                </select>
                            </th>
                            <th class="text-center p-2 w-5">
                                <select name="underwriting2" id="underwriting2" class="text-center">
                                    <option value="">Underwriting</option>
                                    <option value="" selected>Q1-2022</option>
                                    <option value="">Q2-2022</option>
                                    <option value="">Q3-2022</option>
                                    <option value="">Q4-2022</option>
                                    <option value="">Q1-2023</option>
                                    <option value="">Q2-2023</option>
                                    <option value="">Q3-2023</option>
                                    <option value="">Q4-2023</option>
                                </select>
                            </th>
                            <th class="text-center p-2 w-5">Variance</th>
                            <th class="text-center p-2 w-80">Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <WatchlistPerformanceTable tokenName="All in Cost" token2="1,000,000" token3="1,000,000"
                            token4="0" rowColor="bg-gray-200" />
                        <WatchlistPerformanceTable tokenName="Historical Cash Flows" token2="200,000" token3="200,000"
                            token4="0" rowColor="bg-white" />
                        <WatchlistPerformanceTable tokenName="Projected Cash Flows" token2="1,200,000"
                            token3="1,200,000" token4="0" rowColor="bg-gray-200" />
                        <WatchlistPerformanceTable tokenName="Total Cash Flows" token2="1,400,000" token3="1,400,000"
                            token4="0" rowColor="bg-white" />
                        <WatchlistPerformanceTable tokenName="Disposition Date" token2="March-2028" token3="March-2028"
                            token4="-" rowColor="bg-gray-200" />
                        <WatchlistPerformanceTable tokenName="Profit" token2="400,000" token3="400,000" token4="0"
                            rowColor="bg-white" />
                        <WatchlistPerformanceTable tokenName="IRR" token2="24.6%" token3="24.6%" token4="-"
                            rowColor="bg-gray-200" />
                        <WatchlistPerformanceTable tokenName="Gross Multiple" token2="1.4X" token3="1.4X" token4="-"
                            rowColor="bg-white" />
                        <WatchlistPerformanceTable tokenName="Wtd Avg Life (yrs)" token2="6.5" token3="6.5" token4="-"
                            rowColor="bg-gray-200" />
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import WatchlistPerformanceTable from "./WatchlistPerformanceTable";
export default ({
    name: "WatchlistPerformance",
    components: {
        WatchlistPerformanceTable,
    }
})
</script>