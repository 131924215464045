<template>
    <div>
        <InvestorNo />
    </div>
</template>

<script>
import InvestorNo from '../LoginRegister/InvestorNo'

export default {
    name: 'InvestorNoPage',
    components: {
        InvestorNo
    }
}
</script>