<template>
    <div class="banner-wrapper-area">
        <div class="container">
            <div class="row align-items-center m-0">
                <div class="col-xl-5 col-lg-6 col-md-12 p-0">
                    <div class="banner-wrapper-content">
                        <span class="sub-title">First order and you’ll get up to $10 in free BTC as a reward</span>
                        <h1>A Trusted and Secure Cryptocurrency Exchange</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing elit.</p>
                        <form>
                            <input type="email" class="input-newsletter" placeholder="Enter your Email or Phone" name="EMAIL">
                            <button type="submit">
                                Get Started 
                                <i class='bx bx-chevron-right'></i>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="col-xl-7 col-lg-6 col-md-12 p-0">
                    <div class="banner-wrapper-image">
                        <img src="../../assets/img/banner/banner-img3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>