<template>
    <div class="container my-properties-admin mb-20">
        <div class="row">
            <div class="col-lg-2 col-md-7 col-12 my-properties-admin-sidebar">
                <ul class="nav flex-column only-md">
                    <div class="nav-item" v-for="(tab, ci) in tabs" :key="ci">
                        <a class="nav-link" href="#" :class="[{ 'active': tab === currentTab }]"
                            @click="swapComponent(tab)">
                            {{ tab_names[ci] }}
                        </a>
                    </div>
                </ul>
                <div class="form-group only-sm mb-3">
                    <select class="form-select" @change="swapComponent($event.target.value)">
                        <option v-for="(tab, ci) in tabs" :key="ci" :value="tab"
                            :selected="[{ 'selected': tab === currentTab }]">
                            {{ tab_names[ci] }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-lg-10 col-md-12 col-12" id="my_properties_admin_main_content">
                <component :is="currentTab" :param="params">
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import MyListings from './MyListings/MyListings';
import MyListings1 from './MyListings/MyListings';
import MyListings2 from './MyListings/MyListings';
import MyListings3 from './MyListings/MyListings';
import MyListings4 from './MyListings/MyListings';
import MyListings5 from './MyListings/MyListings';

export default {
    el: '#my_properties_admin_main_content',
    name: 'Admin',
    props: ['componentsArray'],
    data() {
        return {
            currentTab: 'MyListings',
            tabs: ['MyListings', 'MyListings1', 'MyListings2', 'MyListings3', 'MyListings4', 'MyListings5'],
            tab_names: ['My Listings', 'Lead Activity', 'Contacts', 'Email Lists', 'Email Campaigns', 'Notification Settings'],
            params: 'main',
        }
    },
    components: {
        MyListings,
        MyListings1,
        MyListings2,
        MyListings3,
        MyListings4,
        MyListings5,
    },
    methods: {
        swapComponent: function (component) {
            this.params = 'main';
            this.currentTab = component;
        }
    }
}
</script>