<template>
    <div>
        <Navbar />
        <Table />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Footer from '../Layouts/Footer'
import Table from "../TrackOrder/Table"

export default {
    name: 'TrackOrder',
    components: {
        Navbar,
        Table,
        Footer,
    }
}
</script>