<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <PropertyBreadCrumbs selected_option="Distributions" />
        <Distribution />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import PropertyBreadCrumbs from '../Exchange/Property/Breadcrumb'
import Distribution from "../Exchange/Property/Distribution/Distribution"
import Footer from '../Layouts/Footer'
export default {
    name: 'ExchangeDistribution',
    components: {
        Navbar,
        Breadcrumb,
        PropertyBreadCrumbs,
        Distribution,
        Footer,
    },
    data() {
        return {
            crumbs: ['Exchange', '45 Wilshire Blvd']
        }
    }
}
</script>