<template>
    <div>
        <Navbar/>
        <SellCryptocurrency />
        <SellBitcoin />
        <BestSellers heading="Sell Popular Crypto Instantly" />
        <PaymentMethods />
        <FeedbackSlider />
        <StartTrading heading="Coin-to-Coin Temo" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import SellCryptocurrency from '../Sell/SellCryptocurrency'
import SellBitcoin from '../Sell/SellBitcoin'
import BestSellers from '../Common/BestSellers'
import PaymentMethods from '../Common/PaymentMethods'
import FeedbackSlider from '../Common/FeedbackSlider'
import StartTrading from '../Common/StartTrading'
import Footer from '../Layouts/Footer'

export default {
    name: 'BuyPage',
    components: {
        Navbar,
        SellCryptocurrency,
        SellBitcoin,
        BestSellers,
        PaymentMethods,
        FeedbackSlider,
        StartTrading,
        Footer,
    }
}
</script>