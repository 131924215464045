<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-md-2 col-12">
                <select name="properties" id="properties" class="form-select mr-2">
                    <option value="">All Types</option>
                    <option value="">Buy</option>
                    <option value="">Sell</option>
                </select>
            </div>
            <div class="col-md-2 col-12">
                <select name="transactions" id="transactions" class="form-select mr-2">
                    <option value="">Any Price</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
}
</script>