<template>
    <div class="report-modal-sub-tabs">
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Appearance' }" @click="swapSubTabs('Appearance')">
            <i class='bx bx-list-ul'></i> Appearance
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Security' }" @click="swapSubTabs('Security')">
            <i class='bx bxs-lock-alt'></i> Security
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Titles' }" @click="swapSubTabs('Titles')">
            <i class='bx bx-edit-alt'></i> Titles
        </div>
    </div>
    <div>
        <Appearance v-if="subTab == 'Appearance'" />
        <Security v-if="subTab == 'Security'" />
        <Titles v-if="subTab == 'Titles'" />
    </div>
</template>

<script>
import Appearance from './Settings/Appearance.vue';
import Security from './Settings/Security.vue';
import Titles from './Settings/Titles.vue';

export default {
    name: 'SettingsMain',
    data() {
        return {
            subTab: "Appearance",
        }
    },
    components: {
        Appearance,
        Security,
        Titles,
    },
    methods: {
        swapSubTabs: function (tab) {
            this.subTab = tab;
        }
    }
}
</script>