<template>
    <div class="cryptocurrency-platform-area">
        <div class="container-fluid">
            <div class="section-title">
                <h2>Treating all our Investors as Institutional</h2>
                <p>We treat all our clients with the un-paralleled white glove service. Whether you're a savy real
                    estate invester with years experience or accredited investor looking to get the type of exposure
                    normally only accessible by larger funds.</p>
            </div>
            <div class="cryptocurrency-platform-slides">
                <carousel :autoplay="5000" :breakpoints="breakpoints" :settings="settings">
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-cryptocurrency-platform-box">
                            <img :src="slide.image" alt="image">
                            <div class="content">
                                <div class="icon">
                                    <img :src="slide.Icon" alt="image">
                                </div>
                                <h3>
                                    <router-link to="cryptocurrency-details">
                                        {{ slide.heading }}
                                    </router-link>
                                </h3>
                                <p>{{ slide.description }}</p>
                                <router-link to="/cryptocurrency-details" class="link-btn">
                                    View More
                                    <i class='bx bx-chevron-right'></i>
                                </router-link>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
    name: 'TrustedCryptocurrency',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/img/cryptocurrency-platform/cryptocurrency-platform-1.jpg'),
                Icon: require('../../assets/img/icon/icon4.png'),
                heading: 'Protected by Insurance',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            },
            {
                id: 2,
                image: require('../../assets/img/cryptocurrency-platform/cryptocurrency-platform-2.jpg'),
                Icon: require('../../assets/img/icon/icon5.png'),
                heading: 'Build Your Reputation',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            },
            {
                id: 3,
                image: require('../../assets/img/cryptocurrency-platform/cryptocurrency-platform-3.jpg'),
                Icon: require('../../assets/img/icon/icon6.png'),
                heading: 'Earn Extra Income',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            },
            {
                id: 4,
                image: require('../../assets/img/cryptocurrency-platform/cryptocurrency-platform-4.jpg'),
                Icon: require('../../assets/img/icon/icon7.png'),
                heading: 'Secure Storage',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            },
            {
                id: 5,
                image: require('../../assets/img/cryptocurrency-platform/cryptocurrency-platform-1.jpg'),
                Icon: require('../../assets/img/icon/icon4.png'),
                heading: 'Protected by Insurance',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            },
        ],
        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 2.1,
                snapAlign: 'center',
            },
        },
    }),
})
</script>