<template>
    <div>
        <Login />
    </div>
</template>

<script>
import Login from '../LoginRegister/Login'

export default {
    name: 'LoginPage',
    components: {
        Login
    }
}
</script>