<template>
    <div class="tokens-area pb-100">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="tokens-image">
                        <img src="../../assets/img/tokens.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="tokens-content">
                        <h2>Pre-Sale Ends In</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                        <div id="countdown">
                            <ul>
                                <li><span id="days">{{days}}</span>DAYS</li>
                                <li><span id="hours">{{hours}}</span>HOURS</li>
                                <li><span id="minutes">{{minutes}}</span>MIN</li>
                                <li><span id="seconds">{{seconds}}</span>SEC</li>
                            </ul>
                        </div>
                        <div class="token-price">
                            <div class="d-flex">
                                <div class="box">
                                    <span>Token Price</span>
                                    <h3>1 ICOX=$0.08</h3>
                                </div>
                                <div class="box">
                                    <span>We Accept</span>
                                    <div>
                                        <img src="../../assets/img/ethereum.png" alt="image"> ETH
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="total-price">
                            <span>Tokens Available On Pre-Sale <span>75,000,000 ICOX</span></span>
                        </div>
                        <div class="btn-box">
                            <router-link to="/buy" class="default-btn">
                                <i class='bx bxs-discount'></i> 
                                Buy Tokens 55% Off
                            </router-link>
                            <a href="#" target="_blank" class="default-btn"><i class='bx bxs-file'></i> White Pappers</a>
                        </div>
                        <div class="payment-methods">
                            <img src="../../assets/img/master-card.png" alt="image">
                            <img src="../../assets/img/paypal.png" alt="image">
                            <img src="../../assets/img/bitcoin2.png" alt="image">
                            <img src="../../assets/img/visa.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PreSale',
    data() {
        return {
            days: '',
            hours: '',
            minutes: '',
            seconds: ''
        }
    },
    created() { // Turn data into viewable values
        setInterval(() => {
            this.commingSoonTime();
        }, 1000);
    },
    methods: {
        commingSoonTime() {
            let endTime = new Date("December 5, 2025 17:00:00 PDT");            
            let endTimeParse = (Date.parse(endTime)) / 1000;
            let now = new Date();
            let nowParse = (Date.parse(now) / 1000);
            let timeLeft = endTimeParse - nowParse;
            let days = Math.floor(timeLeft / 86400);
            let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
            let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
            let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
            if (hours < "10") { hours = "0" + hours; }
            if (minutes < "10") { minutes = "0" + minutes; }
            if (seconds < "10") { seconds = "0" + seconds; }
            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        }
    }
}
</script>