<template>
    <div class="container mt-4 mb-20">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">Name</th>
                                <th class="text-center p-2">Market</th>
                                <th class="text-center p-2">Sub-Market</th>
                                <th class="text-center p-2">Property type</th>
                                <th class="text-center p-2">Property Subtype</th>
                                <th class="text-center p-2">Square Footage</th>
                                <th class="text-center p-2">NRA (sq ft)</th>
                                <th class="text-center p-2">Occupancy</th>
                                <th class="text-center p-2">Year Built</th>
                                <th class="text-center p-2">Lot Size (sq ft)</th>
                                <th class="text-center p-2">Zoning</th>
                                <th class="text-center p-2">Broker Co-Op</th>
                                <th class="text-center p-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRowVue tokenName="A&E TOKEN 1" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 2" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 3" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 4" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 5" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 6" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 7" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 1" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 2" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 3" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 4" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone5" rowColor="bg-white" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableRowVue from './TableRow';

export default {
    name: 'Table',
    components: {
        TableRowVue
    }
}
</script>