<template>
    <div>
        <Owner />
    </div>
</template>

<script>
import Owner from '../LoginRegister/Owner'

export default {
    name: 'OwnerPage',
    components: {
        Owner
    }
}
</script>