<template>
    <div class="buy-sell-cryptocurrency-area bg-image pt-70">
        <div class="container">
            <div class="section-title">
                <h2>How to Buy and Sell Cryptocurrency</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-image">
                        <img src="../../assets/img/women-with-tab.png" alt="image">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon12.png" alt="image">
                                    </div>
                                    <h3>Bank Transfers</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon13.png" alt="image">
                                    </div>
                                    <h3>Online Wallets</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon14.png" alt="image">
                                    </div>
                                    <h3>Cash Payment</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon15.png" alt="image">
                                    </div>
                                    <h3>Debit/Credit Cards</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon16.png" alt="image">
                                    </div>
                                    <h3>Discounted Gift Cards</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon17.png" alt="image">
                                    </div>
                                    <h3>Goods & Services</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyAndSell'
}
</script>