<template>
    <div>
        <apexchart width="100%" type="line" height="300px" stacked=false :options="chartOptions" :series="series">
        </apexchart>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            chartOptions: {
                chart: {
                    id: "vuechart-example",
                },
                datalabels: {
                    enabled: false
                },
                color: "#247BA0",
                xaxis: {
                    categories: [2019, 2020, 2021, 2022],
                },
            },
            series: [
                {
                    name: "series-1",
                    data: [30, 40, 35, 50],
                },
            ],
        };
    },
}
</script>