<template>
    <form>
        <div class="row mt-3 admin-add-property-step-3">
            <div class="col-12">
                <span class="admin-add-property-name">Property Name: 100 Main Street</span>
                <span class="admin-add-property-description">Offering Memorandum & Flyer</span>
                <div class="admin-add-property-offering-memorandum">
                    <span class="admin-add-property-heading">Offering Memorandum</span>
                    <hr class="hr">
                    <div class="add-files-block">
                        <div>
                            <button type="button" class="btn btn-primary">Add Files</button>
                            <span class="add-files-text">
                                <i class='bx bx-cloud-upload'></i> Drop files here or click ADD to upload
                            </span>
                        </div>
                    </div>
                    <div class="form-check-group">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Public OM
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                Private OM (CA required)
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                Private OM (CA and approval required)
                            </label>
                        </div>
                    </div>
                    <span class="admin-add-property-detail">
                        If your OM is behind a Confidentiality Agreement select "Private OM"
                    </span>
                    <hr class="hr">
                </div>
                <div class="admin-add-property-flyer">
                    <span class="admin-add-property-heading">Flyer</span>
                    <hr class="hr">
                    <div class="form-check-group">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Upload your own Flyer
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                                checked>
                            <label class="form-check-label" for="flexRadioDefault2">
                                Use Crexi Flyer
                            </label>
                        </div>
                    </div>
                    <div class="add-files-block">
                        <div>
                            <button type="button" class="btn btn-primary">Add Files</button>
                            <span class="add-files-text">
                                <i class='bx bx-cloud-upload'></i> Drop files here or click ADD to upload
                            </span>
                        </div>
                    </div>
                    <hr class="hr">
                </div>
                <div class="col-11 text-right mt-3">
                    <button class="btn btn-secondary mr-3" type="button" @click="this.back()">Back</button>
                    <button class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#propertymodal">Finish</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    name: "step-5",
    emits: ["emitStep"],
    methods: {
        back: function () {
            this.$emit("emitStep", "4");
        }
    },
}
</script>