<template>
    <div class="report-modal-sub-tabs">
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Tables' }" @click="swapSubTabs('Tables')">
            <i class='bx bx-table'></i> Tables
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Filters' }" @click="swapSubTabs('Filters')">
            <i class='bx bx-filter-alt'></i> Filters
        </div>
    </div>
    <div>
        <Tables v-if="subTab == 'Tables'" />
        <Filters v-if="subTab == 'Filters'" />
    </div>
</template>

<script>
import Tables from './DataSource/Tables'
import Filters from './DataSource/Filters'

export default {
    name: 'DataSource',
    data() {
        return {
            subTab: "Tables",
        }
    },
    components: {
        Tables,
        Filters,
    },
    methods: {
        swapSubTabs: function (tab) {
            this.subTab = tab;
        }
    }
}
</script>