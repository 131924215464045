<template>
    <div class="profile-authentication-area register">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>Which of the following best describes you?</h3>
                                <div class="register-block-list mt-5">
                                    <router-link to="/register/investor" class="register-block-li">
                                        <div class="register-block-icon">
                                            <i class='bx bx-dollar-circle'></i>
                                        </div>
                                        <p class="register-block-title">Individual Investor</p>
                                        <p class="register-block-detail">I would like to invest on behalf of myself, my
                                            family, or my company.</p>
                                    </router-link>
                                    <router-link to="/register/property-owner" class="register-block-li">
                                        <div class="register-block-icon">
                                            <i class='bx bx-briefcase'></i>
                                        </div>
                                        <p class="register-block-title">Property Owner</p>
                                        <p class="register-block-detail">I would like to tokenize or fractionalize a
                                            property on behalf of myself, my
                                            family, or my company.</p>
                                    </router-link>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12 register-block-signin-button mt-3">
                                    Already have an account?
                                    <router-link to="/login" class="text-blue">Sign in</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'Register',
    components: {
        RegisterNav
    }
}
</script>