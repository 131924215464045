<template>
    <tr v-bind:class="rowColor" class="mt-8 Price-Table">
        <td class="text-center p-1.5">{{ transType() }}</td>
        <td class="text-center">{{ showDate() }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (12 - 0) + 0) }}</td>
        <td class="text-center p-1.5">{{ notes() }}</td>
        <td class="text-center p-1.5"></td>
    </tr>
</template>
<script >
export default {
    name: "TableRow",
    methods: {
        showDate: function () {
            const months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var today = new Date();
            var before = new Date(2018, 11, 24, 10, 33, 30, 0);
            var d = new Date(today.getTime() + Math.random() * (before.getTime() - today.getTime())),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (day.length < 2) day = '0' + day;

            return months[month] + ' ' + day + ', ' + year;
        },
        transType: function () {
            const months = ["Capital Raise", "Distribution"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        },
        notes: function () {
            const months = ["Transaction on start of project", "Complete phase 1", "Testing", "Debugged"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        }
    }
}
</script>