<template>
    <div class="report-modal-sub-tabs">
        <div class="sub-tab-item" :class="{ 'active': subTab == 'GroupBy' }" @click="swapSubTabs('GroupBy')">
            <i class='bx bx-columns'></i> Group By
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'SortBy' }" @click="swapSubTabs('SortBy')">
            <i class='bx bx-bar-chart-alt-2'></i> Sort By
        </div>
    </div>
    <div>
        <GroupBy v-if="subTab == 'GroupBy'" />
        <SortBy v-if="subTab == 'SortBy'" />
    </div>
</template>

<script>
import GroupBy from './Groups/GroupBy.vue';
import SortBy from './Groups/SortBy.vue';

export default {
    name: 'GroupsMain',
    data() {
        return {
            subTab: "GroupBy",
        }
    },
    components: {
        GroupBy,
        SortBy,
    },
    methods: {
        swapSubTabs: function (tab) {
            this.subTab = tab;
        }
    }
}
</script>