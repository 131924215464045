<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <PropertyBreadCrumbs selected_option="Reporting" />
        <Filter />
        <Subtabs />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import PropertyBreadCrumbs from '../Property/Breadcrumb'
import Filter from '../Property/Reporting/Filter'
import Subtabs from '../Property/Reporting/Subtabs'
import Footer from '../Layouts/Footer'

export default {
    name: 'PropertyReporting',
    components: {
        Navbar,
        Breadcrumb,
        PropertyBreadCrumbs,
        Filter,
        Subtabs,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', '45 Wilshire Blvd']
        }
    }
}
</script>