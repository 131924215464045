<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" :sub_crumbs="sub_crumbs" sub_crumbs_selected="Watchlist"
            is_sub_crumbs_dropdown="true" />
        <Main />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import Main from "../Watchlist/Main"
import Footer from '../Layouts/Footer'


export default {
    name: 'Watchlist',
    components: {
        Navbar,
        Breadcrumb,
        Main,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Tokens', 'Watchlist'],
            sub_crumbs: ['Watchlist', 'My Holdings'],
        }
    }
}
</script>