<template>
    <div>
        <Submission />
    </div>
</template>

<script>
import Submission from '../LoginRegister/Submission'

export default {
    name: 'SubmissionPage',
    components: {
        Submission
    }
}
</script>