<template>
    <div class="wallet-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="wallet-content">
                        <h2>Be Your Own Bank</h2>
                        <p>
                            Crypto wallets are an essential tool for buying, trading and selling cryptocurrencies and
                            security tokens. Investors need them to capture data stored on the blockchain, as well as to
                            protect and validate transaction information.
                        </p>
                        <p>
                            Whether using hot and cold storage, KIBANX wallets offer investors a safe, sophisticated and
                            reliable solution.
                        </p>
                        <router-link to="/register" class="default-btn">
                            <i class='bx bxs-user'></i>
                            Create Your Wallet
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="wallet-image text-center">
                        <img src="../../assets/img/wallet.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'YourOwnBank'
}
</script>