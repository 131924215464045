<template>
    <div class="profile-authentication-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>
                                    <router-link to="/register/tell-us-more"><i class='bx bx-arrow-back'></i>
                                    </router-link>
                                    Submission Complete
                                </h3>
                                <div class="register-submission-block">
                                    <h3>Congratulations! You are on your way to being able to purchase institutional
                                        grade security tokens on the KIBANX platform.</h3>
                                </div>
                                <div class="register-investor-next-buttons">
                                    <button class="btn btn-register-investor-confirmation-next"
                                        @click="next">Done</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import router from '../../router'
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'Submission',
    components: {
        RegisterNav
    },
    methods: {
        next: function () {
            router.push({ name: "Home" })
        }
    }
}
</script>