<template>
    <div class="fedback-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Trusted by Thousands of Users</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
            </div>
            <div class="feedback-slides">
                <carousel 
                    :autoplay="5000"
                    :breakpoints="breakpoints"
                    :settings="settings"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-feedback-item">
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <p>{{slide.description}}</p>
                            <div class="client-info">
                                <div class="d-flex align-items-center">
                                    <img :src="slide.image" alt="image">
                                    <div class="title">
                                        <h3>{{slide.name}}</h3>
                                        <span>{{slide.position}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'FeedbackSlider',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user1.jpg'),
                name: 'David Malan',
                position: 'Developer',
            },
            {
                id: 2,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user2.jpg'),
                name: 'Sarah Taylor',
                position: 'Designer',
            },
            {
                id: 3,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user3.jpg'),
                name: 'Ben Stokes',
                position: 'Manager',
            },
            {
                id: 4,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user4.jpg'),
                name: 'James Andy',
                position: 'Developer',
            },
            {
                id: 5,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user5.jpg'),
                name: 'Alina Smith',
                position: 'Designer',
            },
            {
                id: 6,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user6.jpg'),
                name: 'John Terry',
                position: 'Manager',
            },
            {
                id: 7,
                description: 'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame.',
                image: require('../../assets/img/user/user4.jpg'),
                name: 'James Andy',
                position: 'Developer',
            },
        ],
        // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>