<template>
    <div class="container mt-20 mb-20">
        <div class="cryptocurrency-data-table trackorder-data-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="item in items" :key="item" class="text-center">{{ item.heading }}</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                    <TableRowVue rowColor="bg-gray-200" />
                    <TableRowVue rowColor="bg-white" />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import TableRowVue from './TableRow';

const items = ref([{ heading: "Order ID" }, { heading: "Date/Time" }, { heading: "Buy - Sell" }, { heading: "Quantity" }, { heading: "Price" }, { heading: "Nominal Value" }, { heading: "Fee" }, { heading: "Expiration" }, { heading: "Status" }, { heading: "Actions" }])
export default {
    name: 'Table',
    data() {
        return {
            items: items
        }
    },
    components: {
        TableRowVue
    }
}
</script>