<template>
    <div class="modal fade" id="createReportModal" tabindex="-1" aria-labelledby="createReportModallabel"
        aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createReportModallabel">
                        KIBANX Custom Reporting
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container p-0 my-2">
                        <div class="row">
                            <div class="col-3">
                                <div class="report-modal-step-heading">
                                    Steps
                                </div>
                                <div class="report-modal-step-tab" :class="{ 'active': currentTab == 'Data Source' }"
                                    @click="swapTab('Data Source')">
                                    Data Source
                                </div>
                                <div class="report-modal-step-tab" :class="{ 'active': currentTab == 'Columns' }"
                                    @click="swapTab('Columns')">
                                    Columns
                                </div>
                                <div class="report-modal-step-tab" :class="{ 'active': currentTab == 'Cells' }"
                                    @click="swapTab('Cells')">
                                    Cells
                                </div>
                                <div class="report-modal-step-tab" :class="{ 'active': currentTab == 'Groups' }"
                                    @click="swapTab('Groups')">
                                    Groups
                                </div>
                                <div class="report-modal-step-tab" :class="{ 'active': currentTab == 'Settings' }"
                                    @click="swapTab('Settings')">
                                    Settings
                                </div>
                            </div>
                            <div class="col-9">
                                <DataSourceVue v-if="currentTab == 'Data Source'" />
                                <Columns v-if="currentTab == 'Columns'" />
                                <Cells v-if="currentTab == 'Cells'" />
                                <Groups v-if="currentTab == 'Groups'" />
                                <Settings v-if="currentTab == 'Settings'" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-previous"
                        :class="{ 'disabled': currentTab == 'Data Source' }" @click="prev">
                        Previous
                    </button>
                    <button type="button" class="btn btn-primary btn-next" v-if="currentTab != 'Settings'"
                        @click="next">
                        Next
                    </button>
                    <button type="button" class="btn btn-complete" data-bs-dismiss="modal"
                        v-if="currentTab == 'Settings'">
                        Complete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DataSourceVue from './ReportModal/DataSource.vue';
import Columns from './ReportModal/Columns.vue';
import Cells from './ReportModal/Cells.vue';
import Groups from './ReportModal/Groups.vue';
import Settings from './ReportModal/Settings.vue';

export default {
    name: "ReportModal",
    components: {
        DataSourceVue,
        Columns,
        Cells,
        Groups,
        Settings,
    },
    data() {
        return {
            currentTab: "Data Source",
        }
    },
    methods: {
        swapTab: function (val) {
            this.currentTab = val;
        },
        next: function () {
            if (this.currentTab == "Data Source") {
                this.currentTab = "Columns";
            } else if (this.currentTab == "Columns") {
                this.currentTab = "Cells";
            } else if (this.currentTab == "Cells") {
                this.currentTab = "Groups";
            } else if (this.currentTab == "Groups") {
                this.currentTab = "Settings";
            }
        },
        prev: function () {
            if (this.currentTab == "Settings") {
                this.currentTab = "Groups";
            } else if (this.currentTab == "Groups") {
                this.currentTab = "Cells";
            } else if (this.currentTab == "Cells") {
                this.currentTab = "Columns";
            } else if (this.currentTab == "Columns") {
                this.currentTab = "Data Source";
            }
        }
    }
}
</script>