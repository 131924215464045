<template>
    <tr v-bind:class="rowColor" class="mt-8 Price-Table">
        <td class="text-center">{{ showDate() }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (12 - 0) + 0) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">${{ Math.floor(Math.random() * (200 - 110) + 110) }}</td>
        <td class="text-center p-1.5">{{ Math.floor(Math.random() * (7000000 - 1000000) + 1000000) }}</td>
    </tr>
</template>
<script >
export default {
    props: [],
    created() {
    }
    ,
    methods: {
        showDate: function () {
            var today = new Date();
            var before = new Date(2018, 11, 24, 10, 33, 30, 0);
            var d = new Date(today.getTime() + Math.random() * (before.getTime() - today.getTime())),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [day, month, year].join('-');
        },
    }
}
</script>