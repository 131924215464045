<template>
    <div class="profile-authentication-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 col-md-12">
                            <div class="register-form row">
                                <h3>
                                    <router-link to="/register/investor"><i class='bx bx-arrow-back'></i></router-link>
                                    Unfortunately, trading of KIBANX real estate security tokens is only available to
                                    accredited investors.
                                </h3>
                                <p class="register-investor-mainline"> You can still create an account to view our real
                                    estate token offerings, create watchlists, as well as get insightful analysis on the
                                    latest real estate trends.</p>
                                <div class="register-non-investor-container">
                                    <form class="register-non-investor-form">
                                        <div class="container p-0 mt-3">
                                            <div class="row">
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="first_name" class="form-label">First Name</label>
                                                    <input type="text" name="first_name" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="last_name" class="form-label">Last Name</label>
                                                    <input type="text" name="last_name" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="email" class="form-label">Email</label>
                                                    <input type="text" name="email" class="form-control">
                                                </div>
                                                <div class="col-md-6 col-12 form-group">
                                                    <label for="phone_number" class="form-label">Phone Number</label>
                                                    <input type="phone" name="phone_number" class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="register-investor-next-buttons">
                                    <button class="btn btn-register-investor-confirmation-next"
                                        @click="next">Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterNav />
    </div>
</template>

<script>
import router from '../../router';
import RegisterNav from '../Layouts/RegisterNav'

export default {
    name: 'InvestorNo',
    components: {
        RegisterNav
    },
    methods: {
        next: function () {
            router.push({ name: "TellUsMorePage" })
        }
    }
}
</script>