<template>
    <div class="container mb-20">
        <div class="cryptocurrency-data-table remove-left-data-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th v-for="item in items" :key="item"
                            :class="(item.heading == 'File Name') ? 'text-left' : 'text-center'">{{ item.heading }}</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowVue filename="Offering Memorandum" rowColor="bg-gray-200" />
                    <TableRowVue filename="Term Sheets" rowColor="bg-white" />
                    <TableRowVue filename="Tax Structure" rowColor="bg-gray-200" />
                    <TableRowVue filename="Management Presentation" rowColor="bg-white" />
                    <TableRowVue filename="Financial Statements" rowColor="bg-gray-200" />
                    <TableRowVue filename="Deal Model" rowColor="bg-white" />
                    <TableRowVue filename="Due Diligence Summary" rowColor="bg-gray-200" />
                    <TableRowVue filename="Token Documents" rowColor="bg-white" />
                    <TableRowVue filename="Operational Records" rowColor="bg-gray-200" />
                    <TableRowVue filename="Market Analysis" rowColor="bg-white" />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import TableRowVue from './TableRow';

const items = ref([{ heading: "File Name" }, { heading: "Description" }, { heading: "Document type" }, { heading: "Business Area" }, { heading: "Modified" }, { heading: "Modified By" }, { heading: "Actions" }])
export default {
    name: 'Table',
    data() {
        return {
            items: items
        }
    },
    components: {
        TableRowVue
    }
}
</script>