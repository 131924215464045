<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <MyHoldingsDetailBreadCrumbs />
        <MyHoldingsDetails />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import MyHoldingsDetailBreadCrumbs from '../MyHoldings/Summary/Details/MyHoldingsDetailBreadCrumbs'
import MyHoldingsDetails from "../MyHoldings/Summary/Details/Main"
import Footer from '../Layouts/Footer'

export default {
    name: 'MyHoldingsDetail',
    components: {
        Navbar,
        Breadcrumb,
        MyHoldingsDetailBreadCrumbs,
        MyHoldingsDetails,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Holdings', '45 Wilshire Blvd']
        }
    }
}
</script>