<template>
    <div class="my-listing-block">
        <div v-if="currentTab == 'main'">
            <Main @emitFunction="addProperty" />
        </div>
        <div v-if="currentTab == 'add'">
            <Add @emitFunction="addProperty" />
        </div>
    </div>

</template>

<script>
import Main from './Main';
import Add from './AddProperty/Add';

export default {
    el: '.my-listing-block',
    name: "MyPropertyAdmin",
    props: ['componentsArray', 'param'],
    components: {
        Main,
        Add,
    },
    data() {
        return {
            currentTab: this.param,
        }
    },
    methods: {
        addProperty: function (val) {
            this.currentTab = val;
        }
    },
    mounted: function () {
        console.log(this.currentTab);
    }
}
</script>