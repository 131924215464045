<template>
    <div id="chart">
        <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            series: [24, 8, 28, 32, 23],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: ["Under 20", "20-25", "25-45", "45-65", "Over 65"],
                theme: {
                    monochrome: {
                        enabled: true
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name]
                    }
                },
                legend: {
                    show: false
                }

            }
        };
    },
}
</script>