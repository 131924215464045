<template>
    <div class="container my-3 report-setting-security">
        <div class="row form-group text-left">
            <div class="col-md-12">
                <div class="form-group text-left mb-3">
                    <label for="title" class="form-label">Report Title</label>
                    <input name="title" id="title" class="form-control" placeholder="Report Title">
                </div>
                <div class="form-group text-left mb-3">
                    <label for="footer" class="form-label">Report Footer</label>
                    <textarea name="footer" id="footer" class="form-control" placeholder="Report Footer"></textarea>
                </div>
                <div class="form-group text-left mb-3">
                    <label for="header" class="form-label">Report Header</label>
                    <textarea name="header" id="header" class="form-control" placeholder="Report Header"></textarea>
                </div>
                <div class="form-group text-left mb-3">
                    <label for="record" class="form-label">Records per page</label>
                    <input name="record" id="record" class="form-control" placeholder="Records per page">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Titles',
}
</script>