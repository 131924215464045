<template>
    <form>
        <div class="row mt-3 admin-add-property-step-3">
            <div class="col-12">
                <span class="admin-add-property-name">Property Name: 100 Main Street</span>
                <span class="admin-add-property-description">Description</span>
                <span class="admin-add-property-description-text">
                    Please provide a detailed marketing description and investment highlights about the property. For
                    security reason, we reserve the right to remove third-party website links that are deemed harmful to
                    our users. If you're attempting to upload a link that is essential to your listing description or
                    need further assistance, please contact <a href="mailto:support@kibanx.com">support@kibanx.com</a>.
                </span>
                <form class="form">
                    <div class="row">
                        <div class="col-md-5 col-12 from-group mt-4">
                            <label for="description" class="form-label">Description</label>
                            <textarea name="description" id="description" rows="5" class="form-control"></textarea>
                        </div>
                        <div class="offset-md-1 col-md-5 col-12 from-group mt-4">
                            <label for="opportunity" class="form-label">Value Add Opportunity</label>
                            <textarea name="opportunity" id="opportunity" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5 col-12 from-group mt-4">
                            <label for="assumptions" class="form-label">Exit Assumptions</label>
                            <textarea name="assumptions" id="assumptions" rows="5" class="form-control"></textarea>
                        </div>
                        <div class="offset-md-1 col-md-5 col-12 from-group mt-4">
                            <label for="terms" class="form-label">Financing Terms</label>
                            <textarea name="terms" id="terms" rows="5" class="form-control"></textarea>
                        </div>
                    </div>
                </form>
                <div class="col-11 text-right mt-3">
                    <button class="btn btn-secondary mr-3" type="button" @click="this.back()">Back</button>
                    <button class="btn btn-primary" type="button" @click="this.continue()">Continue</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: "step-3",
    emits: ['emitStep'],
    methods: {
        continue: function () {
            this.$emit('emitStep', '4');
        },
        back: function () {
            this.$emit('emitStep', '2');
        }
    }
}
</script>