<template>
    <div class="modal fade" id="examplemodal" tabindex="-1" aria-labelledby="examplemodallabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="examplemodallabel">Add Distribution</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="container">
                            <div class="row form-group mt-3">
                                <div class="col-4 align-self-center">
                                    <label for="distribution_date" class="form-label">Distribution Date</label>
                                </div>
                                <div class="col-8">
                                    <input type="date" id="distribution_date" class="form-control">
                                </div>
                            </div>
                            <div class="row form-group mt-3">
                                <div class="col-4 align-self-center">
                                    <label for="amount" class="form-label">Amount</label>
                                </div>
                                <div class="col-8">
                                    <input type="number" id="amount" class="form-control">
                                </div>
                            </div>
                            <div class="row form-group mt-3">
                                <div class="col-4">
                                    <label for="note" class="form-label">Notes</label>
                                </div>
                                <div class="col-8">
                                    <textarea rows="3" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Save</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DistributionsModal"
}
</script>