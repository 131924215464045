import { createWebHistory, createRouter } from "vue-router";

import LandingPage from "../components/Pages/LandingPage";
import Dashboard from "../components/Pages/Dashboard";
import HomePageOne from "../components/Pages/HomePageOne";
import HomePageTwo from "../components/Pages/HomePageTwo";
import HomePageThree from "../components/Pages/HomePageThree";
import TradePage from "../components/Pages/TradePage";
import TeamPage from "../components/Pages/TeamPage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";
import BuyPage from "../components/Pages/BuyPage";
import CryptocurrencyPage from "../components/Pages/CryptocurrencyPage";
import CryptocurrencyDetailsPage from "../components/Pages/CryptocurrencyDetailsPage";
import SellPage from "../components/Pages/SellPage";
import ListingsPage from "../components/Pages/ListingsPage";
import ListingsDetailsPage from "../components/Pages/ListingsDetailsPage";
import FaqPage from "../components/Pages/FaqPage";
import GuidesPage from "../components/Pages/GuidesPage";
import WalletPage from "../components/Pages/WalletPage";
import AboutPagev from "../components/Pages/AboutPage";
import ContactPage from "../components/Pages/ContactPage";
import AffiliateProgramPage from "../components/Pages/AffiliateProgramPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import LoginPage from "../components/Pages/LoginPage";
import RegisterPage from "../components/Pages/RegisterPage";
import OwnerPage from "../components/Pages/OwnerPage";
import InvestorPage from "../components/Pages/InvestorPage";
import InvestorNoPage from "../components/Pages/InvestorNoPage";
import InvestorYesPage from "../components/Pages/InvestorYesPage";
import TellUsMorePage from "../components/Pages/TellUsMorePage";
import SubmissionPage from "../components/Pages/SubmissionPage";
import LoginRegisterPage from "../components/Pages/LoginRegisterPage";
import Watchlist from "../components/Pages/Watchlist";
import WatchlistDetail from "../components/Pages/WatchlistDetail";
import MyHoldings from "../components/Pages/MyHoldings";
import MyHoldingsDetail from "../components/Pages/MyHoldingsDetail";
import TrackOrder from "../components/Pages/TrackOrder";
import MyProperty from "../components/Pages/MyProperty";
import MyPropertyMap from "../components/Pages/MyPropertyMap";
import MyPropertyDistribution from "../components/Pages/MyPropertyDistribution";
import MyPropertyAdmin from "../components/Pages/MyPropertyAdmin";
import PriceHistory from "../components/Pages/PriceHistory";
import Property from "../components/Pages/Property";
import PropertyTradeOrder from "../components/Pages/PropertyTradeOrder";
import PropertyReporting from "../components/Pages/PropertyReporting";
import PropertyDistribution from "../components/Pages/PropertyDistribution";
import PropertyGallery from "../components/Pages/PropertyGallery";
import PropertyLibrary from "../components/Pages/PropertyLibrary";
import Exchange from "../components/Pages/Exchange";
import ExchangeProperty from "../components/Pages/ExchangeProperty";
import ExchangeDistribution from "../components/Pages/ExchangeDistribution";
import SystemAdmin from "../components/Pages/SystemAdminPage";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      reload: true,
    },
  },
  {
    path: "/dashboard",
    name: "Home",
    component: Dashboard,
    meta: {
      reload: true,
    }
  },
  { path: "/home-one", name: "HomePageOne", component: HomePageOne },
  { path: "/home-two", name: "HomePageTwo", component: HomePageTwo },
  { path: "/home-three", name: "HomePageThree", component: HomePageThree },
  { path: "/trade", name: "TradePage", component: TradePage },
  { path: "/leadership", name: "TeamPage", component: TeamPage },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPage",
    component: TermsConditionsPage
  },
  { path: "/buy", name: "BuyPage", component: BuyPage },
  {
    path: "/cryptocurrency",
    name: "CryptocurrencyPage",
    component: CryptocurrencyPage
  },
  {
    path: "/cryptocurrency-details",
    name: "CryptocurrencyDetailsPage",
    component: CryptocurrencyDetailsPage
  },
  { path: "/sell", name: "SellPage", component: SellPage },
  { path: "/prices", name: "ListingsPage", component: ListingsPage },
  {
    path: "/prices-details",
    name: "ListingsDetailsPage",
    component: ListingsDetailsPage
  },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  { path: "/guides", name: "GuidesPage", component: GuidesPage },
  { path: "/wallet", name: "WalletPage", component: WalletPage },
  { path: "/about", name: "AboutPagev", component: AboutPagev },
  { path: "/contact", name: "ContactPage", component: ContactPage },
  {
    path: "/affiliate-program",
    name: "AffiliateProgramPage",
    component: AffiliateProgramPage
  },
  { path: "/blog", name: "BlogPage", component: BlogPage },
  {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage
  },
  {
    path: "/login-register",
    name: "LoginRegisterPage",
    component: LoginRegisterPage
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: RegisterPage
  },
  {
    path: "/register/property-owner",
    name: "OwnerPage",
    component: OwnerPage
  },
  {
    path: "/register/investor",
    name: "InvestorPage",
    component: InvestorPage
  },
  {
    path: "/register/non-accredited-investor",
    name: "InvestorNoPage",
    component: InvestorNoPage
  },
  {
    path: "/register/accredited-investor",
    name: "InvestorYesPage",
    component: InvestorYesPage
  },
  {
    path: "/register/tell-us-more",
    name: "TellUsMorePage",
    component: TellUsMorePage
  },
  {
    path: "/register/submission-complete",
    name: "SubmissionPage",
    component: SubmissionPage
  },
  {
    path: "/my-properties",
    name: "My Properties",
    component: MyProperty
  },
  {
    path: "/my-properties-map",
    name: "My Property Map",
    component: MyPropertyMap
  },
  {
    path: "/my-properties/distribution",
    name: "My Property Distribution",
    component: MyPropertyDistribution
  },
  {
    path: "/my-properties/admin",
    name: "My Property Admin",
    component: MyPropertyAdmin
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    component: Watchlist
  },
  {
    path: "/watchlist-detail",
    name: "WatchlistDetail",
    component: WatchlistDetail
  },
  {
    path: "/my-holdings",
    name: "My Holdings",
    component: MyHoldings
  },
  {
    path: "/my-holdings-detail",
    name: "MyHoldingsDetail",
    component: MyHoldingsDetail
  },
  {
    path: "/track-order",
    name: "TrackOrder",
    component: TrackOrder
  },
  {
    path: "/my-property/price-history",
    name: "Price History",
    component: PriceHistory
  },
  {
    path: "/my-property/property",
    name: "Property",
    component: Property
  },
  {
    path: "/my-property/trade-and-orders",
    name: "Trade & Orders",
    component: PropertyTradeOrder
  },
  {
    path: "/my-property/reporting",
    name: "Reporting",
    component: PropertyReporting
  },
  {
    path: "/my-property/distributions",
    name: "Distribution",
    component: PropertyDistribution
  },
  {
    path: "/my-property/gallery",
    name: "Property Gallery",
    component: PropertyGallery
  },
  {
    path: "/my-property/library",
    name: "Property Library",
    component: PropertyLibrary
  },
  {
    path: "/exchange",
    name: "Exchange",
    component: Exchange
  },
  {
    path: "/exchange/property",
    name: "Exchange Property",
    component: ExchangeProperty
  },
  {
    path: "/exchange/distributions",
    name: "Exchange Distribution",
    component: ExchangeDistribution
  },
  {
    path: "/system-admin",
    name: "System Admin",
    component: SystemAdmin
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
