<template>
    <div class="container kyc-graphical-detail mt-4">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-12 position-relative">
                <div class="kyc-area-chart-text">
                    <span class="kyc-area-chart-text-heading">Inbound Bandwidth</span>
                    <span class="kyc-area-chart-text-detail">+90<i class='bx bx-up-arrow-alt'></i></span>
                </div>
                <AreaChartVue :areaChartOptions="areaChartOptions" :areaSeries="areaSeries" />
            </div>
            <div class="col-lg-4 col-md-6 col-12">
                <DonutPieChartVue :donutPieChartOptions="donutPieChartOptions" :donutPieSeries="donutPieSeries" />
            </div>
            <div class="col-lg-4 col-md-6 col-12 ">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">Type</th>
                                <th class="text-center p-2">Ammount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-gray-200">
                                <td>Standard KYC</td>
                                <td>1070</td>
                            </tr>
                            <tr class="bg-gray-200">
                                <td>Accedited Investors</td>
                                <td>17</td>
                            </tr>
                            <tr class="bg-gray-200">
                                <td>Legal Entities</td>
                                <td>5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-4">
        <FilterVue />
    </div>
    <div class="container mt-4 mb-20">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">Full Name</th>
                                <th class="text-center p-2">Email</th>
                                <th class="text-center p-2">Creation Date</th>
                                <th class="text-center p-2">Tags</th>
                                <th class="text-center p-2">Status</th>
                                <th class="text-center p-2">Type</th>
                                <th class="text-center p-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRowVue :tableData=tableData rowColor="bg-gray-200" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AreaChartVue from "../../Common/AreaChart.vue";
import DonutPieChartVue from "../../Common/DonutPieChart.vue";
import FilterVue from "./Filter.vue";
import TableRowVue from "./TableRow.vue";

export default ({
    name: "KYC",
    data: () => ({
        tableData: [
            {
                id: 1,
                name: "Dacevi John (KHM)",
                email: "johndacev@gmail.com",
                date: "November 13, 2018 7:18 AM",
                tags: [],
                status: "Pending",
                type: "KYC"
            },
            {
                id: 2,
                name: "Sophia Tem (KHM)",
                email: "ramo@gmail.com",
                date: "November 8, 2018 9:53 AM",
                tags: [],
                status: "Pending",
                type: "Accredited investor"
            },
            {
                id: 3,
                name: "SOENG TONG (IDN)",
                email: "tn@outlook.com",
                date: "November 7, 2018 3:45 AM",
                tags: ['Unsatisfactory photos', 'Invalid ID'],
                status: "Declined",
                type: "Legal entity"
            },
            {
                id: 4,
                name: "Truc Li Trak (VNM)",
                email: "leanhk@gmail.com",
                date: "November 3, 2018 1:47 AM",
                tags: [],
                status: "Approved",
                type: "KYC"
            },
            {
                id: 5,
                name: "Jeff chuk Wu (NGA)",
                email: "chuk.wu@gmail.com",
                date: "November 1, 2018 5:36 AM",
                tags: [],
                status: "Approved",
                type: "KYC"
            },
            {
                id: 6,
                name: "Simon Wu (CHN)",
                email: "liuxia77@gmail.com",
                date: "October 31, 2018 7:31 AM",
                tags: ['Duplicate'],
                status: "Declined",
                type: "KYC"
            },
            {
                id: 7,
                name: "Chong Kim (CHN)",
                email: "chong.kim@gmail.com",
                date: "October 31, 2018 6:05 AM",
                tags: ['Additional documents'],
                status: "Declined",
                type: "Legal entity"
            },
        ],
        areaChartOptions: {
            chart: {
                id: "vuechart-example",
            },
            dataLabels: {
                enabled: false
            },
            color: "#247BA0",
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
                labels: {
                    show: false
                }
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
            yaxis: {
                labels: {
                    show: false
                }
            },
        },
        areaSeries: [
            {
                name: "series-1",
                data: [31, 40, 28, 51, 42, 109, 100],
            },
        ],
        donutPieChartOptions: {
            chart: {
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            labels: ['10% declined', '20% pending', '70% approved'],
        },
        donutPieSeries: [10, 20, 70]
    }),
    components: {
        AreaChartVue,
        DonutPieChartVue,
        FilterVue,
        TableRowVue,
    }
})
</script>