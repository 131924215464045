<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="text-left text-link py-2 px-0">
            <router-link to="/watchlist-detail" class="nav-link">
                {{ tokenName }}
            </router-link>
        </td>
        <td class="text-center py-2 px-0">{{ Math.floor(Math.random() * (100 - 60) + 60) }}</td>
        <td class="text-center py-2 px-0">{{ Math.floor(Math.random() * (12 - 0) + 0) }}</td>
        <td class="text-center py-2 px-0">{{ Math.floor(Math.random() * (12 - 0) + 0) }} %</td>
        <td class="text-center py-2 px-0" v-if="checkMap">USD</td>
        <td class="text-center py-2 px-0" v-if="checkMap">NorthEast</td>
        <td class="text-center py-2 px-0" v-if="checkMap">{{ Math.floor(Math.random() * (3000 - 500) + 500) }}</td>
        <td class="text-center py-2 px-0" v-if="checkMap">NA</td>
        <td class="text-center py-2 px-0" v-if="checkMap">NA</td>
        <td class="text-center py-2 px-0" v-if="checkMap">NA</td>
        <td class="text-center py-2 px-0" v-if="checkMap">{{ Math.floor(Math.random() * (5000000 - 500000) + 500000) }}
        </td>
        <td class="text-center py-2 px-0" v-if="checkMap">{{ Math.floor(Math.random() * (3000000 - 100000) + 100000) }}
        </td>
        <td class="text-center py-2 px-0"><button type="button" class="btn btn-watchlist-action">Buy</button></td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName', 'checkMap'],
    created() {
    }
}
</script>