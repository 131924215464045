<template>
    <tr v-bind:class="rowColor" class="mt-8" v-for="(row, ind) in tableData" :key="ind">
        <td class="text-left text-link py-2 px-0">
            <router-link to="/watchlist-detail" class="nav-link">
                {{ row.name }}
            </router-link>
        </td>
        <td class="text-left py-2 px-0">
            {{ row.email }}
        </td>
        <td class="text-center py-2 px-0">
            {{ row.date }}
        </td>
        <td class="text-center py-2 px-0">
            <span class="badge bg-info text-dark fw-semibold" v-for="(tag, i) in row.tags" :key="i">{{ tag }}</span>
        </td>
        <td class="text-center py-2 px-0">
            <span class="badge fw-semibold"
                :class="(row.status == 'Pending')? 'bg-warning text-dark': ((row.status == 'Declined')? 'bg-danger text-light': 'bg-success text-light')">{{
                row.status }}</span>
        </td>
        <td class="text-center py-2 px-0">
            {{ row.type }}
        </td>
        <td class="text-center py-2 px-0">
            <span class="text-pointer text-danger fa-2x"><i class="bx bx-x"></i></span>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tableData']
}
</script>