<template>
    <div>
        <apexchart width="100%" type="line" stacked=false :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            chartOptions: {
                chart: {
                    id: "vuechart-example",
                    height: 900,
                },
                datalabels: {
                    enabled: false
                },
                color: "#247BA0",
                xaxis: {
                    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
                },
            },
            series: [
                {
                    name: "100 Main Street",
                    data: [28, 35, 55, 30, 29, 27, 30],
                },
                {
                    name: "KIBANX RE Index",
                    data: [33, 35, 40, 34, 33, 30, 30],
                },
                {
                    name: "DJIA",
                    data: [40, 25, 30, 17, 18, 19, 19],
                },

            ],
        };
    },
}
</script>