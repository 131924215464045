<template>
    <div class="container watchlist-block">
        <div class="watchlist-block-row-col flex justify-between">
            <div class="watchlist-main-menu">
                <a class="d-inline-block text-blue-700 text-base font-bold hover:text-black mr-5 mb-3" href="#"
                    :class="{ 'active': currentTab == 'Summary' }" @click="changeTab('Summary')">Summary</a>
                <a class="d-inline-block text-blue-700 text-base font-bold hover:text-black mr-5 mb-3" href="#"
                    :class="{ 'active': currentTab == 'Fundamentals' }"
                    @click="changeTab('Fundamentals')">Fundamentals</a>
                <a class="d-inline-block text-blue-700 text-base font-bold hover:text-black mr-5 mb-3" href="#"
                    :class="{ 'active': currentTab == 'Performance' }" @click="changeTab('Performance')">Performance</a>
                <a class="d-inline-block text-blue-700 text-base font-bold hover:text-black mr-5 mb-3" href="#"
                    :class="{ 'active': currentTab == 'Characteristics' }"
                    @click="changeTab('Characteristics')">Characteristics</a>
                <a class="d-inline-block text-blue-700 text-base font-bold hover:text-black mr-5 mb-3" href="#"
                    :class="{ 'active': currentTab == 'Risk Analysis' }" @click="changeTab('Risk Analysis')">Risk
                    Analysis</a>
            </div>
            <div class="watchlist-main-options d-flex align-items-center justify-content-end">
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Share</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Create Portfolio</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Print</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Import</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Export</a>
                <a class="text-blue-700 text-base font-bold hover:text-black mx-2.5 mb-3" href="#">Settings</a>
            </div>
        </div>
    </div>
    <Summary v-if="currentTab == 'Summary'" />
    <Fundamentals v-if="currentTab == 'Fundamentals'" />
    <Performance v-if="currentTab == 'Performance'" />
    <Characteristics v-if="currentTab == 'Characteristics'" />
    <RiskAnalysis v-if="currentTab == 'Risk Analysis'" />
</template>

<script>
import Summary from './Summary/Table.vue';
import Fundamentals from './Fundamentals/Table.vue';
import Performance from './Performance/Table.vue';
import Characteristics from './Characteristics/Table.vue';
import RiskAnalysis from './RiskAnalysis/Table.vue';

export default {
    name: 'Main',
    data() {
        return {
            currentTab: 'Summary',
        }
    },
    components: {
        Summary,
        Fundamentals,
        Performance,
        Characteristics,
        RiskAnalysis,
    },
    methods: {
        changeTab: function (val) {
            this.currentTab = val;
        }
    }
}
</script>