<template>
    <div class="about-area pb-100">
        <div class="container">
            <div class="about-content">
                <div class="content">
                    <h1>Not Going Anywhere, We Are Here!</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                </div>
            </div>
            <div class="history-timeline timeline">
                <div class="section-title">
                    <h2>Our History</h2>
                </div>
                <div class="timeline-event">
                    <div class="timeline-event-icon">
                        <i class='bx bx-coin-stack'></i>
                    </div>
                    <div class="timeline-event-date bg1">
                        <span>JAN 2007</span>
                    </div>
                    <div class="timeline-event-content">
                        <h3>Temo is Born</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                    </div>
                </div>
                <div class="timeline-event">
                    <div class="timeline-event-icon">
                        <i class='bx bx-sun'></i>
                    </div>
                    <div class="timeline-event-date bg2">
                        <span>MAY 2009</span>
                    </div>
                    <div class="timeline-event-content">
                        <h3>The Rise of Temo</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                    </div>
                </div>
                <div class="timeline-event">
                    <div class="timeline-event-icon">
                        <i class='bx bxl-apple'></i>
                    </div>
                    <div class="timeline-event-date bg3">
                        <span>APR 2012</span>
                    </div>
                    <div class="timeline-event-content">
                        <h3>The Temo Mobile App</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                    </div>
                </div>
                <div class="timeline-event">
                    <div class="timeline-event-icon">
                        <i class='bx bxl-bitcoin'></i>
                    </div>
                    <div class="timeline-event-date bg4">
                        <span>FEB 2017</span>
                    </div>
                    <div class="timeline-event-content">
                        <h3>Adding Cryptocurrencies</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                    </div>
                </div>
                <div class="timeline-event">
                    <div class="timeline-event-icon">
                        <i class='bx bxs-pie-chart-alt'></i>
                    </div>
                    <div class="timeline-event-date bg5">
                        <span>MAR 2020</span>
                    </div>
                    <div class="timeline-event-content">
                        <h3>Trading Platform</h3>
                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Vel, nam! Nam eveniet ut aliquam ab asperiores, accusamus iure veniam corporis incidunt reprehenderit accusantium id aut architecto harum quidem dolorem in!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>