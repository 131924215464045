<template>
    <div class="report-modal-sub-tabs">
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Types' }" @click="swapSubTabs('Types')">
            <i class='bx bx-columns'></i> Cell Types
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Formatting' }" @click="swapSubTabs('Formatting')">
            <i class='bx bx-filter-alt'></i> Conditional Formatting
        </div>
    </div>
    <div>
        <TypesTab v-if="subTab == 'Types'" />
        <FormattingTab v-if="subTab == 'Formatting'" />
    </div>
</template>

<script>
import TypesTab from './Cells/Types'
import FormattingTab from './Cells/Formatting'

export default {
    name: 'CellsMain',
    data() {
        return {
            subTab: "Types",
        }
    },
    components: {
        TypesTab,
        FormattingTab,
    },
    methods: {
        swapSubTabs: function (tab) {
            this.subTab = tab;
        }
    }
}
</script>