<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <MyPropertiesBreadcrumb selected_option="Admin" />
        <Admin />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import MyPropertiesBreadcrumb from '../MyProperties/Breadcrumb'
import Admin from "../MyProperties/Admin/Admin"
import Footer from '../Layouts/Footer'
export default {
    name: 'MyPropertyAdmin',
    components: {
        Navbar,
        Breadcrumb,
        MyPropertiesBreadcrumb,
        Admin,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', 'Summary']
        }
    }
}
</script>