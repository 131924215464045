<template>
    <tr v-bind:class="rowColor">
        <td class="text-left">{{ names() }}</td>
        <td class="text-left">{{ desc() }}</td>
        <td class="text-center">{{ tables() }}</td>
        <td class="text-center">Admin</td>
        <td class="text-center">
            <span class="text-pointer report-table-icons"><i class='bx bx-edit-alt'></i></span>
            <span class="text-pointer report-table-icons text-danger"><i class='bx bx-x'></i></span>
            <span class="text-pointer report-table-icons"><i class='bx bx-cloud-download'></i></span>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor'],
    methods: {
        names: function () {
            const name = ["Test", "Search", "My Report"];
            const random = Math.floor(Math.random() * name.length);
            return name[random];
        },
        tables: function () {
            const table_names = ["Properties", "Tokens", "Investors"];
            const random = Math.floor(Math.random() * table_names.length);
            return table_names[random];
        },
        desc: function () {
            const table_names = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at...", "Pellentesque magna nisi, blandit vel euismod et, vestibulum in eros...", "Proin convallis orci mi, in fermentum est ultricies non. Nullam quis..."];
            const random = Math.floor(Math.random() * table_names.length);
            return table_names[random];
        }
    }
}
</script>