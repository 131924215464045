<template>
    <div class="earn-money-area pt-100 pb-70 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2>Earn Money on KIBANX</h2>
                <p>
                    For each "Qualifying New Account on KIBANX" (as defined below) KIBANX will credit you with a certain
                    amount of shares of a KIBANX-sponsored public fund (the “Share Bonus”) according to the chart below,
                    as determined by KIBANX. The Share Bonus will be credited to you within approximately one week after
                    all conditions are satisfied.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EarnMoney'
}
</script>