<template>
    <tr v-bind:class="rowColor">
        <td class="text-left">{{ filename }}</td>
        <td>{{ desc() }}</td>
        <td class="text-center p-0">
            <span class="text-pointer report-table-icons" title="Download Excel">
                <i class='bx bx-spreadsheet'></i>
            </span>
            <span class="text-pointer report-table-icons" title="Download PDF">
                <i class='bx bxs-file-pdf'></i>
            </span>
            <span class="text-pointer report-table-icons" title="Download CSV">
                <i class='bx bxs-spreadsheet'></i>
            </span>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'filename'],
    methods: {
        desc: function () {
            const table_names = ["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at commodo ante, sed malesuada metus. Sed semper...", "Pellentesque magna nisi, blandit vel euismod et, vestibulum in eros. Quisque mollis elit ac purus suscipit, in...", "Proin convallis orci mi, in fermentum est ultricies non. Nullam quis justo consequat, dictum velit eget, blandit risus..."];
            const random = Math.floor(Math.random() * table_names.length);
            return table_names[random];
        }
    }
}
</script>