<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="row m-0">
                <div class="col-xl-6 col-lg-6 col-md-12 p-0">
                    <div class="main-banner-content ml-8">
                        <h1>Coming Soon: Tokenize, Trade & Invest Real Estate Easy, Fast </h1>
                        <p>
                            Invest in institutional grade property or fractionalize your real estate holdings with
                            KIBANX while taking advantage of our end-to-end digital exchange bringing issuers and
                            investors together on a single state of the art platform.
                        </p>
                        <router-link to="/" class="default-btn">
                            <i class='bx bxs-user'></i>
                            Register For Alerts Now.
                        </router-link>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 p-0">
                    <div class="main-banner-image">
                        <img src="../../assets/img/banner/header_b.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="../../assets/img/shape/shape1.png" alt="image"></div>
        <div class="shape2"><img src="../../assets/img/shape/shape2.png" alt="image"></div>
        <div class="shape3"><img src="../../assets/img/shape/shape3.png" alt="image"></div>
        <div class="shape5"><img src="../../assets/img/shape/shape5.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner',
}
</script>