<template>
    <div class="container">
        <div class="sub-breadcrumbs my-4">
            <router-link to="/my-properties" class="property-link"
                :class="{ 'selected_option': selected_option == 'Properties' }">
                Properties
            </router-link>
            <router-link to="" class="property-link" :class="{ 'selected_option': selected_option == 'Transactions' }">
                Transactions
            </router-link>
            <router-link to="/my-properties/distribution" class="property-link"
                :class="{ 'selected_option': selected_option == 'Distributions' }">
                Distributions
            </router-link>
            <router-link to="/my-properties/admin" class="property-link"
                :class="{ 'selected_option': selected_option == 'Admin' }">
                Admin
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: ['selected_option'],
}
</script>