<template>
    <div class="container my-3">
        <div class="row form-group align-items-center">
            <div class="col-12">
                <div class="cryptocurrency-data-table report-modal-cells-type table-responsive">
                    <table class="main-table">
                        <thead>
                            <tr>
                                <th class="text-center w-25">Columns</th>
                                <th class="text-center w-75">Cell Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_0">no_of_ac</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><select
                                            class="input-as-tf" style="width: 58% !important;"
                                            name="slctdcltype_0-no_of_ac" id="slctdcltype_0"
                                            onchange="cellType(this.value,0)">
                                            <option value="value">Standard Cell</option>
                                            <option value="image">Image Cell</option>
                                            <option value="stars">Rating Stars Cell</option>
                                            <option value="link">link cell</option>
                                            <option value="bit">True Or False Cell</option>
                                            <option value="country">Country Flag Cell</option>
                                            <option value="append-r">Append a text</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_1">payment_approval_status</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><select
                                            class="input-as-tf" style="width: 58% !important;"
                                            name="slctdcltype_1-payment_approval_status" id="slctdcltype_1"
                                            onchange="cellType(this.value,1)">
                                            <option value="value">Standard Cell</option>
                                            <option value="image">Image Cell</option>
                                            <option value="stars">Rating Stars Cell</option>
                                            <option value="link">link cell</option>
                                            <option value="bit">True Or False Cell</option>
                                            <option value="country">Country Flag Cell</option>
                                            <option value="append-r">Append a text</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_2">last_payment_approval_log_id</label>
                                </td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><select
                                            class="input-as-tf" style="width: 58% !important;"
                                            name="slctdcltype_2-last_payment_approval_log_id" id="slctdcltype_2"
                                            onchange="cellType(this.value,2)">
                                            <option value="value">Standard Cell</option>
                                            <option value="image">Image Cell</option>
                                            <option value="stars">Rating Stars Cell</option>
                                            <option value="link">link cell</option>
                                            <option value="bit">True Or False Cell</option>
                                            <option value="country">Country Flag Cell</option>
                                            <option value="append-r">Append a text</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_3">paidToDate</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><select
                                            class="input-as-tf" style="width: 58% !important;"
                                            name="slctdcltype_3-paidToDate" id="slctdcltype_3"
                                            onchange="cellType(this.value,3)">
                                            <option value="value">Standard Cell</option>
                                            <option value="image">Image Cell</option>
                                            <option value="stars">Rating Stars Cell</option>
                                            <option value="link">link cell</option>
                                            <option value="bit">True Or False Cell</option>
                                            <option value="country">Country Flag Cell</option>
                                            <option value="append-r">Append a text</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 50%;"><label for="inputlbl_4">payment_date</label></td>
                                <td style="width: 50%;">
                                    <div class="left-inner-addon"><i class='bx bx-edit-alt'></i><select
                                            class="input-as-tf" style="width: 58% !important;"
                                            name="slctdcltype_4-payment_date" id="slctdcltype_4"
                                            onchange="cellType(this.value,4)">
                                            <option value="value">Standard Cell</option>
                                            <option value="image">Image Cell</option>
                                            <option value="stars">Rating Stars Cell</option>
                                            <option value="link">link cell</option>
                                            <option value="bit">True Or False Cell</option>
                                            <option value="country">Country Flag Cell</option>
                                            <option value="append-r">Append a text</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Types',
}
</script>