<template>
    <form>
        <div class="row mt-3 admin-add-property-step-3">
            <div class="col-12">
                <span class="admin-add-property-name">Property Name: 100 Main Street</span>
                <span class="admin-add-property-description">Photos & Videos</span>
                <span class="admin-add-property-description-text">
                    Click below to upload photos and videos. Media will be displayed in the order it appears.
                </span>
                <div class="row">
                    <div class="col-md-11 col-12 row">
                        <div class="col">
                            <div class="add-photo-block">
                                <div class="add-photo-icon">
                                    <i class='bx bx-image'></i>
                                </div>
                                <div class="add-photo-detail-block">
                                    <span class="add-photo-heading">Add Photos</span>
                                    <span class="add-photo-detail">Click or drag and drop to add files</span>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="add-video-block">
                                <div class="add-video-icon">
                                    <i class='bx bx-video'></i>
                                </div>
                                <div class="add-video-detail-block">
                                    <span class="add-video-heading">Add Videos</span>
                                    <span class="add-video-detail">Click to upload or to use embed code</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-11 text-right mt-3">
                    <button class="btn btn-secondary mr-3" type="button" @click="this.back()">Back</button>
                    <button class="btn btn-primary" type="button" @click="this.continue()">Continue</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>

export default {
    name: "step-4",
    emits: ['emitStep'],
    methods: {
        continue: function () {
            this.$emit('emitStep', '5');
        },
        back: function () {
            this.$emit('emitStep', '3');
        }
    }
}
</script>