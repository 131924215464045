<template>
    <tr v-bind:class="rowColor" class="mt-8">
        <td class="text-left text-link py-2 px-0">
            <router-link to="/watchlist-detail" class="nav-link" style="width: 125px; padding: 10px;">
                {{ tokenName }}
            </router-link>
        </td>
        <td class="text-center py-2 px-0">New York City</td>
        <td class="text-center py-2 px-0">Manhattan</td>
        <td class="text-center py-2 px-0">Commercial</td>
        <td class="text-center py-2 px-0">Retail</td>
        <td class="text-center py-2 px-0">20,000</td>
        <td class="text-center py-2 px-0">21,000</td>
        <td class="text-center py-2 px-0">79.3%</td>
        <td class="text-center py-2 px-0">1990</td>
        <td class="text-center py-2 px-0">2,731</td>
        <td class="text-center py-2 px-0">R</td>
        <td class="text-center py-2 px-0">Yes</td>
        <td class="text-center py-2 px-0">
            <div style="width: 130px;">
                <button type="button" class="btn btn-watchlist-action">Buy</button>
                <button type="button" class="btn btn-watchlist-danger ml-1">Sell</button>
            </div>
        </td>
    </tr>
</template>
<script >
export default {
    props: ['rowColor', 'tokenName'],
}
</script>