<template>
    <div>
        <apexchart width="100%" type="area" :options="areaChartOptions" :series="areaSeries">
        </apexchart>
    </div>
</template>

<script>
export default {
    name: 'Area Apex Chart',
    props: ['areaChartOptions', 'areaSeries'],
}
</script>