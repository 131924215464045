<template>
    <div class="profile-authentication-area login">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="login-form">
                                <h2>Login</h2>
                                <form @submit="SubmitForm">
                                    <div class="form-group">
                                        <input type="text" id="input-login-email" class="form-control"
                                            placeholder="Username or email">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" id="input-login-password" class="form-control"
                                            placeholder="Password">
                                    </div>
                                    <p class="text-red-600">
                                        {{ this.errorMessage ? `Something went wrong, Please try again` : "" }}
                                    </p>
                                    <div class="row align-items-center">
                                        <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="rememberMe">
                                                <label class="form-check-label" for="rememberMe">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                            <a href="#" class="lost-your-password">Lost your password?</a>
                                        </div>
                                    </div>
                                    <button type="submit">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LoginNav />
    </div>
</template>

<script>
import router from '../../router'
import LoginNav from '../Layouts/LoginNav'

export default {
    name: 'Login',
    data() {
        var errorMessage = false
        return {
            errorMessage
        }

    },
    components: {
        LoginNav
    },
    methods: {
        SubmitForm(e) {
            e.preventDefault()
            this.errorMessage = true
            const theEmail = document.getElementById('input-login-email').value
            const thePassword = document.getElementById('input-login-password').value
            if (theEmail === "admin@admin.com" && thePassword === "12345678") {
                router.push({ name: "Home" })
            }

        }
    }
}
</script>