<template>
    <div class="container my-property-filters">
        <div class="row">
            <div class="col-lg-2 col-md-3 col-6">
                <select name="properties" id="properties" class="form-select mr-2">
                    <option value="">Business Area</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-3 col-6 btn-search-block">
                <input type="text" id="search" class="form-control" placeholder="Search here">
                <button type="button" class="btn btn-search"><i class='bx bx-search'></i></button>
            </div>
            <div class="col-lg-8 col-md-6 col-12 flex align-items-center justify-content-end">
                <button class="btn btn-main">Add</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filter',
}
</script>