<template>
    <div class="container">
        <div class="flex flex-row justify-between align-items-center mt-2">
            <button type="button" class="bg-blue-500 rounded-50 w-28 h-11 text-white">
                <strong class="text-xl mr-1">+</strong> Add Token
            </button>
            <div class="flex flex-row align-items-center">
                <span class="flex flex-row ml-8">
                    <span class="text-gray-500 mr-2.5">
                        Show Map
                    </span>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            v-model="checked" @change="showMap()">
                    </div>
                </span>
            </div>
        </div>
    </div>
    <div class="container mt-4 mb-20">
        <div class="row">
            <div class="col-12" :class="{ 'col-md-12':show_Map, 'col-md-6':!show_Map}">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">Name</th>
                                <th class="text-center p-2">Last Price</th>
                                <th class="text-center p-2">Change</th>
                                <th class="text-center p-2">Chg %</th>
                                <th class="text-center p-2" v-if="show_Map">Currency</th>
                                <th class="text-center p-2" v-if="show_Map">Region</th>
                                <th class="text-center p-2" v-if="show_Map">Avg Vol(3m)</th>
                                <th class="text-center p-2" v-if="show_Map">Day Range</th>
                                <th class="text-center p-2" v-if="show_Map">52-Wk Range</th>
                                <th class="text-center p-2" v-if="show_Map">Day Chart</th>
                                <th class="text-center p-2" v-if="show_Map">Tokens Issued</th>
                                <th class="text-center p-2" v-if="show_Map">Market Cap</th>
                                <th class="text-center p-2">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRowVue tokenName="A&E TOKEN 1" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 2" :checkMap="show_Map" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 3" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 4" :checkMap="show_Map" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 5" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 6" :checkMap="show_Map" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 7" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 1" :checkMap="show_Map" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 2" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 3" :checkMap="show_Map" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 4" :checkMap="show_Map" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone5" :checkMap="show_Map" rowColor="bg-white" />
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-6 col-12" v-if="!show_Map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12098.40003046868!2d-74.0072812!3d40.7048062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70c11c6c3c128d73!2sDeal%20Tracking%20Solutions%2C%20Inc.!5e0!3m2!1sen!2s!4v1660665348367!5m2!1sen!2s"
                    class="investment-detail-location-map" height="700px" style="border:0;" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import TableRowVue from './TableRow';

export default {
    name: 'Table',
    data() {
        return {
            show_Map: true,
            checked: false
        }
    },
    components: {
        TableRowVue
    },
    methods: {
        showMap: function () {
            return this.show_Map = (this.show_Map) ? false : true;
        }
    }
}
</script>