<template>
    <div class="cta-area bg-gradient-image pt-100">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="cta-content black-text">
                        <h2>Start Trading on Temo</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                        <router-link to="/login-register" class="default-btn">
                            <i class='bx bxs-user'></i> 
                            Register Now
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="cta-image">
                        <img src="../../assets/img/man-with-ipad.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="shape6"><img src="../../assets/img/shape/shape6.png" alt="image"></div>
        <div class="shape7"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape8.png" alt="image"></div>
        <div class="shape9"><img src="../../assets/img/shape/shape9.png" alt="image"></div>
        <div class="shape15"><img src="../../assets/img/shape/shape15.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'StartTradingTwo'
}
</script>