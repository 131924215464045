<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li class="tabs__header-item" v-for="tab in tabs" v-on:click="selectTab (tab.id)"
                            v-bind:class="{ 'active': activeTab == tab.id }" :key="tab.id">
                            <i :class="tab.icon"></i>
                            {{tab.name}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div class="tabs__list" ref='tabsList'>
                        <div class="tabs__list-tab" v-for="tab in tabs" v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id">
                            <AccordionContant v-if="activeTab == 1" />
                            <AccordionContant2 v-if="activeTab == 2" />
                            <AccordionContant3 v-if="activeTab == 3" />
                            <AccordionContant4 v-if="activeTab == 4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionContant from '../Faq/AccordionContant'
import AccordionContant2 from '../Faq/AccordionContant2'
import AccordionContant3 from '../Faq/AccordionContant3'
import AccordionContant4 from '../Faq/AccordionContant4'

export default {
    name: 'Faq',
    components: {
        AccordionContant,
        AccordionContant2,
        AccordionContant3,
        AccordionContant4,
    },
    data() {
        return {
            activeTab: 1,
            offsetRight: 0,

            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-flag',
                    name: 'General',
                    content: 'Compnay dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam'
                },
                {
                    id: 2,
                    icon: 'bx bx-info-circle',
                    name: 'Investing on KIBANX',
                    content: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less'
                },
                {
                    id: 3,
                    icon: 'bx bxs-badge-dollar',
                    name: 'Secondary Market',
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look'
                },
                {
                    id: 4,
                    icon: 'bx bx-book-open',
                    name: 'KDOLLARS',
                    content: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look'
                }
            ]
        }
    },
    methods: {
        selectTab(id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted() {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>