<template>
    <div class="cryptocurrency-area pb-70">
        <div class="container">
            <div class="row justify-content-center">
                <!-- Block 1 -->
                <div class="col-lg-4 col-md-6 col-sm-6 d-flex align-items-stretch" v-for="(card, ci) in cards"
                    :key="ci">
                    <div class="single-cryptocurrency-item my-property-card d-flex flex-column cursor-pointer">
                        <div class="row" @click="MyProperty">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3 class="text-blue-700">{{ card.heading }}</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking Spaces</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" :src="card.img" alt="Card image">
                            </div>
                        </div>
                        <div class="row exhange-main-card-row mt-auto">
                            <div class="col-12" @click="MyProperty">
                                <hr class="hr">
                            </div>
                            <div class="col-6 row" @click="MyProperty">
                                <div class="col-6 sub-title-heading">Symbol:</div>
                                <div class="col-6 sub-title-detail p-0">AET01</div>
                            </div>
                            <div class="col-6 row" @click="MyProperty">
                                <div class="col-6 sub-title-heading">Available:</div>
                                <div class="col-6 sub-title-detail text-right p-0">10,000</div>
                            </div>
                            <div class="col-6 row" @click="MyProperty">
                                <div class="col-6 sub-title-heading">Offered By:</div>
                                <div class="col-6 sub-title-detail p-0">DBA Holdings</div>
                            </div>
                            <div class="col-6 row" @click="MyProperty">
                                <div class="col-6 sub-title-heading">Price:</div>
                                <div class="col-6 sub-title-detail text-right p-0">
                                    25.71
                                    (<span class="text-default">+2.34%</span>)
                                </div>
                            </div>
                            <div class="col-12 text-right mt-2">
                                <button type="button" class="btn btn-default" data-bs-toggle="modal"
                                    data-bs-target="#buySellModal" @click="BuySell(card.id)">Buy</button>
                                <button type="button" class="btn btn-outline-default" data-bs-toggle="modal"
                                    data-bs-target="#buySellModal" @click="BuySell(card.id)">Sell</button>
                                <button type="button" class="btn btn-main" @click="MyProperty">Info</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BuySellModalVue :property=selected_prop :success=success @emitSuccess=orderSubmit />
</template>

<script>
import router from '../../router';
import BuySellModalVue from './BuySellModal.vue';

export default {
    name: 'Card',
    components: {
        BuySellModalVue,
    },
    data() {
        return {
            selected_prop: [],
            success: false,
            cards: [
                {
                    id: 1,
                    heading: '45 Wilshire Blvd',
                    img: require('../../assets/img/investment_detail/1.png')
                },
                {
                    id: 2,
                    heading: '212 Canal St',
                    img: require('../../assets/img/investment_detail/2.jpg')
                },
                {
                    id: 3,
                    heading: '306 Fifth Ave',
                    img: require('../../assets/img/investment_detail/3.jpg')
                },
                {
                    id: 4,
                    heading: '785 Park Ave',
                    img: require('../../assets/img/investment_detail/4.jpg')
                },
                {
                    id: 5,
                    heading: '633 Third Ave',
                    img: require('../../assets/img/investment_detail/5.jpg')
                },
                {
                    id: 6,
                    heading: '866 UN Plaza',
                    img: require('../../assets/img/investment_detail/6.jpg')
                },
            ]
        }
    },
    methods: {
        MyProperty() {
            router.push({ name: "Exchange Property" })
        },
        BuySell(val) {
            this.success = false;
            this.selected_prop = this.cards[val - 1];
        },
        orderSubmit: function (val) {
            this.success = val;
        }
    }
}

</script>