
<template>
    <div class="register-navbar navbar-area is-sticky">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <router-link class="navbar-brand" to="/">
                    <img src="../../assets/img/kibanxLogo.png" alt="logo">
                </router-link>
                <div class="others-option">
                    <div class="d-flex align-items-center">
                        <a href="/" class="back-icon">
                            <i class='bx bx-x'></i>
                        </a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
export default {
    name: 'LoginNav'
}

</script>