<template>
    <tr v-bind:class="rowColor" class="mt-8 Price-Table">
        <td class="text-center p-1.5">45 Wilshire</td>
        <td class="text-center p-1.5">25,000,000</td>
        <td class="text-center p-1.5">1,000</td>
        <td class="text-center p-1.5">{{ statusType() }}</td>
        <td class="text-center p-1.5">99%</td>
        <td class="text-center p-1.5">235</td>
        <td class="text-center p-1.5">125</td>
        <td class="text-center p-1.5">25</td>
        <td class="text-center p-1.5">
            <span class="text-pointer" title="Dashboard"><i class='bx bxs-dashboard'></i></span>
            <span class="text-pointer" title="Edit"><i class='bx bx-edit'></i></span>
        </td>
    </tr>
</template>
<script >
export default {
    name: "TableRow",
    methods: {
        statusType: function () {
            const months = ["Active", "Deactive"];
            const random = Math.floor(Math.random() * months.length);
            return months[random];
        },
    }
}
</script>