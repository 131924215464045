<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <Filter />
        <Card />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import Filter from '../Exchange/Filter'
import Card from '../Exchange/Card'
import Footer from '../Layouts/Footer'


export default {
    name: 'Exchange',
    components: {
        Navbar,
        Breadcrumb,
        Filter,
        Card,
        Footer,
    },
    data() {
        return {
            crumbs: ['Exchange']
        }
    }
}
</script>