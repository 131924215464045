<template>
    <div>
        <Register />
    </div>
</template>

<script>
import Register from '../LoginRegister/Register'

export default {
    name: 'RegisterPage',
    components: {
        Register
    }
}
</script>