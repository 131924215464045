<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <PropertyBreadCrumbs selected_option="Library" />
        <Filter />
        <Table />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import PropertyBreadCrumbs from '../Property/Breadcrumb'
import Filter from '../Property/Library/Filter'
import Table from "../Property/Library/Table"
import Footer from '../Layouts/Footer'
export default {
    name: 'PropertyLibrary',
    components: {
        Navbar,
        Breadcrumb,
        PropertyBreadCrumbs,
        Filter,
        Table,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', '45 Wilshire Blvd']
        }
    }
}
</script>