<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <Main />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import Main from "../SystemAdmin/Main"
import Footer from '../Layouts/Footer'


export default {
    name: 'SystemAdminPage',
    components: {
        Navbar,
        Breadcrumb,
        Main,
        Footer,
    },
    data() {
        return {
            crumbs: ['System Admin']
        }
    }
}
</script>