<template>
    <div class="report-modal-sub-tabs">
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Columns' }" @click="swapSubTabs('Columns')">
            <i class='bx bx-columns'></i> Columns
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Labels' }" @click="swapSubTabs('Labels')">
            <i class='bx bx-edit-alt'></i> Labels
        </div>
        <div class="sub-tab-item" :class="{ 'active': subTab == 'Aggregation Functions' }"
            @click="swapSubTabs('Aggregation Functions')">
            <i class='bx bx-bar-chart-alt-2'></i> Aggregation Functions
        </div>
    </div>
    <div>
        <ColumnsTab v-if="subTab == 'Columns'" />
        <LabelsTab v-if="subTab == 'Labels'" />
        <FunctionsTab v-if="subTab == 'Aggregation Functions'" />
    </div>
</template>

<script>
import ColumnsTab from './Columns/Columns'
import LabelsTab from './Columns/Labels'
import FunctionsTab from './Columns/Functions'

export default {
    name: 'ColumnsMain',
    data() {
        return {
            subTab: "Columns",
        }
    },
    components: {
        ColumnsTab,
        LabelsTab,
        FunctionsTab,
    },
    methods: {
        swapSubTabs: function (tab) {
            this.subTab = tab;
        }
    }
}
</script>