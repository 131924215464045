<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12 mb-5">
                <PriceChart />
            </div>
            <div class="col-md-6 col-12 mb-5">
                <div class="cryptocurrency-data-table table-responsive max-w-xl">
                    <table class="table">
                        <thead>
                            <tr>

                                <th class="text-center p-2">Date</th>
                                <th class="text-center p-2">Last</th>
                                <th class="text-center p-2">Change</th>
                                <th class="text-center p-2">Open</th>
                                <th class="text-center p-2">High</th>
                                <th class="text-center p-2">Low</th>
                                <th class="text-center p-2">Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                            <PriceTable />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PriceChart from './PriceChart';
import PriceTable from "./PriceTable";
export default {
    name: "PriceHistory",
    components: {
        PriceChart,
        PriceTable,
    }
}
</script>