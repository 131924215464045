<template>
    <div class="modal fade" id="buySellModal" tabindex="-1" aria-labelledby="buySellModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="buySellModalLabel">Place Order</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container">
                        <div class="row" v-if="!success">
                            <div class="col-12 property-heading">
                                <span class="property-heading-name text-main">{{ property.heading }}</span>
                            </div>
                            <div class="col-7">
                                <span class="property-detail-heading">Total Tokens Issued:</span>
                            </div>
                            <div class="col-5">
                                <span class="property-detail-detail">50,000</span>
                            </div>
                            <div class="col-7">
                                <span class="property-detail-heading">Asking Price:</span>
                            </div>
                            <div class="col-5">
                                <span class="property-detail-detail">$100,000</span>
                            </div>
                            <div class="col-7">
                                <span class="property-detail-heading">Total Tokens For Sale:</span>
                            </div>
                            <div class="col-5">
                                <span class="property-detail-detail">6,000</span>
                            </div>
                            <div class="col-7">
                                <span class="property-detail-heading">Currently Available:</span>
                            </div>
                            <div class="col-5">
                                <span class="property-detail-detail">6,000</span>
                            </div>
                        </div>
                        <div class="row form-group mt-4" v-if="!success">
                            <label for="quantity" class="form-label">Order Quantity:</label>
                            <input type="number" class="form-control" name="quantity">
                            <button type="button" class="btn btn-block btn-main" @click="order(true)">
                                Place Order
                            </button>
                        </div>
                        <div class="row my-5" v-if="success">
                            <div class="col-12 text-center">
                                <h5 class="property-modal-order-status text-success">
                                    Order submitted successfully!
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BuySellModal",
    props: ['property', 'success'],
    emits: ['emitSuccess'],
    methods: {
        order: function (val) {
            this.$emit('emitSuccess', val);
        }
    }
}
</script>