<template>
    <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <router-link class="navbar-brand" to="/dashboard">
                    <img src="../../assets/img/kibanxLogo.png" alt="logo">
                </router-link>
                <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    v-bind:class="{ 'active': button_active_state }"
                    v-on:click="button_active_state = !button_active_state">
                    <i class="fas fa-bars"></i>
                    <i class="fas fa-times"></i>
                </div>
                <div class="flex flex-row other-nav-menu only-sm">
                    <div class="flex flex-row align-items-center user-help">
                        <a href="#">
                            <span class="flex align-items-center">
                                <i class='bx bx-message-square-minus'></i>
                            </span>
                        </a>
                        <span class="mr-1 ml-1">
                            |
                        </span>
                    </div>
                    <div class="dropdown user-dropdown">
                        <button class='anchor md-anchor' @mouseenter="showMenu = true" @mouseleave="showMenu = false">
                            <i class='bx bxs-user-circle'></i>
                        </button>
                        <div class='menu' :class="{ 'show': showMenu }" @mouseenter="showMenu = true"
                            @mouseleave="showMenu = false">
                            <div class='menu-item' @click='itemClicked("System Admin")'>
                                <div>
                                    <i class='bx bx-shield-alt'></i>
                                    System Admin
                                </div>
                            </div>
                            <div class='menu-item' @click='itemClicked("LandingPage")'>
                                <div>
                                    <i class='bx bx-info-circle'></i>
                                    Sign Out
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="collapse navbar-collapse" :class="{ toggler: active }">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link">
                                Home
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/my-properties" class="nav-link">
                                My Properties
                            </router-link>
                        </li>
                        <li class="nav-item megamenu">
                            <a href="#" class="dropdown-toggle nav-link">Tokens</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/watchlist" class="nav-link">
                                        <i class='bx bx-show'></i>
                                        Watchlist
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/my-holdings" class="nav-link">
                                        <i class='bx bx-coin'></i>
                                        My Holdings
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link to="/exchange" class="nav-link">
                                Exchange
                            </router-link>
                        </li>
                        <li class="nav-item megamenu">
                            <a href="#" class="dropdown-toggle nav-link">Support</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <router-link to="/faq" class="nav-link">
                                        <i class='bx bx-info-circle'></i>
                                        FAQ
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/guides" class="nav-link">
                                        <i class='bx bx-book'></i>
                                        Guides
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/wallet" class="nav-link">
                                        <i class='bx bx-wallet'></i>
                                        Wallets
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/about" class="nav-link">
                                        <i class='bx bx-group'></i>
                                        About Us
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/contact" class="nav-link">
                                        <i class='bx bx-phone-call'></i>
                                        Contact Us
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/affiliate-program" class="nav-link">
                                        <i class='bx bx-paper-plane'></i>
                                        Friend & Family Program
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/track-order" class="nav-link">
                                        <i class='bx bx-coin'></i>
                                        Track Order
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <router-link to="/blog" class="nav-link">
                                Blog
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="flex flex-row other-nav-menu only-md">
                    <div class="flex flex-row align-items-center user-help">
                        <a href="#">
                            <span class="flex align-items-center">
                                <i class='bx bx-message-square-minus'></i>
                                <span class="hide-sm mx-1">Need Help?</span>
                            </span>
                        </a>
                        <span class="mr-1 ml-1">
                            |
                        </span>
                    </div>
                    <div class="dropdown user-dropdown">
                        <button class='anchor' @mouseenter="showMenu = true" @mouseleave="showMenu = false">
                            <i class='bx bxs-user-circle'></i>
                            <span class="hide-sm">{{ name }}</span>
                        </button>
                        <div class='menu' :class="{ 'show': showMenu }" @mouseenter="showMenu = true"
                            @mouseleave="showMenu = false">
                            <div class='menu-item' @click='itemClicked("System Admin")'>
                                <div>
                                    <i class='bx bx-shield-alt'></i>
                                    System Admin
                                </div>
                            </div>
                            <div class='menu-item' @click='itemClicked("LandingPage")'>
                                <div>
                                    <i class='bx bx-info-circle'></i>
                                    Sign Out
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import router from '../../router';
export default {
    name: 'Navbar',
    data() {
        return {
            isSticky: false,
            active: false,
            button_active_state: false,
            admin_picture: require("../../assets/img/cryptocurrency/cryptocurrency2.png"),
            name: "Admin",
            showMenu: false
        }
    },
    mounted() {
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if (scrollPos >= 100) {
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    },
    methods: {
        itemClicked: function (val) {
            router.push({ name: val })
        }
    }
}
</script>