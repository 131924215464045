<template>
    <div class="container mt-4 mb-20">
        <div class="row">
            <div class="col-12">
                <div class="cryptocurrency-data-table table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">Token</th>
                                <th class="text-center p-2">Property Name</th>
                                <th class="text-center p-2">Cost Basis</th>
                                <th class="text-center p-2">Current Price</th>
                                <th class="text-center p-2">Shares</th>
                                <th class="text-center p-2">Cost Basis / Share</th>
                                <th class="text-center p-2">Total Market Value</th>
                                <th class="text-center p-2">Ownership %</th>
                                <th class="text-center p-2">Distributions</th>
                                <th class="text-center p-2">Total Gain</th>
                                <th class="text-center p-2">ROI</th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRowVue tokenName="A&E TOKEN 1" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 2" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 3" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 4" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 5" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="A&E TOKEN 6" rowColor="bg-white" />
                            <TableRowVue tokenName="A&E TOKEN 7" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 1" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 2" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone 3" rowColor="bg-white" />
                            <TableRowVue tokenName="BlackStone 4" rowColor="bg-gray-200" />
                            <TableRowVue tokenName="BlackStone5" rowColor="bg-white" />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableRowVue from './TableRow';

export default {
    name: 'Table',
    components: {
        TableRowVue
    }
}
</script>