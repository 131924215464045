<template>
    <div>
        <Navbar />
        <Breadcrumb class="container" :crumbs="crumbs" />
        <MyPropertiesBreadcrumb selected_option="Properties" />
        <Filter />
        <Card />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import Breadcrumb from '../Common/Breadcrumb'
import MyPropertiesBreadcrumb from '../MyProperties/Breadcrumb'
import Filter from '../MyProperties/Filter'
import Card from '../MyProperties/Card'
import Footer from '../Layouts/Footer'


export default {
    name: 'MyProperty',
    components: {
        Navbar,
        Breadcrumb,
        MyPropertiesBreadcrumb,
        Filter,
        Card,
        Footer,
    },
    data() {
        return {
            crumbs: ['My Properties', 'Summary']
        }
    }
}
</script>