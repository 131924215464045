<template>
    <div class="row">
        <div class="col-12 mb-5">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Address</p>
                        <p class="col-5 text-bold mb-0">100 Main Street</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Medium Income</p>
                        <p class="col-5 text-bold mb-0">$75.5K</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Male</p>
                        <p class="col-5 text-bold mb-0">54.5%</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Open Jobs</p>
                        <p class="col-5 text-bold mb-0">250</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">State</p>
                        <p class="col-5 text-bold mb-0">New York</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">2026 Estimate</p>
                        <p class="col-5 text-bold mb-0">$80.5K</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Female</p>
                        <p class="col-5 text-bold mb-0">45.5%</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Regular Jobs</p>
                        <p class="col-5 text-bold mb-0">120</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Neighborhood</p>
                        <p class="col-5 text-bold mb-0">Financial District</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Growth Rate</p>
                        <p class="col-5 text-bold mb-0">4.6%</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">HS Graduation Rate</p>
                        <p class="col-5 text-bold mb-0">75.5%</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Remote Jobs</p>
                        <p class="col-5 text-bold mb-0">130</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">MSA</p>
                        <p class="col-5 text-bold mb-0">NYC</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Debt to Income Ratio</p>
                        <p class="col-5 text-bold mb-0">1.25</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">College Graduation Rate</p>
                        <p class="col-5 text-bold mb-0">60.2%</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <p class="col-6 mb-0">Medium Salary</p>
                        <p class="col-5 text-bold mb-0">$45K</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row property-location-graph">
        <div class="col-md-3 col-12 mb-5">
            <h5>Population</h5>
            <br>
            <LineChart2 />
        </div>
        <div class="col-md-3 col-12 mb-5">
            <h5>Household Income</h5>
            <br>
            <PieChart2 />
        </div>
        <div class="col-md-3 col-12 mb-5">
            <h5>Age Demographics</h5>
            <br>
            <PieChart />
        </div>
        <div class="col-md-3 col-12 mb-5">
            <h5>Employment</h5>
            <br>
            <BarChart />
        </div>
    </div>
</template>
<script>
import LineChart2 from "./Chart2";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import PieChart2 from "./PieChart2";
export default ({
    name: "PropertyLocation",
    components: {
        LineChart2,
        BarChart,
        PieChart,
        PieChart2,
    }
})
</script>