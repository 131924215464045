<template>
    <div>
        <LandingNav />
        <MainBanner />
        <Disclosures />
    </div>
</template>

<script>
import LandingNav from '../Layouts/LandingNav'
import MainBanner from '../LandingPage/MainBanner'
import Disclosures from '../LandingPage/Disclosures'

export default {
    name: 'LandingPage',
    components: {
        LandingNav,
        MainBanner,
        Disclosures,
    }
}
</script>