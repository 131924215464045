<template>
    <accordion>
        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button">
                    How can I contact the KIBANX team if I have questions?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>You can contact the <strong>KIBANX</strong> team in one of three ways:</p>
                <ol>
                    <li>By emailing your assigned <strong>KIBANX</strong> Token Specialist.</li>
                    <li>
                        By emailing us at <a href="mailto:customersuppport@kibanx.com">customersuppport@kibanx.com</a>.
                    </li>
                    <li>By calling us at <a href="tel:+16468667473">+1 (646) 866-7473</a>.</li>
                </ol>
                <p class="text-black-50 mt-1">
                    <span class="red">*</span>
                    Our hours of customer support are between 9 AM - 6 PM EST, Monday through Friday.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    How do I create an account?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    If you visit 'Registration' button on our home screen, you will be directed to short survey-style
                    questions about your contact information, whether you’re an accredited investor, the details of your
                    investment objectives, etc. Once you submit, we will grant you access to our platform. You will then
                    receive an email from a member of our <strong>KIBANX</strong> team to walk you through the rest of
                    the on-boarding process.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">Who can invest on the KIBANX platform?</button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    In order to invest in <strong>KIBANX</strong>, investors must meet the criteria of being an
                    accredited investor.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">What is an accredited investor?</button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    “Accredited investor” has been defined by the Securities and Exchange Commission (SEC) as a test to
                    determine who is eligible to participate in certain private capital market offerings. Generally, an
                    individual person is an accredited investor if they: (1) had an income of $200,000 over the last two
                    years (or $300,000 jointly with a spouse or spousal equivalent) and reasonably expect to satisfy the
                    same criteria in the current year, (2) have a net worth of over $1,000,000, either alone or together
                    with a spouse or spousal equivalent (excluding the person’s primary residence) or (3) hold a Series
                    7, Series 82, or Series 65 financial services license and is in good standing. An individual must be
                    an accredited investor to invest on <strong>KIBANX</strong>.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">Can international investors participate?</button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    Our website and offerings are directed solely to persons located within the United States. If you
                    live outside the United States, it is your responsibility to fully observe the laws of any relevant
                    territory or jurisdiction outside the United States in connection with any purchase of membership
                    interests, including obtaining required governmental or other consents or observing any other
                    required legal or other formalities. Unless otherwise expressly indicated by us, we have not
                    registered or qualified the offering of shares in any jurisdiction outside the United States.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    I am investing through a business entity (i.e. a trust, LLC, partnership or corporation). How does
                    an entity qualify as an "accredited investor"?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    In addition to qualifying as an individual, there are other categories of accredited investors which
                    will satisfy the SEC’s definitions: (1) any trust, with total assets in excess of $5 million, not
                    formed specifically to purchase the subject securities, whose purchase is directed by a
                    sophisticated person, or (2) certain entity with total investments in excess of $5 million, not
                    formed to specifically purchase the subject securities, or (3) any entity in which all of the equity
                    owners are accredited investors.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    Why do I have to prove I am accredited for this investment?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    <strong>KIBANX</strong> Tokens are offered pursuant to an SEC rule referred to as 506(c). The rule
                    requires that
                    Cadre verify the accreditation status of each investor. For this reason, investors in
                    <strong>KIBANX</strong>
                    are asked to provide documents that attest to their accreditation to satisfy the rule.
                </p>
            </template>
        </accordion-item>

        <accordion-item>
            <template v-slot:accordion-trigger>
                <button class="accordion-button" type="button">
                    What documents can I provide to verify I am accredited?
                </button>
            </template>
            <template v-slot:accordion-content>
                <p>
                    The following documents can be provided to verify your status as an Accredited Investor:
                </p>
                <ol>
                    <li>W-2s;</li>
                    <li>tax form 1040;</li>
                    <li>
                        a letter from a broker-dealer, SEC-registered investment adviser, licensed attorney or CPA
                        stating that you are an accredited investor (“a verification letter”);
                    </li>
                    <li>Individual BrokerCheck Reports (as applicable).</li>
                </ol>
            </template>
        </accordion-item>
    </accordion>
</template>

<script>
import Accordion from "../Common/accordion";
import AccordionItem from "../Common/accordion-item";

export default {
    name: 'AccordionContant',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>