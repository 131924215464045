import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import 'bootstrap'; import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/custom.scss";

const app = createApp(App);
app.use(VueApexCharts).use(router).mount("#app");
