<template>
    <div class="cryptocurrency-area pb-70 ">
        <div class="container">
            <div class="row cursor-pointer" @click="MyProperty">
                <!-- Block 1 -->
                <div class="col-lg-4 col-md-6 col-sm-6" style="overflow-y: scroll; height: 600px">
                    <div class="single-cryptocurrency-item my-property-card">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3>45 Wilshire Blvd</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking Spaces</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/1.png" alt="Card image">
                            </div>
                        </div>
                    </div>
                    <div class="single-cryptocurrency-item my-property-card">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3>45 Wilshire Blvd</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking Spaces</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/2.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                    <div class="single-cryptocurrency-item my-property-card">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3>45 Wilshire Blvd</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking Spaces</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/3.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                    <div class="single-cryptocurrency-item my-property-card">
                        <div class="row">
                            <div class="col-5 my-property-card-detail">
                                <div class="title">
                                    <h3>45 Wilshire Blvd</h3>
                                    <div class="row">
                                        <div class="col-6 sub-title-heading">Property</div>
                                        <div class="col-6 sub-title-detail">Retail</div>
                                        <div class="col-6 sub-title-heading">Square Footage</div>
                                        <div class="col-6 sub-title-detail">1,524</div>
                                        <div class="col-6 sub-title-heading">Cap Rate</div>
                                        <div class="col-6 sub-title-detail">7.06%</div>
                                        <div class="col-6 sub-title-heading">NOI</div>
                                        <div class="col-6 sub-title-detail">$ 31,779</div>
                                        <div class="col-6 sub-title-heading">Year Built</div>
                                        <div class="col-6 sub-title-detail">1930</div>
                                        <div class="col-6 sub-title-heading">Buildings</div>
                                        <div class="col-6 sub-title-detail">1</div>
                                        <div class="col-6 sub-title-heading">Stories</div>
                                        <div class="col-6 sub-title-detail">10</div>
                                        <div class="col-6 sub-title-heading">Units / Keys</div>
                                        <div class="col-6 sub-title-detail">127</div>
                                        <div class="col-6 sub-title-heading">Parking Spaces</div>
                                        <div class="col-6 sub-title-detail">45</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7 my-property-card-img">
                                <img class="" src="@/assets/img/investment_detail/4.jpg" alt="Card image">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Block 2 -->
                <div class="col-lg-8 col-md-6 col-sm-6">
                    <div class="single-cryptocurrency-item my-property-map">
                        <div class="row">
                            <div class="col-12 my-property-card-detail">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12098.40003046868!2d-74.0072812!3d40.7048062!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70c11c6c3c128d73!2sDeal%20Tracking%20Solutions%2C%20Inc.!5e0!3m2!1sen!2s!4v1660665348367!5m2!1sen!2s"
                                    class="investment-detail-location-map" height="600px" style="border:0;"
                                    loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import router from '../../router';
export default {
    name: 'Card',
    methods: {
        MyProperty() {
            router.push({ name: "Property" })
        }
    }
}

</script>