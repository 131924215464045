<template>
    <div class="blog-area gallery-card-block pb-50">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_1.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 1
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_2.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 2
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_3.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 3
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_4.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 4
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_5.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 5
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mb-3">
                    <div class="single-blog-post">
                        <div class="post-image">
                            <router-link to="" class="d-block">
                                <img src="@/assets/img/gallery/Gallery_6.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="post-content">
                            <h3>
                                <router-link to="">
                                    Property Photo 6
                                </router-link>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Card',
}
</script>