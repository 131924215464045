<template>
    <div class="buy-sell-cryptocurrency-area bg-image pt-70">
        <div class="container">
            <div class="section-title">
                <h2>Access capital and investors internalionally with real estate tokenization</h2>
                <p>Real estate security token offering is an excellent source of capital for the production,
                    acquisition, or extension of real estate property.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-image">
                        <img src="../../assets/img/women-with-tab.png" alt="image">
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12">
                    <div class="buy-sell-cryptocurrency-content">
                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon12.png" alt="image">
                                    </div>
                                    <h3>Smart Contracts</h3>
                                    <p>Smart contract automation removes administrative overhead associated with
                                        tracking transactions on the blockchain.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon13.png" alt="image">
                                    </div>
                                    <h3>Online Wallets</h3>
                                    <p>Using advanced encryption to ensure that your payment information is secure. All
                                        your transactions can be made via your KIBANX wallet.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon14.png" alt="image">
                                    </div>
                                    <h3>Real Distributions</h3>
                                    <p>KIBANX properties can offer our investors real world distributions. Get
                                        distributions sent to you directly to your wallet.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon15.png" alt="image">
                                    </div>
                                    <h3>Digital Exchange</h3>
                                    <p>Our KIBANX Exchange allows customers to search and trade securitized tokens with
                                        digital currencies. Our exchange also acts as a market maker's role that takes
                                        the bid-ask spreads as a transaction commission.</p>
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon16.png" alt="image">
                                    </div>
                                    <h3>Discounted Gift Cards</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut.</p>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-6 col-sm-6 col-6 mdw-100">
                                <div class="single-buy-sell-box">
                                    <div class="icon">
                                        <img src="../../assets/img/icon/icon17.png" alt="image">
                                    </div>
                                    <h3>Goods & Services</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut.</p>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuyAndSell'
}
</script>