<template>
    <div class="container my-3">
        <div class="row form-group align-items-center">
            <div class="col-lg-2 col-md-4 col-4 mb-3">
                <label for="field" class="form-label m-0">Field</label>
            </div>
            <div class="col-lg-4 col-md-8 col-8 mb-3">
                <select name="field" id="field" class="form-select">
                    <option value="">Select</option>
                    <option value="renovation_types">renovation_types</option>
                    <option value="properties_id">properties_id</option>
                    <option value="buildings_id">buildings_id</option>
                    <option value="units_id">units_id</option>
                    <option value="regional_office_id">regional_office_id</option>
                    <option value="work_order_type">work_order_type</option>
                    <option value="job_name">job_name</option>
                    <option value="proposed_grade">proposed_grade</option>
                    <option value="current_grade">current_grade</option>
                    <option value="reported_location_id">reported_location_id</option>
                    <option value="renovation_cycles">renovation_cycles</option>
                    <option value="reason_request_id">reason_request_id</option>
                    <option value="benchmark_costs">benchmark_costs</option>
                    <option value="order_name">order_name</option>
                    <option value="contract_id">contract_id</option>
                    <option value="order_date">order_date</option>
                    <option value="completion_date">completion_date</option>
                    <option value="Internal_notes">Internal_notes</option>
                    <option value="construction_reason">construction_reason</option>
                    <option value="vendor_construction_notes">vendor_construction_notes</option>
                    <option value="project_started_date">project_started_date</option>
                    <option value="forcasted_compl_date">forcasted_compl_date</option>
                    <option value="reported_compl_date">reported_compl_date</option>
                    <option value="cancel_date">cancel_date</option>
                    <option value="cancelled">cancelled</option>
                    <option value="emergency">emergency</option>
                    <option value="previous_status">previous_status</option>
                    <option value="uncancel_date">uncancel_date</option>
                    <option value="uncancel_notes">uncancel_notes</option>
                    <option value="construction_manager">construction_manager</option>
                    <option value="request_source">request_source</option>
                    <option value="ordering_firm">ordering_firm</option>
                    <option value="job_loc_id">job_loc_id</option>
                    <option value="last_approval_log_id">last_approval_log_id</option>
                    <option value="approval_status">approval_status</option>
                    <option value="Vendor_ID">Vendor_ID</option>
                    <option value="delete_flg">delete_flg</option>
                    <option value="job_status_id">job_status_id</option>
                    <option value="options_id">options_id</option>
                    <option value="no_of_ac">no_of_ac</option>
                    <option value="payment_approval_status">payment_approval_status</option>
                    <option value="last_payment_approval_log_id">last_payment_approval_log_id</option>
                    <option value="paidToDate">paidToDate</option>
                    <option value="payment_date">payment_date</option>
                    <option value="created_by">created_by</option>
                    <option value="updated_by">updated_by</option>
                    <option value="created_at">created_at</option>
                    <option value="updated_at">updated_at</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-4 mb-3">
                <label for="filter" class="form-label m-0">Filters</label>
            </div>
            <div class="col-lg-4 col-md-8 col-8 mb-3">
                <select name="filter" id="filter" class="form-select">
                    <option value="">Select</option>
                    <option value="Equal">Equal</option>
                    <option value="Greater_than">Greater than</option>
                    <option value="Less_than">Less than</option>
                    <option value="Like">Like</option>
                    <option value="NOT_like">NOT Like</option>
                    <option value="Not_equal">NOT Equal</option>
                    <option value="Between">Between</option>
                </select>
            </div>
            <div class="col-lg-2 col-md-4 col-4 mb-3">
                <label for="value" class="form-label m-0">Filter Value</label>
            </div>
            <div class="col-lg-4 col-md-8 col-8 mb-3">
                <input name="value" id="value" class="form-control">
            </div>
            <div class="col-lg-6 col-md-12 col-12 mb-3 text-center">
                <button for="value" class="btn btn-primary m-0">Add</button>
            </div>
        </div>
        <div class="row form-group mb-3">
            <div class="col-lg-3 col-md-4 col-4">
                <label for="filter" class="form-label m-0">Select Columns</label>
            </div>
            <div class="col-lg-8 col-md-8 col-8 mb-3">
                <textarea name="column" id="column" class="form-control resize-none" disabled rows="4"></textarea>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Filters',
}
</script>